import React, { useEffect, useRef } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
  TableRow,
  TableCell,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  Checkbox,
  alertTitleClasses,
  //Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TableSwitch } from "./TableSwitch";
import EditIcon from "@mui/icons-material/Edit";
import { CommentIcon } from "../svg/CommentIcon";
import { StyledTableRow } from "./styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RefreshIcon } from "../svg/RefreshIcon";
import { ChartIcon } from "../svg/ChartIcon";
import { BarCodeIcon } from "../svg";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { DownloadIcon } from "../svg";
import _ from 'lodash';

const useStyles = makeStyles({
  tableBody: {
    //padding: "12px",
    fontFamily: "poppinsemibold",
    fontSize: "12px",
    padding: 10,
  },
});

export const ListTableRow = ({
  incrementCount = 0,
  index = null,
  parent_id = "",
  tableData = [],
  condition = () => true,
  Header = [],
  row = {},
  refreshIcon = "",
  assgineeClick = false,
  handleCheckBox = () => null,
  handleTextfield = () => null,
  handleEdit = () => null,
  handleChartIcon = () => null,
  handlePrintJob = () => null,
  handleStatusClick = () => null,
  handleViewClick = () => null,
  handleView = () => null,
  handleOverRide = () => null,
  handleDelete = () => null,
  handleHyperLink = () => null,
  handleSelect = () => null,
  setOpen = () => null,
  handleUploadInvoice = () => null,
  handleDownload = () => null,
  state = {},
  tdStyle = {},
  totalCheck = [],
  handleBar = () => null,
}) => {
  const classes = useStyles();

  return (
    <StyledTableRow
      id={`${parent_id}-${index}-table-dataList-TableRow`}
      key={index + 1}
    >
      {tableData.map((val, i) => {
        return (
          <TableCell
            id={`${parent_id}-${i}-table-dataList-TableCell`}
            style={{
              paddingLeft: i === 0 ? "25px" : "",
              paddingRight: i === Header.length - 1 ? "25px" : "",
              paddingBottom: i === tableData.length - 1 ? "10px" : "",
              fontSize: "12px",
              color: "#858585",
              ...tdStyle,
            }}
            align={val.align ? val.align : "left"}
            className={classes.tableBody}
          >
            {val.type.map((type) => {
              if (condition(row, type, val.name)) {
                return getComponentType({
                  index: index + incrementCount,
                  type: type,
                  condition: val.cond,
                  value: val.optionLabel
                    ? row[val.name]?.[val.optionLabel]
                    : row[val.name],
                  rowSpan: row[val?.rowSpan],
                  error: !!row?.error?.[val.name],
                  placeholder: [val.name],
                  options: val?.options ?? [],
                  icon: val?.icon ?? "",
                  toolTip: val?.toolTip ?? {},
                  state: state,
                  row: row,
                  refreshIcon: refreshIcon,
                  assgineeClick: assgineeClick,
                  totalCheck: totalCheck,
                  handleCheckBox: (e) => {
                    handleCheckBox(e, row, index);
                  },
                  handleEdit: (e) => {
                    handleEdit(e, row, index);
                  },
                  handleView: (e) => {
                    handleView(e, row, index);
                  },
                  handleBar: (e) => {
                    handleBar(e, row, index);
                  },
                  handlePrintJob: (e) => {
                    handlePrintJob(e, row, index);
                  },
                  handleChartIcon: (e) => {
                    handleChartIcon(e, row, index);
                  },
                  handleStatusClick: (e) => {
                    handleStatusClick(e, row, index);
                  },
                  handleViewClick: (e) => {
                    handleViewClick(e, row, index);
                  },
                  handleOverRide: (e) => {
                    handleOverRide(e, row, index);
                  },
                  handleDelete: (e) => {
                    handleDelete(e, row, index);
                  },
                  handleTextfield: (e) => {
                    handleTextfield(e, row, val.name, index);
                  },
                  handleHyperLink: (e) => {
                    handleHyperLink(e, row, index);
                  },
                  handleSelect: (data) => {
                    handleSelect(data, row, val.name, index);
                  },
                  setOpen: (data, index) => {
                    setOpen(data, index);
                  },
                  handleUploadInvoice: (e) => {
                    handleUploadInvoice(e, row, index);
                  },
                  handleDownload: (e) => {
                    handleDownload(e, row, index);
                  }
                });
              } else {
                return false;
              }
            })}
          </TableCell>
        );
      })}
    </StyledTableRow>
  );
};

const generateStatusBullet = (data) => {
  switch (data) {
    case "Update Price": {
      return "#ffb95a";
    }
    case "PENDING": {
      return "#ffeacd";
    }
    case "PAID": {
      return "#b7e6cf";
    }
    case "Pending": {
      return "#ffeacd";
    }
    case "Fitted": {
      return "#d1efef";
    }
    case "Delivered": {
      return "#b7e6cf7";
    }
    case "Shipped": {
      return "#d9ebff";
    }
    case "Packed": {
      return "#d1efef";
    }
    case "QC": {
      return "#d1efef";
    }
    case "QC Passed": {
      return "#b7e6cf";
    }
    case "QC Failed": {
      return "#ffb1b2";
    }
    case "Created": {
      return "#d1efef";
    }
    case "In Progress": {
      return "#ffeacd";
    }
    case "Completed": {
      return "#d9ebff";
    }
    case "Paid": {
      return "#b7e6cf";
    }
    case "Shipped From Store": {
      return "#d9ebff";
    }
    case "Delivered To Warehouse": {
      return "#b7e6cf";
    }
    case "Job Order QC Passed": {
      return "#b7e6cf";
    }
    case "Job Order QC Failed": {
      return "#ffb1b2";
    }
    case "Shipped From Warehouse": {
      return "#d9ebff";
    }
    case "Delivered To Store": {
      return "#b7e6cf";
    }
    default: {
      return "#d1efef";
    }
  }
};

const uploadInvoiceColor = (data) => {
  if (data) {
    return "#1daf67"
  } else {
    return "#ff6f00"
  }
}

const statusColor = (data) => {
  switch (data) {
    case "Shipped From Store": {
      return "#0077ff";
    }
    case "Delivered To Warehouse": {
      return "#1daf67";
    }
    case "Job Order QC Passed": {
      return "#1daf67";
    }
    case "Job Order QC Failed": {
      return "#ff0000";
    }
    case "Shipped From Warehouse": {
      return "#0077ff";
    }
    case "Delivered To Store": {
      return "#1daf67";
    }
    case "Update Price": {
      return "#fff";
    }
    case "QC Failed": {
      return "#ff0000";
    }
    case "PENDING": {
      return "#ffb95a";
    }
    case "PAID": {
      return "#1daf67";
    }
    case "Pending": {
      return "#ffb95a";
    }
    case "Fitted": {
      return "#03a5a5";
    }
    case "Delivered": {
      return "#1daf67";
    }
    case "Shipped": {
      return "#0077ff";
    }
    case "Packed": {
      return "#03a5a5";
    }
    case "QC Passed": {
      return "#1daf67";
    }
    case "QC": {
      return "#03a5a5";
    }
    case "Created": {
      return "#03a5a5";
    }
    case "In Progress": {
      return "#ffb95a";
    }
    case "Completed": {
      return "#0077ff";
    }
    case "Paid": {
      return "#1daf67";
    }
    default: {
      return "#03a5a5";
    }
  }
};
const getComponentType = (data) => {
  switch (data.type) {
    case "ARRAY": {
      return data.value.map((value) => {
        if (_.isArray(value)) {
          return value.map((val) => {
            return (
              <StyledTableRow>
                <TableCell rowSpan={data.rowSpan} style={{
                  fontSize: "12px",
                  color: "#858585",
                }}>
                  {val}
                </TableCell>
              </StyledTableRow>
            );
          });
        } else {
          return (
            <StyledTableRow>
              <TableCell rowSpan={data.rowSpan} style={{
                fontSize: "12px",
                color: "#858585",
              }}>
                {value}
              </TableCell>
            </StyledTableRow>
          );
        }
      });
    }
    case "TEXT": {
      if (
        data?.condition &&
        data.row[data.condition.key] === data.condition.value
      ) {
        return (
          <React.Fragment>
            <span style={{ paddingLeft: "5px" }}>{data.value}</span>
          </React.Fragment>
        );
      } else {
        return data.value;
      }
    }
    case "TEXT_LINK": {
      return !data.assgineeClick ? (
        <div style={{ color: "#03a5a5" }}>{data.value}</div>
      ) : (
        <div
          onClick={data.handleViewClick}
          style={{ textDecoration: "underline", color: "#03a5a5" }}
        >
          {data.value}
        </div>
      );
    }
    case "INCRIMENT": {
      return data.index + 1;
    }
    case "CHECKBOX": {
      return (
        <Checkbox
          id={`table-value-TableSwitch`}
          checked={data.totalCheck.includes(data?.row)}
          onChange={data.handleCheckBox}
          name="checkbox"
        />
      );
    }
    case "EDIT": {
      return (
        <img
          id={`table-EditIcon-img`}
          src={<EditIcon />}
          onClick={data.handleEdit}
          width="12px"
          style={{ marginRight: "10px", cursor: "pointer" }}
          alt="table-Edit"
        />
      );
    }
    case "COMMENTS": {
      return (
        <div style={{ paddingLeft: "25px" }}>
          <CommentIcon />
        </div>
      );
    }
    case "BARCODE": {
      return (
        <div style={{ paddingLeft: "25px" }}>
          <BarCodeIcon />
        </div>
      );
    }
    case "LOGO": {
      return <img src={data?.value} alt="Logo" width="66px" height="66px" />;
    }
    case "DELETE": {
      return <DeleteIcon onClick={data.handleView}></DeleteIcon>;
    }
    case "TEXTFIELD": {
      return (
        <TextField
          id={`table-${data?.value}-TextField`}
          value={data?.value}
          placeholder={`Enter ${data?.placeholder}`}
          onChange={data.handleTextfield}
          error={data?.error}
        />
      );
    }
    case "STATUS": {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              height: "24px",
              backgroundColor: generateStatusBullet(data?.value),
              display: "inline-block",
              padding: "4px 3px 4px 0px",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                color: statusColor(data?.value),
                fontSize: "11px",
                fontWeight: 500,
                // lineHeight: 1.14,
                letterSpacing: "0.42px",
                textAlign: "center",
                padding: "2px 2px 0px 4px ",
              }}
            >
              {data?.value.toUpperCase()}
            </Typography>
          </div>
        </div>
      );
    }
    case "UPLOAD_INVOICE": {

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              height: "24px",
              display: "inline-block",
              padding: "4px 3px 4px 0px",
            }}
          >
            <Typography
              onClick={data.handleUploadInvoice}
              sx={{
                color: uploadInvoiceColor(data?.value),
                fontSize: "11px",
                fontWeight: 500,
                letterSpacing: "0.42px",
                textAlign: "center",
              }}
            >
              {(data?.value) ? "Uploaded" : "Upload Invoice"}
            </Typography>
          </div>
        </div>
      );
    }
    case "ORDER_STATUS": {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              height: "24px",
              backgroundColor: generateStatusBullet(data?.value),
              display: "inline-block",
              padding: "4px 3px 4px 0px",
              borderRadius: "4px",
            }}
            onClick={data.handleChartIcon}
          >
            <Typography
              sx={{
                color: statusColor(data?.value),
                fontSize: "11px",
                fontWeight: 500,
                // lineHeight: 1.14,
                letterSpacing: "0.42px",
                textAlign: "center",
                padding: "2px 2px 0px 4px ",
              }}
            >
              {data?.value.toUpperCase()}
            </Typography>
          </div>
          {data?.refreshIcon ? (
            <div onClick={data.handleStatusClick}>
              <RefreshIcon width={18} height={18} />
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
    case "VIEW": {
      return (
        <VisibilityIcon
          onClick={data.handleViewClick}
          sx={{ color: "#03a5a5" }}
        />
      );
    }
    case "VIEW_TEXT": {
      return (
        <div
          style={{ display: "flex", alignItems: "center", color: "#03a5a5" }}
          onClick={data.handleViewClick}
        >
          {data?.value}
        </div>
      );
    }
    case "CHART_ICON": {
      return (
        <div onClick={data.handlePrintJob}>
          <ChartIcon sx={{ color: "#03a5a5" }} />
        </div>
      );
    }
    case "BAR_CODE": {
      return (
        <div style={{ cursor: "pointer" }} onClick={data.handleBar}>
          <QrCodeScannerIcon sx={{ color: "#03a5a5" }} />
        </div>
      );
    }
    case "DOWNLOAD": {
      return (
        data?.value ? (
          <div style={{ paddingLeft: "55px" }} onClick={data.handleDownload}>
            <DownloadIcon />
          </div>
        ) : (
          null
        )

      );
    }

    //   case "TEXTFIELD_SELECT": {
    //     return (
    //       <TextFieldWithSelect
    //         id={`table-${data?.value}-TextFieldWithSelect`}
    //         options={data?.options}
    //         label=""
    //         variant="standard"
    //         // required={true}
    //         value={data?.value}
    //         onChange={(value) => data.handleSelect(value)}
    //       />
    //     );
    //   }
    //   case "SELECT": {
    //     return (
    //       <SelectWithLabel
    //         id={`table-${data?.value}-SelectWithLabel`}
    //         options={data?.options}
    //         label=""
    //         variant="standard"
    //         // required={true}
    //         value={data?.value}
    //         onChange={(value) => data.handleSelect(value)}
    //       />
    //     );
    //   }
    //   case "ICON": {
    //     return (
    //       <div>
    //         <Tooltip
    //           id={`table-${data?.icon ?? ""}-Tooltip`}
    //           arrow={data?.toolTip?.arrow ?? false}
    //           title={data?.toolTip?.title ?? ""}
    //           placement={data?.toolTip?.placement}
    //         >
    //           {data?.icon ?? ""}
    //         </Tooltip>
    //       </div>
    //     );
    //   }
    //   case "VIEWICON": {
    //     return (
    //       <div onClick={data.handleView} style={{
    //         display: "flex",
    //         alignItems: "center",
    //         cursor: "pointer",
    //       }}>
    //         <Tooltip
    //           id={`table-${data?.icon ?? ""}-Tooltip`}
    //           arrow={data?.toolTip?.arrow ?? false}
    //           title={data?.toolTip?.title ?? ""}
    //           placement={data?.toolTip?.placement}
    //         >
    //           {data?.icon ?? ""}
    //         </Tooltip>
    //         <span style={{ color: "#0071F2" }}> View </span>
    //       </div>
    //     );
    //   }
    //   case "DRAFT": {
    //     return (
    //       <Tooltip
    //         id={`table-DraftIcon-Tooltip`}
    //         title="Draft"
    //         placement="top-start"
    //       >
    //         <img id={`table-DraftIcon-img`} src={DraftIcon} alt="table-delete" />
    //       </Tooltip>
    //     );
    //   }
    // case "COLLAPSE": {
    //   return (
    //     <>
    //       {data?.row?.children ? (
    //         <IconButton
    //           aria-label="expand row"
    //           size="small"
    //           onClick={() => data.setOpen(data.row, data.index)}
    //         >
    //           {data?.index === data?.state?.open ? (
    //             <KeyboardArrowUpIcon />
    //           ) : (
    //             <KeyboardArrowDownIcon />
    //           )}
    //         </IconButton>
    //       ) : (
    //         <div style={{ width: 30, display: "inline-block" }} />
    //       )}
    //     </>
    //   );
    // }

    //   case "PROFILE": {
    //     return (
    //       <>
    //         <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
    //           {data.row.pht ? <Avatar
    //             alt={data?.value?.[0]}
    //             src={data.row.pht ? getImgUrl(data.row.pht) : null}
    //             // src={`https://picsum.photos/200/300?random=${Math.floor(
    //             //   Math.random() * 100
    //             // ).toFixed(2)}`}
    //             style={{ width: "24px", height: "24px" }}
    //           /> : <Avatar
    //             style={{ width: "24px", height: "24px", fontSize: 10 }}
    //           >{data?.value?.[0]}</Avatar>}
    //           <span>{data?.value}</span>
    //         </div>
    //       </>
    //     );
    //   }

    default: {
      return data.value;
    }
  }
};
