import React from "react";

export const ListViewIcon = (props) => {
  const width = props?.width ?? "79";
  const height = props?.height ?? "79";
  const color = props?.color ?? "#827e7e";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M7.75 1.5h-7A.755.755 0 0 1 0 .75.755.755 0 0 1 .75 0h7a.755.755 0 0 1 .75.75.755.755 0 0 1-.75.75z"
        transform="translate(13.25 3.75)"
        fill={color}
      />
      <path
        data-name="Vector"
        d="M7.75 1.5h-7A.755.755 0 0 1 0 .75.755.755 0 0 1 .75 0h7a.755.755 0 0 1 .75.75.755.755 0 0 1-.75.75z"
        transform="translate(13.25 8.75)"
        fill={color}
      />
      <path
        data-name="Vector"
        d="M18.75 1.5h-18A.755.755 0 0 1 0 .75.755.755 0 0 1 .75 0h18a.755.755 0 0 1 .75.75.755.755 0 0 1-.75.75z"
        transform="translate(2.25 13.75)"
        fill={color}
      />
      <path
        data-name="Vector"
        d="M18.75 1.5h-18A.755.755 0 0 1 0 .75.755.755 0 0 1 .75 0h18a.755.755 0 0 1 .75.75.755.755 0 0 1-.75.75z"
        transform="translate(2.25 18.75)"
        fill={color}
      />
      <path
        data-name="Vector"
        d="M5.17 7.5H2.33A2.066 2.066 0 0 1 0 5.17V2.33A2.066 2.066 0 0 1 2.33 0h2.85a2.066 2.066 0 0 1 2.33 2.33v2.85A2.07 2.07 0 0 1 5.17 7.5zm-2.84-6c-.71 0-.83.12-.83.83v2.85c0 .71.12.83.83.83h2.85c.71 0 .83-.12.83-.83V2.33c0-.71-.12-.83-.83-.83z"
        transform="translate(2.75 3.25)"
        fill={color}
      />
    </svg>
  );
};
