import { Autocomplete, Dialog, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import {
  ContinueButton,
  StyledTypography,
  SubLabelTypography,
} from "../../../components";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getStoreList } from "../../../app/reducers/stockTransferSlice";

function StoreTransferModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [storeName, setStoreName] = useState({});

  const storeList = useSelector(
    (state) => state.stockTransferReducer.storeList
  );

  useEffect(() => {
    dispatch(getStoreList());
  }, [dispatch])

  const handleDropDownChange = (newValue) => {
    setStoreName(newValue);
  };

  const handleSubmit = () => {
    props?.handleSubmit(storeName);
  }
  return (
    <>
      <Dialog
        open={props?.open}
        onClose={props?.close}
      >
        <div style={{ padding: "20px 24px" }}>
          <div
            className={classes.alignEntire}
            style={{ paddingBottom: "18px" }}
          >
            <StyledTypography
              size={"16px"}
              color={"#242424"}
              weight={600}
              letter="0px"
            >
              Stock Transfer
            </StyledTypography>
            <div
              onClick={props?.close}
              style={{ color: "#d8d8d8", fontSize: "14px", cursor: "pointer" }}
            >
              <CloseIcon style={{ color: "#d8d8d8", fontSize: "14px" }} />
            </div>
          </div>

          <SubLabelTypography margin={"0px 0px 10px"}>STORE</SubLabelTypography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={storeList}
            getOptionLabel={(option) => option.name }
            sx={{ width: 350, marginBottom: "150px" }}
            onChange={(event, newValue) => {
              handleDropDownChange(newValue);
            }}
            size="small"
            renderInput={(params) => (
              <TextField
                sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                {...params}
                placeholder="Select"
              />
            )}
          />
          <ContinueButton onClick={handleSubmit} marginLeft={"0px"}>
            Submit
          </ContinueButton>
        </div>
      </Dialog>
    </>
  );
}

export default StoreTransferModal;
