import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  lensGrid: [],
  lensListView: [],
  lensAdded: {},
  lensAddedError: "",
  lensEditedError: "",
  lensEdited: {},
  pageNoLens: 0,
  hasMoreLens: true,
};

export const lensCatalogSlice = createSlice({
  name: "lensCatalog",
  initialState,
  reducers: {
    getGroupedLens() {},
    setGroupedLens(state, action) {
      return Object.assign({}, state, {
        lensGrid: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getLensListView() {},
    setLensListView(state, action) {
      return Object.assign({}, state, {
        lensListView:
          state.pageNoLens > 0
            ? [...state.lensListView, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreLens: action.payload.length < 15 ? false : true,
      });
    },
    
    setPagenoLens(state, action) {
      return Object.assign({}, state, {
        pageNoLens: action.payload,
      });
    },


    getSaveLens() {},
    setSaveLens(state, action) {
      return Object.assign({}, state, {
        lensAdded: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    saveLensErrors(state, action) {
      return Object.assign({}, state, {
        lensAddedError: Array.isArray(action.payload)
          ? action.payload[0].msg
          : action.payload,
      });
    },
    editLens() {},
    editLensSuccess(state, action) {
      return Object.assign({}, state, {
        lensEdited: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    editLensErrors(state, action) {
      return Object.assign({}, state, {
        lensEditedError: Array.isArray(action.payload)
          ? action.payload[0].msg
          : action.payload,
      });
    },
    clearLens(state, action){
      return Object.assign({}, state, initialState);
    }
  },
});

export const {
  getGroupedLens,
  setGroupedLens,
  getLensListView,
  setLensListView,
  setSaveLens,
  getSaveLens,
  editLens,
  editLensSuccess,
  editLensErrors,
  saveLensErrors,
  setPagenoLens,
  clearLens
} = lensCatalogSlice.actions;

export default lensCatalogSlice.reducer;
