import React from "react";

export const BackIcon = (props) => {
  const width = props?.width ?? "79";
  const height = props?.height ?? "79";
  const color = props?.color ?? "#a0a0a0";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M20 10A10 10 0 1 1 10 0a10 10 0 0 1 10 10z"
        transform="translate(2 2)"
        fill="none"
        stroke="#827e7e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
      />
      <path
        data-name="Vector"
        d="M3.52 7.06 0 3.53 3.52 0"
        transform="translate(9.74 8.47)"
        fill="none"
        stroke="#827e7e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
      />
    </svg>
  );
};
