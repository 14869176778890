import {
  List,
  ListItemText,
  ListItemButton,
  Collapse,
  Typography,
  Dialog,
  IconButton,
  Grid,
  Autocomplete,
  TextField,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import {
  BackIcon,
  ContinueButton,
  ListTable,
  OrderIndentIcon,
  StyledTypography,
  Footer,
} from "../../components";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import StatusModal from "../../components/Modal/StatusModal";
import StepperComponent from "./StepperComponent";
import JobOrderModal from "../../components/Modal/JobOrderModal";
import JobCard from "../../components/Modal/JobCard";
import IndentSummary from "../../components/Modal/IndentSummary";
import AssigneeModal from "../../components/Modal/AssigneeModal";
import { getIndentOrders } from "../../app/reducers/indentSlice";
import { getLensUpdation } from "../../app/reducers/orderSlice";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

function Indents(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [gridFilter, setGridFilter] = useState([]);

  const orderList = useSelector((state) => state.indentReducer.orderList);
  const assigneeList = useSelector(
    (state) => state.assigneeReducer.assigneeList
  );
  const indentData = useSelector((state) => state.indentReducer.indentData);
  const isAssigneeSelected = useSelector(
    (state) => state.assigneeReducer.assigneeSelected
  );
  const updatedStatus = useSelector(
    (state) => state.orderReducer.updatedStatus
  );
  const shipmentStatus = useSelector(
    (state) => state.orderReducer.shipmentStatus
  );

  const lensUpdate = useSelector((state) => state.orderReducer.lensUpdate);

  const [assigneeOpen, setAssigneeOpen] = useState(false);
  const [isRequireFitting, setIsRequireFitting] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [statusOpen, setStatusOpen] = useState(false);
  const [isQc, setIsQc] = useState(false);
  const [isShipment, setIsShipment] = useState(false);
  const [statusDetails, setStatusDetails] = useState("");
  const [openJob, setOpenJob] = useState(false);
  const [indentSummaryStatus, setIndentSummaryStatus] = useState(false);
  const [openStepperChart, setOpenStepperChart] = useState(false);
  const [stepperChart, setStepperChart] = useState({});
  const [openCostPriceUpdate, setOpenCostPriceUpdate] = useState(false);
  const [costPrice, setCostPrice] = useState("");
  const [costPriceDetails, setCostPriceDetails] = useState({});
  const [openJobCardOrder, setOpenJobCardOrder] = useState(false);
  const [jobCardData, setJobCardData] = useState({});
  const [indentSummaryDetails, setIndentSummaryDetails] = useState({});
  const [allOrderDetails, setAllOrderDetails] = useState([]);

  useEffect(() => {
    dispatch(getIndentOrders(indentData.indentId));
  }, [
    indentData,
    isAssigneeSelected,
    updatedStatus,
    shipmentStatus,
    lensUpdate,
  ]);

  const getAssigneeRoleByStatus = (role, requireFitting) => {
    let assigneeRole;
    if (role === "Created" || role === "Job Order Assigned") {
      assigneeRole = !requireFitting ? "qc" : "pickup";
    } else if (role === "Pick Up") {
      assigneeRole = !requireFitting ? "qc" : "fitter";
    } else if (role === "Fitted") {
      assigneeRole = !requireFitting ? "qc" : "qc";
    } else if (role === "QC") {
      assigneeRole = !requireFitting ? "packing" : "packing";
    } else if (role === "QC Failed") {
      assigneeRole = !requireFitting ? "qc" : "qc";
    } else if (role === "QC Passed") {
      assigneeRole = !requireFitting ? "packing" : "packing";
    } else if (role === "Packed") {
      assigneeRole = !requireFitting ? "shipping" : "shipping";
    } else if (role === "Shipped") {
      assigneeRole = "shipping";
    } else if (role === "Delivered") {
      assigneeRole = "shipping";
    } else if (role === "Completed") {
      assigneeRole = "shipping";
    } else if (role === "Intend Closed") {
      assigneeRole = "shipping";
    } else {
      assigneeRole = !requireFitting ? "qc" : "pickup";
    }
    return assigneeRole;
  };

  const getNextRole = (role, requireFitting) => {
    let nextRole;
    if (role === "Job Order Assigned" || role === "Created") {
      nextRole = !requireFitting ? "QC" : "Pick Up";
    } else if (role === "Pick Up") {
      nextRole = !requireFitting ? "QC" : "Fitted";
    } else if (role === "Fitted") {
      nextRole = !requireFitting ? "QC" : "QC Passed";
    } else if (role === "QC Passed") {
      nextRole = !requireFitting ? "Packed" : "Packed";
    } else if (role === "QC Failed") {
      nextRole = !requireFitting ? "QC" : "QC";
    } else if (role === "Packed") {
      nextRole = !requireFitting ? "Shipping" : "Shipping";
    } else if (role === "Shipping") {
      nextRole = "Shipping";
    } else if (role === "Delivered") {
      nextRole = "Shipping";
    } else {
      nextRole = !requireFitting ? "Pending" : "Pending";
    }
    return nextRole;
  };

  let tableData = [];

  const data = orderList?.map((order) => {
    const frame = order?.products.name.split(" ");
    let requireFitting = false;
    if (_.isEmpty(order?.products?.lensItems)) {
      requireFitting = false;
    } else {
      requireFitting = true;
    }
    const assignee = getAssigneeRoleByStatus(order?.status, requireFitting);
    const orderData = {
      storeId: order?.storeId,
      orderId:
        order?.orderInfo?.orderId ,
        orderDate:moment(order?.orderInfo?.orderDate).format("ll"),
      jobOrderId: order?.jobOrderId,
      frameInfo:
        frame.slice(0,-1).join('  ')||
        " - ",
      // frame[1].concat(" ").concat(frame[2]).concat(" ").concat(frame[3]) ||
      // " - ",
      // frame[0].concat(" ").concat(frame[2]).concat(" ").concat(frame[3]) ||
      lensType: order?.products?.lensName || " - ",
      amount: order?.inclGst,
      currentAssignee:
        _.isEmpty(order?.products?.lensItems?.costPrice) &&
          !_.isEmpty(order?.products?.lensItems)
          ? "-"
          : _.isEmpty(order?.assignees)
            ? "Assign"
            : order?.status === "Shipped"
              ? "-"
              : order?.assignees[assignee].name,
      status:
        _.isEmpty(order?.products?.lensItems?.costPrice) &&
          !_.isEmpty(order?.products?.lensItems)
          ? "Update Price"
          : order?.status,
      refreshIcon:
        _.isEmpty(order?.products?.lensItems?.costPrice) &&
          !_.isEmpty(order?.products?.lensItems)
          ? "refresh"
          : order?.status === "Shipped" ||
            order?.status === "Intend Closed" ||
            order?.status === "Delivered"
            ? ""
            : _.isEmpty(order?.assignees)
              ? ""
              : "refresh",
      assgineeClick:
        _.isEmpty(order?.products?.lensItems?.costPrice) &&
          !_.isEmpty(order?.products?.lensItems)
          ? false
          : _.isEmpty(order?.assignees)
            ? true
            : false,
      data: order,
    };
    tableData.push(orderData);

    return tableData;
  });

  const handlePrintJob = (e, data) => {
    setOrderDetails({
      orderId:
        data?.data?.orderInfo?.orderId +
        "/" +
        moment(data?.data?.orderInfo?.orderDate).format("ll"),
      jobOrderId: data?.data?.jobOrderId,
      storeId: data?.data?.storeId + "," + data?.data?.storeName,
      jobOrder_id: data?.data?._id,
      intendId: data?.data?.intendId,
    });
    const frame = data.data?.products.name.split(" ");
    const framePrice = data?.data?.products?.price || " - ";
    const lens = data.data?.products?.lensName || " - ";
    const lensPrice = data.data?.products?.lensItems?.price || " - ";
    const prescription = data.data?.products?.prescriptions || [];
    const frameSize = data.data?.products?.frameSize || {};
    setJobCardData({
      frame: (frame.slice(0, -1)).join('  ')||" - ",
      // frame[0].concat(" ").concat(frame[1]).concat(" ").concat(frame[2]).concat(" ").concat(frame[3]) ||
      // " - ",
      // frame[0].concat(" ").concat(frame[2]).concat(" ").concat(frame[3]) ||
      // " - ",
      framePrice,
      lens,
      lensPrice,
      prescription,
      frameSize,
    });
    setOpenJobCardOrder(true);
  };

  const handleChartIcon = (e, data) => {
    setStepperChart({
      status: data.status,
      assignee: _.isEmpty(data.data.assignees) ? {} : data.data.assignees,
    });
    setOrderDetails({
      orderId:
        data?.data?.orderInfo?.orderId +
        "/" +
        moment(data?.data?.orderInfo?.orderDate).format("ll"),
      jobOrderId: data?.data?.jobOrderId,
      storeId: data?.data?.storeId,
      jobOrder_id: data?.data?._id,
      intendId: data?.data?.intendId,
    });
    setOpenStepperChart(true);
  };

  const handleViewClick = (e, data) => {
    setAssigneeOpen(true);
    setOrderDetails({
      orderId:
        data?.data?.orderInfo?.orderId +
        "/" +
        moment(data?.data?.orderInfo?.orderDate).format("ll"),
      jobOrderId: data?.data?.jobOrderId,
      storeId: data?.data?.storeId,
      jobOrder_id: data?.data?._id,
      intendId: data?.data?.intendId,
    });
    if (_.isEmpty(data?.data?.products?.lensItems)) {
      setIsRequireFitting(false);
    } else {
      setIsRequireFitting(true);
    }
  };

  const handleClose = () => {
    setAssigneeOpen(false);
    setStatusOpen(false);
    setOpenStepperChart(false);
    setOpenJob(false);
    setOpenCostPriceUpdate(false);
    setOpenJobCardOrder(false);
    setIndentSummaryStatus(false);
    setOrderDetails({});
  };

  const handleChange = (event) => {
    setCostPrice(event.target.value);
  };

  const handleStatusClick = (e, data) => {
    if (
      _.isEmpty(data?.data?.products?.lensItems?.costPrice) &&
      !_.isEmpty(data?.data?.products?.lensItems)
    ) {
      setOpenCostPriceUpdate(true);
      setCostPriceDetails(data?.data);
    } else {
      setOpenCostPriceUpdate(false);

      let requireFitting = false;
      if (_.isEmpty(data?.data?.products?.lensItems)) {
        requireFitting = false;
      } else {
        requireFitting = true;
      }
      const role = getNextRole(data?.status, requireFitting);
      if (data?.status === "Fitted" && requireFitting) {
        setIsQc(true);
        setIsShipment(false);
      } else if (
        (data?.status === "Job Order Assigned" || data?.status === "Created") &&
        !requireFitting
      ) {
        setIsQc(true);
        setIsShipment(false);
      } else if (data?.status === "QC Passed") {
        setIsQc(false);
        setIsShipment(false);
      } else if (data?.status === "QC Failed") {
        setIsQc(true);
        setIsShipment(false);
      } else if (data?.status === "Packed") {
        setIsShipment(true);
        setIsQc(false);
      } else {
        setIsQc(false);
        setIsShipment(false);
      }

      setStatusDetails(role);
      setStatusOpen(true);
      setOrderDetails({
        orderId:
          data?.data?.orderInfo?.orderId +
          "/" +
          moment(data?.data?.orderInfo?.orderDate).format("ll"),
        jobOrderId: data?.data?.jobOrderId,
        storeId: data?.data?.storeId,
        jobOrder_id: data?.data?._id,
        intendId: data?.data?.intendId,
      });
    }
  };
  const handleBack = () => {
    history("/indents");
  };

  const openJobCard = () => {
    setOpenJob(true);
    let tempArr = [];

    orderList?.map((order) => {
      const frame = order.products.name.split(" ");
      const framePrice = order.products?.price || " - ";
      const lens = order?.products?.lensName || " - ";
      const lensPrice = order.products?.lensItems?.price || " - ";
      const prescription = order.products?.prescriptions || [];
      const frameSize = order.products?.frameSize || {};

      const orderDetails = {
        orderId:
          order?.orderInfo?.orderId +
          "/" +
          moment(order.orderInfo?.orderDate).format("ll"),
        jobOrderId: order.jobOrderId,
        storeId: order.storeId + "," + order?.storeName,
        jobOrder_id: order?._id,
        intendId: order?.intendId,
        frame: (frame.slice(0, -1)).join('  ')||" - ",
        // frame[0].concat(" ").concat(frame[1]).concat(" ").concat(frame[2]).concat(" ").concat(frame[3]) ||
        // " - ",
        // frame[1].concat(" ").concat(frame[2]).concat(" ").concat(frame[3]) ||
        // " - ",
        framePrice,
        lens,
        lensPrice,
        prescription,
        frameSize,
      };
      tempArr.push(orderDetails);
      return orderDetails;
    });

    setAllOrderDetails(tempArr);
  };

  const openIndentSummary = () => {
    setIndentSummaryStatus(true);
    const indentDataCopy = { ...indentData }
    indentDataCopy.totalCostPrice = 0;
    indentDataCopy.totalGstAmount = 0;

    let tempObj = {
      orderData: [],
      indentData: indentDataCopy
    };

    orderList?.map((order) => {
      tempObj.orderData.push({
        jobOrderId: order.jobOrderId || "-",
        name: order.products.name || "-",
        hsnCode: "9003",
        costPrice: order.products.costPrice || "-",
        gstPercentage: (((Number(order.products.inclGstCostPrice) / Number(order.products.costPrice)) * 100) - 100).toFixed(2),
        gstCostPrice: (Number(order.products.inclGstCostPrice) - Number(order.products.costPrice)).toFixed(2),
        inclGstCostPrice: order.products.inclGstCostPrice || "-"
      })
      tempObj.indentData.totalCostPrice += Number(order.products.costPrice)
      tempObj.indentData.totalGstAmount += Number(order.products.inclGstCostPrice) - Number(order.products.costPrice)

      if (order.products.lensItems) {
        tempObj.orderData.push({
          jobOrderId: order.jobOrderId || "-",
          name: order.products.lensName || "-",
          hsnCode: "9002",
          gstPercentage: (((Number(order.products.inclGstCostPrice) / Number(order.products.costPrice)) * 100) - 100).toFixed(2),
          gstCostPrice: (Number(order.products.lensItems.inclGstCostPrice) - Number(order.products.lensItems.costPrice)).toFixed(2),
          costPrice: order.products.lensItems.costPrice || "-",
          inclGstCostPrice: order.products.lensItems.inclGstCostPrice || "-"
        })
        tempObj.indentData.totalCostPrice += Number(order.products.lensItems.costPrice)
        tempObj.indentData.totalGstAmount += Number(order.products.lensItems.inclGstCostPrice) - Number(order.products.lensItems.costPrice)
      }

      if (order.products.addOnCharges) {
        const addOnKeys = Object.keys(order.products.addOnCharges);

        addOnKeys.map(addOnKey => {
          tempObj.orderData.push({
            jobOrderId: order.jobOrderId || "-",
            name: addOnKey || "-",
            gstCostPrice: order.products.addOnCharges[addOnKey].gstCostPrice || "-",
            gstPercentage: order.products.addOnCharges[addOnKey].gstPercentage || "-",
            hsnCode: order.products.addOnCharges[addOnKey].hsnCode || "-",
            costPrice: order.products.addOnCharges[addOnKey].costPrice || "-",
            inclGstCostPrice: order.products.addOnCharges[addOnKey].inclGstCostPrice || "-"
          })
          tempObj.indentData.totalGstAmount += Number(order.products.addOnCharges[addOnKey].costPrice)
          tempObj.indentData.totalCostPrice += Number(order.products.addOnCharges[addOnKey].costPrice)
        })
      }
    });
    tempObj.indentData.totalGstAmount = tempObj.indentData.totalGstAmount.toFixed(2)
    tempObj.indentData.totalCostPrice = tempObj.indentData.totalCostPrice.toFixed(2)

    setIndentSummaryDetails(tempObj);
  };

  const handleLensUpdation = () => {
    const obj = {
      jobOrder_id: costPriceDetails?._id,
      costPrice: costPrice,
    };
    const data = dispatch(getLensUpdation(obj));
    if (data) {
      setOpenCostPriceUpdate(false);
    }
  };

  const handleFilterChange = (event, value) => {
    setGridFilter(value);
  };

  return (
    <>
      <div
        style={{
          padding: "90px 24px 40px 26px",
          height: "calc(100vh - 12%)",
          overflow: "hidden",
        }}
      >
        <div onClick={handleBack}>
          <BackIcon />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            aligntItems: "center",
          }}
        >
          <div
            style={{
              minWidth: "15%",
              height: "10%",
              borderRadius: "5px",
              backgroundColor: "#fff",
              border: "solid 1px #eaeaea",
              margin: "27px 23px 34px 24px",
              padding: "17px 0px 20px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#827e7e",
                  fontSize: "12px",
                  fontWeight: 500,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.88,
                  letterSpacing: "0.8px",
                  textAlign: "left",
                }}
              >
                INDENT ID
              </Typography>
              <OrderIndentIcon width="31px" height="31px" />
            </div>
            <div
              style={{
                color: "#2b2b2b",
                fontSize: "11px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.19,
                letterSpacing: "normal",
                textAlign: "left",
              }}
            >
              {indentData?.indentId}
            </div>
          </div>

          <div
            style={{
              minWidth: "15%",
              height: "10%",
              borderRadius: "5px",
              backgroundColor: "#fff",
              border: "solid 1px #eaeaea",
              margin: "27px 23px 34px 24px",
              padding: "17px 0px 20px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#827e7e",
                  fontSize: "12px",
                  fontWeight: 500,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.88,
                  letterSpacing: "0.8px",
                  textAlign: "left",
                }}
              >
                INDENT DATE
              </Typography>
              <OrderIndentIcon width="31px" height="31px" />
            </div>
            <div
              style={{
                color: "#2b2b2b",
                fontSize: "11px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.19,
                letterSpacing: "normal",
                textAlign: "left",
              }}
            >
              {indentData?.indentDate}
            </div>
          </div>
          <div
            style={{
              minWidth: "15%",
              height: "10%",
              borderRadius: "5px",
              backgroundColor: "#fff",
              border: "solid 1px #eaeaea",
              margin: "27px 23px 34px 24px",
              padding: "17px 0px 20px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#827e7e",
                  fontSize: "12px",
                  fontWeight: 500,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.88,
                  letterSpacing: "0.8px",
                  textAlign: "left",
                }}
              >
                ORDERS
              </Typography>
              <OrderIndentIcon width="31px" height="31px" />
            </div>
            <div
              style={{
                color: "#2b2b2b",
                fontSize: "11px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.19,
                letterSpacing: "normal",
                textAlign: "left",
              }}
            >
              {indentData?.orders}
            </div>
          </div>
          <div
            style={{
              minWidth: "15%",
              height: "10%",
              borderRadius: "5px",
              backgroundColor: "#fff",
              border: "solid 1px #eaeaea",
              margin: "27px 23px 34px 24px",
              padding: "17px 0px 20px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#827e7e",
                  fontSize: "12px",
                  fontWeight: 500,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.88,
                  letterSpacing: "0.8px",
                  textAlign: "left",
                }}
              >
                TOTAL AMOUNT
              </Typography>
              <OrderIndentIcon width="31px" height="31px" />
            </div>
            <div
              style={{
                color: "#2b2b2b",
                fontSize: "11px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.19,
                letterSpacing: "normal",
                textAlign: "left",
              }}
            >
              {indentData.amount && (indentData?.amount).toFixed(2)}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
            Orders
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              aligntItems: "center",
            }}
          >
            <div style={{ fontSize: 12, color: "#827e7e" }}>ORDER STATUS</div>
            <Autocomplete
              multiple
              id="order-status"
              options={["In Progress", "Completed", "Paid"]}
              sx={{ width: "100%", paddingBottom: "20px" }}
              onChange={(event, newValue) => {
                handleFilterChange(event, newValue);
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    width: "100%",
                    padding: "5px",
                  }}
                  {...params}
                  variant="standard"
                />
              )}
            />
          </div>
        </div>
        <ListTable
          maxHeight={'calc(100vh - 450px)'}
          dataList={tableData}
          handleChartIcon={handleChartIcon}
          handlePrintJob={handlePrintJob}
          handleViewClick={handleViewClick}
          handleStatusClick={handleStatusClick}
          filters={gridFilter}
          Header={[
            "Store ID",
            "Order ID",
            "Order Date",
            "Job Order ID",
            "Frame Info",
            "Lens Type",
            "Order Amount",
            "Current Assignee",
            "Status",
            "",
          ]}
          tableData={[
            { type: ["TEXT"], name: "storeId" },
            { type: ["TEXT"], name: "orderId" },
            { type: ["TEXT"], name: "orderDate" },
            { type: ["TEXT"], name: "jobOrderId" },
            { type: ["TEXT"], name: "frameInfo" },
            { type: ["TEXT"], name: "lensType" },
            { type: ["TEXT"], name: "amount" },
            { type: ["TEXT_LINK"], name: "currentAssignee" },
            { type: ["ORDER_STATUS"], name: "status" },
            { type: ["CHART_ICON"], name: "" },
          ]}
        />
      </div>
      {indentData.status == "Completed" ? (
        <Footer
          back={""}
          onContinue={"Indent Summary"}
          handleContinueButton={openIndentSummary}
        />
      ) : (
        <Footer
          back={""}
          onContinue={"Print All Job Cards"}
          handleContinueButton={openJobCard}
        />
      )}
      <Dialog
        open={openCostPriceUpdate}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "8px", minWidth: "1000px" }}
      >
        <DialogTitle>
          <StyledTypography
            sx={{ padding: "0px" }}
            color={"#2b2b2b"}
            size={20}
            height={1.98}
            weight={600}
          >
            Update Price
          </StyledTypography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid container>
              <Grid item md={6} lg={6} sx={{ padding: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <StyledTypography
                    color={"#827e7e"}
                    size={14}
                    height={1.0}
                    weight={500}
                    sx={{ marginRight: "5px" }}
                  >
                    Lens Type:
                  </StyledTypography>
                  <StyledTypography
                    color={"#2b2b2b"}
                    size={14}
                    height={1.0}
                    weight={500}
                  >
                    {costPriceDetails.products?.lensItems?.lensType}
                  </StyledTypography>
                </div>
              </Grid>
              <Grid item md={6} lg={6} sx={{ padding: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <StyledTypography
                    color={"#827e7e"}
                    size={14}
                    height={1.0}
                    weight={500}
                    sx={{ marginRight: "5px" }}
                  >
                    BRAND:
                  </StyledTypography>
                  <StyledTypography
                    color={"#2b2b2b"}
                    size={14}
                    height={1.0}
                    weight={500}
                  >
                    {costPriceDetails?.products?.lensItems?.brand || "-"}
                  </StyledTypography>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6} lg={6} sx={{ padding: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <StyledTypography
                    color={"#827e7e"}
                    size={14}
                    height={1.0}
                    weight={500}
                    sx={{ marginRight: "5px" }}
                  >
                    COATING:
                  </StyledTypography>
                  <StyledTypography
                    color={"#2b2b2b"}
                    size={14}
                    height={1.0}
                    weight={500}
                  >
                    {costPriceDetails?.products?.lensItems?.lensCoating || "-"}
                  </StyledTypography>
                </div>
              </Grid>
              <Grid item md={6} lg={6} sx={{ padding: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <StyledTypography
                    color={"#827e7e"}
                    size={14}
                    height={1.0}
                    weight={500}
                    sx={{ marginRight: "5px" }}
                  >
                    INDEX:
                  </StyledTypography>
                  <StyledTypography
                    color={"#2b2b2b"}
                    size={14}
                    height={1.0}
                    weight={500}
                  >
                    {costPriceDetails?.products?.lensItems?.index || "-"}
                  </StyledTypography>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6} lg={6} sx={{ padding: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <StyledTypography
                    color={"#827e7e"}
                    size={14}
                    height={1.0}
                    weight={500}
                    sx={{ marginRight: "5px" }}
                  >
                    SELL PRICE:
                  </StyledTypography>
                  <StyledTypography
                    color={"#2b2b2b"}
                    size={14}
                    height={1.0}
                    weight={500}
                  >
                    {costPriceDetails?.products?.lensItems?.price || "-"}
                  </StyledTypography>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6} lg={6} sx={{ padding: "15px" }}>
                <div>
                  <StyledTypography
                    color={"#2b2b2b"}
                    size={14}
                    height={1.0}
                    weight={500}
                    sx={{ marginRight: "5px" }}
                  >
                    COST PRICE(&#8377;)
                  </StyledTypography>
                  <TextField
                    fullWidth
                    onChange={(event) => handleChange(event)}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "25px",
                      marginTop: "5px",
                    }}
                    placeholder="0.00"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div>
            <ContinueButton onClick={handleLensUpdation}>
              Confirm
            </ContinueButton>
          </div>
        </DialogContent>
      </Dialog>
      {openJobCardOrder ? (
        <JobOrderModal
          open={openJobCardOrder}
          handleClose={handleClose}
          data={jobCardData}
          orderDetails={orderDetails}
        />
      ) : (
        " "
      )}
      {assigneeOpen ? (
        <AssigneeModal
          open={assigneeOpen}
          handleClose={handleClose}
          assigneeList={assigneeList}
          requireFitting={isRequireFitting}
          orderDetails={orderDetails}
        />
      ) : (
        " "
      )}
      {statusOpen ? (
        <StatusModal
          open={statusOpen}
          handleClose={handleClose}
          orderDetails={orderDetails}
          statusDetails={statusDetails}
          isShipment={isShipment}
          isQc={isQc}
        />
      ) : (
        " "
      )}
      {openStepperChart ? (
        <StepperComponent
          open={openStepperChart}
          handleClose={handleClose}
          data={stepperChart}
          orderDetails={orderDetails}
        />
      ) : (
        " "
      )}
      {openJob ? (
        <JobCard
          open={openJob}
          handleClose={handleClose}
          data={jobCardData}
          orderDetails={allOrderDetails}
        />
      ) : (
        " "
      )}
      {indentSummaryStatus ? (
        <IndentSummary
          open={indentSummaryStatus}
          handleClose={handleClose}
          data={jobCardData}
          orderDetails={indentSummaryDetails}
        />
      ) : (
        " "
      )}
    </>
  );
}

export default Indents;
