import { List, ListItemText, ListItemButton, Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    BackIcon,
    ContinueButton,
    ListTable,
    OrderIndentIcon,
    StyledTypography,
    Footer,
  } from "../../components";
import { makeStyles } from "@mui/styles";
import { getStoreInventories, clearStores, setPagenoInventories } from "../../app/reducers/storeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

function Stores(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const inventoryList = useSelector(
    (state) => state.storeReducer.inventoryList
  );
  const storeData = useSelector((state) => state.storeReducer.storeData);
  const pageNo = useSelector((state) => state.storeReducer.pageNoInventories);
  const hasMore = useSelector((state) => state.storeReducer.hasMoreInventories);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [inventoryList]);

  useEffect(() => {
    dispatch(getStoreInventories({
      page: 0,
      storeId: storeData?.storeId
    }));
  }, []);

  let tableData = [];

  const data = inventoryList?.map((store) => {
    const storeData = {
      brand: store?.Brand,
      color: store?.ColorCode,
      frameColor: store?.FrameColor,
      model: store?.ModelNo,
      size: store?.SizeActual,
      barcodeId: store?.barcodeId,
      data: store
    };
    tableData.push(storeData);

    return tableData;
  });

  const handleBack = () => {
    history("/stores");
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoInventories(pageNo + 1));
    dispatch(getStoreInventories({
      page: pageNo + 1,
      storeId: storeData?.storeId
    }));
  };

  return (
    <div
      style={{
        padding: "90px 24px 40px 26px",
        height: "calc(100vh - 2%)",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          aligntItems: "center",
        }}
      >   
       <div onClick={handleBack}>
        <BackIcon />
      </div>
         <div style={{ fontSize: 16, color: "#000", fontWeight: 600, paddingLeft: "5px" }}>
          Store Inventories
        </div>
      </div>
      <ListTable
        dataList={tableData}
        isLoading={loading}
        handleLoadmore={handleLoadmore}
        hasMore={hasMore}
        Header={[
          { name :"Brand" },
          { name : "Color Code" },
          { name : "Frame Color" },
          { name : "Model No" },
          { name :"Size Actual" },
          { name :"Barcode ID" },
        ]}
        tableData={[
          { type: ["TEXT"], name: "brand" },
          { type: ["TEXT"], name: "color" },
          { type: ["TEXT"], name: "frameColor" },
          { type: ["TEXT"], name: "model" },
          { type: ["TEXT"], name: "size" },
          { type: ["TEXT"], name: "barcodeId" },
        ]}
      />
    </div>
  );
}

export default Stores;
