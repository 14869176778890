import React, { useRef } from "react";
import {
  Dialog,
  IconButton,
  Grid,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ContinueButton, StyledTypography } from "../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewDetailTable from "./ViewDetailTable";
import { useReactToPrint } from "react-to-print";
import _ from "lodash";
import "./printChart.css";

const JobOrderModal = (props) => {
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Dialog fullWidth open={props.open} onClose={props.handleClose}>
      <div ref={printRef}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", padding: "10px" }}>
            <StyledTypography
              color={"#2b2b2b"}
              weight={600}
              sx={{
                borderBottom: "1px solid #000",
                borderTop: "1px solid #000",
                width: "100%",
                textAlign: "center",
              }}
            >
              {/* EYEPLAY RETAIL LLP */}
              LOFTY OPTICAL INDUSTRIES

            </StyledTypography>
          </div>
          <div style={{ width: "100%", padding: "10px" }}>
            <StyledTypography
              color={"#2b2b2b"}
              weight={600}
              sx={{ width: "100%", textAlign: "center" }}
            >
              JOB CARD
            </StyledTypography>
          </div>

          <div style={{ width: "100%", padding: "0px 20px 0px 20px" }}>
            <StyledTypography color={"#2b2b2b"} weight={600}>
              Account Name
            </StyledTypography>
          </div>
        </div>
        {props?.fromReturns ? (
           <div>
           <div
             style={{
               display: "flex",
               justifyContent: "space-between",
               padding: "0px 20px 0px 20px",
             }}
           >
             <div
               style={{
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
               }}
             >
               <StyledTypography>Store:</StyledTypography>

               <StyledTypography color={"#2b2b2b"} weight={700}>
                 {props?.returnDetails?.storeId + "," + props?.returnDetails?.storeName}
               </StyledTypography>
             </div>
             <div
               style={{
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
               }}
             >
               <StyledTypography>Returns:</StyledTypography>

               <StyledTypography color={"#2b2b2b"} weight={700}>
                 {props?.returnDetails?.returnData}
               </StyledTypography>
             </div>
           </div>
           <div
             style={{
               display: "flex",
               justifyContent: "space-between",
               padding: "0px 20px 0px 20px",
             }}
           >
             <div
               style={{
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
               }}
             >
               <StyledTypography>Order:</StyledTypography>
               <StyledTypography color={"#2b2b2b"} weight={700}>
                 {props?.returnDetails?.orderId}
               </StyledTypography>
             </div>
           </div>
         </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px 0px 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StyledTypography>Store:</StyledTypography>

                <StyledTypography color={"#2b2b2b"} weight={700}>
                  {props?.orderDetails?.storeId}
                </StyledTypography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StyledTypography>Indent:</StyledTypography>

                <StyledTypography color={"#2b2b2b"} weight={700}>
                  {props?.orderDetails?.intendId}
                </StyledTypography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px 0px 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StyledTypography>Order:</StyledTypography>
                <StyledTypography color={"#2b2b2b"} weight={700}>
                  {props?.orderDetails?.orderId}
                </StyledTypography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StyledTypography>Job Id:</StyledTypography>
                <StyledTypography color={"#2b2b2b"} weight={700}>
                  {props?.orderDetails?.jobOrderId}
                </StyledTypography>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "10px 20px",
          }}
        >
          <StyledTypography sx={{ paddingRight: "2px" }}>
            Frame Info:
          </StyledTypography>
          <StyledTypography color={"#2b2b2b"} weight={600}>
            {props?.data?.frame}
          </StyledTypography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "0px 20px",
          }}
        >
          <StyledTypography sx={{ paddingRight: "2px" }}>
            Lens Info:
          </StyledTypography>
          <StyledTypography color={"#2b2b2b"} weight={600}>
            {props?.data?.lens}
          </StyledTypography>
        </div>
        {_.isEmpty(props?.data.prescription) ? (
          <div></div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0px 20px",
            }}
          >
            <ViewDetailTable
              heading={"Prescription"}
              prescriptionData={props?.data.prescription}
            />
          </div>
        )}
        {_.isEmpty(props?.data.frameSize) ? (
          <div></div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0px 20px",
            }}
          >
            <ViewDetailTable
              heading={"Frame"}
              frameData={props?.data.frameSize}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px 10px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledTypography color={"#2b2b2b"} weight={700}>
              Fitting:
            </StyledTypography>
            <StyledTypography
              color={"#2b2b2b"}
              weight={700}
              sx={{ marginLeft: "10px" }}
            >
              __________________________
            </StyledTypography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledTypography color={"#2b2b2b"} weight={700}>
              Time:
            </StyledTypography>
            <StyledTypography
              color={"#2b2b2b"}
              weight={700}
              sx={{ marginLeft: "10px" }}
            >
              _________________
            </StyledTypography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px 10px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledTypography color={"#2b2b2b"} weight={700}>
              QC:
            </StyledTypography>
            <StyledTypography
              color={"#2b2b2b"}
              weight={700}
              sx={{ marginLeft: "10px" }}
            >
              _____________________________
            </StyledTypography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledTypography color={"#2b2b2b"} weight={700}>
              Time:
            </StyledTypography>
            <StyledTypography
              color={"#2b2b2b"}
              weight={700}
              sx={{ marginLeft: "10px" }}
            >
              _________________
            </StyledTypography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "10px 20px 10px 20px",
          }}
        >
          <StyledTypography color={"#2b2b2b"} weight={700}>
            Remarks:
          </StyledTypography>
          <StyledTypography
            color={"#2b2b2b"}
            weight={700}
            sx={{ marginLeft: "10px" }}
          >
            ________________________
          </StyledTypography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "15px 20px 20px 0px",
        }}
      >
        <ContinueButton onClick={handlePrint}>Print Job Card</ContinueButton>
      </div>
    </Dialog>
  );
};

export default JobOrderModal;
