import React from "react";

export const TickIcon = (props) => {
  const width = props?.width ?? "79";
  const height = props?.height ?? "79";
  const color = props?.color ?? "#3bd88e";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
    >
      <path
        d="M30.14 0a30.14 30.14 0 1 0 30.14 30.14A30.19 30.19 0 0 0 30.14 0zm14.407 23.208L27.458 40.3a2.256 2.256 0 0 1-3.195 0l-8.53-8.53a2.259 2.259 0 1 1 3.195-3.195l6.932 6.932 15.492-15.494a2.259 2.259 0 1 1 3.195 3.195z"
        transform="translate(6.028 6.028)"
        fill={color}
        data-name="vuesax/bold/tick-circle"
      />
    </svg>
  );
};
