import { spawn } from 'redux-saga/effects';
import { assigneeSaga } from './assigneeSaga';
import { indentSaga } from './indentSaga';
import { returnsSaga } from './returnsSaga';
import { orderSaga } from './orderSaga';
import { userSaga } from './userSaga';
import { glassSaga } from './glassSaga';
import { brandSaga } from './brandSaga';
import { onboardingSaga } from './onboardingSaga';
import { stockTransferSaga } from './stockTransferSaga';
import { lensCatalogSaga } from './lensCatalogSaga';
import { storeSaga } from './storeSaga';
import { warehouseLoginSaga } from './warehouseLoginSaga';

export default function* rootSaga() {
    yield spawn(indentSaga);    
    yield spawn(returnsSaga);  
    yield spawn(assigneeSaga);  
    yield spawn(orderSaga);
    yield spawn(userSaga);
    yield spawn(glassSaga); 
    yield spawn(brandSaga); 
    yield spawn(onboardingSaga);
    yield spawn(stockTransferSaga);
    yield spawn(lensCatalogSaga);
    yield spawn(storeSaga);
    yield spawn(warehouseLoginSaga);
  }