import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  userList: [],
  userAdded: {},
  userAddedError: "",
  userEdited: {},
  userEditedError: "",
  pageNoUser: 0,
  hasMoreUser: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUsers() {},
    setUsers(state, action) {
      return Object.assign({}, state, {
        userList: state.pageNoUser > 0 ? [...state.userList, ...action.payload] : isEmpty(action.payload) ? [] : action.payload,
        hasMoreUser: action.payload.length < 15 ? false : true 
      });
    },
    setPageNoUser(state, action) {
      return Object.assign({}, state, {
        pageNoUser: action.payload,
      });
    },

    getSaveUsers() {},
    setSaveUsers(state, action) {
      return Object.assign({}, state, {
        userAdded: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setSaveUsersError(state, action) {
      return Object.assign({}, state, {
        userAddedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    updateUsers() {},
    updateUsersSuccess(state, action) {
      return Object.assign({}, state, {
        userEdited: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    updateUsersError(state, action) {
      return Object.assign({}, state, {
        userEditedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    clearUser(state, action) {
      return Object.assign({}, state, initialState);
    }
  },
});

export const {
  getUsers,
  setUsers,
  setPageNoUser,
  setSaveUsers,
  getSaveUsers,
  setSaveUsersError,
  updateUsers,
  updateUsersSuccess,
  updateUsersError,
  clearUser
} = userSlice.actions;

export default userSlice.reducer;
