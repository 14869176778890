import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  IconButton,
  Autocomplete,
  Grid,
} from "@mui/material";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { getEditBrand, editBrandErrors } from "../../app/reducers/brandSlice";
import {
  ContinueButton,
  StyledTypography,
  BackIcon,
  ContinueShoppingButton,
} from "../../components";
import S3 from "react-aws-s3";
import { AlertComp } from "../../components/Alert";
import _ from "lodash";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ViewBrand = (props) => {
  const dispatch = useDispatch();

  const [enableEdit, setEnableEdit] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);
  const [userValue, setUserValue] = useState({
    name: props?.row?.name,
    logo: props?.row?.logo,
  });
  const editBrandError = useSelector(
    (state) => state.brandReducer.editBrandError
  );
  const editBrandData = useSelector((state) => state.brandReducer.editBrand);

  useEffect(() => {
    if (!_.isEmpty(editBrandData)) {
      props?.handleBack();
    }
  }, [editBrandData]);

  const editGlass = () => {
    setEnableEdit(true);
  };

  const handleCancel = () => {
    setEnableEdit(false);
  };

  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET,
    dirName: "eyeplay",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };

  const ReactS3Client = new S3(config);

  const uploadToS3 = async () => {
    return await ReactS3Client.uploadFile(
      userValue?.logo,
      `Master/brand/${userValue?.name}${userValue?.category}`
    );
  };

  const imageUpload = async () => {
    let obj = {};
    const data = imageChanged ? await uploadToS3() : userValue?.logo;
    imageChanged
      ? Object.assign(obj, { logo: data.location })
      : Object.assign(obj, { logo: userValue?.logo });
    return obj;
  };

  const handleEdit = async () => {
    const data = await imageUpload();
    const obj = {
      _id: props.row._id,
      ...userValue,
      ...data,
    };
    dispatch(getEditBrand(obj));
  };

  const handleImageChange = (acceptedFiles) => {
    setImageChanged(true);
    const fileWithPreview = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setUserValue({ ...userValue, logo: fileWithPreview[0] });
  };

  const handleChange = (event, value, name) => {
    if (value) {
      setUserValue({ ...userValue, [name]: value });
    } else {
      setUserValue({ ...userValue, [name]: event.target.value });
    }
  };

  const handleAlertClose = () => {
    dispatch(editBrandErrors(""));
  };
  return (
    <>
      {editBrandError ? (
        <AlertComp
          type="error"
          message={editBrandError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          aligntItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            aligntItems: "center",
          }}
        >
          <div onClick={props?.handleBack}>
            <BackIcon />
          </div>
          <StyledTypography
            sx={{ padding: "0px 0px 0px 10px" }}
            color={"#03a5a5"}
            size={21}
            height={1.23}
            weight={600}
          >
            View Brand
          </StyledTypography>
        </div>
        <div>
          <ContinueButton onClick={editGlass}>Edit Brand</ContinueButton>
        </div>
      </div>
      <Grid>
        <Grid container>
          <Grid item md={6} lg={6} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                BRAND
              </StyledTypography>
              <TextField
                fullWidth
                disabled={!enableEdit}
                value={userValue?.name}
                onChange={(event) => handleChange(event, "", "name")}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="Type Here"
              />
            </div>
          </Grid>
          <Grid item md={6} lg={6} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2+b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                CATEGORY
              </StyledTypography>
              <Autocomplete
                multiple
                id="combo-box-demo"
                size="medium"
                disabled={!enableEdit}
                defaultValue={props?.row.category}
                options={props?.dropDown.FrameCategory || []}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "category")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={8} lg={8} sx={{ padding: "15px" }}>
            <StyledTypography
              sx={{ padding: "20px 10px 30px 0px" }}
              color={"#2b2b2b"}
              size={12}
              height={1.18}
              weight={600}
              maxFiles={1}
            >
              BRAND LOGO
            </StyledTypography>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "20px 23px 40px 33px",
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleImageChange(acceptedFiles);
                }}
                name="logo"
                multiple={false}
                disabled={!enableEdit}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop Thumbnail image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div>
                <img
                  style={{
                    width: "170px",
                    height: "120px",
                    margin: "0",
                    display: "block",
                  }}
                  src={userValue?.logo.preview || userValue?.logo}
                  alt="Logo"
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {enableEdit ? (
            <>
              <div style={{ padding: "10px 0px 0px 0px" }}>
                <ContinueShoppingButton onClick={handleCancel}>
                  Cancel
                </ContinueShoppingButton>
              </div>
              <div style={{ padding: "10px 0px 0px 0px" }}>
                <ContinueButton onClick={handleEdit}>Submit</ContinueButton>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </Grid>
    </>
  );
};

export default ViewBrand;
