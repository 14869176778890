import React from "react";

export const CommentIcon = (props) => {
    const color = props?.color ?? "#03a5a5";
    const width = props?.width ?? "24";
    const height = props?.height ?? "24";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g data-name="vuesax/outline/message-text">
        <path
          d="M10.75 21.56a2.265 2.265 0 0 1-1.8-.96l-1.5-2a.472.472 0 0 0-.2-.1h-.5C2.58 18.5 0 17.37 0 11.75v-5C0 2.33 2.33 0 6.75 0h8c4.42 0 6.75 2.33 6.75 6.75v5c0 4.42-2.33 6.75-6.75 6.75h-.5a.261.261 0 0 0-.2.1l-1.5 2a2.265 2.265 0 0 1-1.8.96zm-4-20.06C3.17 1.5 1.5 3.17 1.5 6.75v5C1.5 16.27 3.05 17 6.75 17h.5a1.876 1.876 0 0 1 1.4.7l1.5 2a.694.694 0 0 0 1.2 0l1.5-2a1.752 1.752 0 0 1 1.4-.7h.5c3.58 0 5.25-1.67 5.25-5.25v-5c0-3.58-1.67-5.25-5.25-5.25z"
          transform="translate(1.25 1.25)"
        //   style="fill:#03a5a5"
        fill={color}
        />
        <path
          data-name="Vector"
          d="M10.75 1.5h-10A.755.755 0 0 1 0 .75.755.755 0 0 1 .75 0h10a.755.755 0 0 1 .75.75.755.755 0 0 1-.75.75z"
          transform="translate(6.25 7.25)"
        //   style="fill:#03a5a5"
        fill={color}
        />
        <path
          data-name="Vector"
          d="M6.75 1.5h-6A.755.755 0 0 1 0 .75.755.755 0 0 1 .75 0h6a.755.755 0 0 1 .75.75.755.755 0 0 1-.75.75z"
          transform="translate(6.25 12.25)"
        //   style="fill:#03a5a5"
        fill={color}
        />
      </g>
    </svg>
  );
};
