import React from "react";

export const FilterIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g data-name="vuesax/linear/setting-4">
        <path
          d="M6 0H0"
          transform="translate(16 6.5)"
          stroke="#03a5a5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5px"
          fill="none"
        />
        <path
          data-name="Vector"
          d="M4 0H0"
          transform="translate(2 6.5)"
          stroke="#03a5a5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5px"
          fill="none"
        />
        <path
          data-name="Vector"
          d="M7 3.5A3.5 3.5 0 1 1 3.5 0 3.5 3.5 0 0 1 7 3.5z"
          transform="translate(6.5 3)"
          stroke="#03a5a5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5px"
          fill="none"
        />
        <path
          data-name="Vector"
          d="M4 0H0"
          transform="translate(18 17.5)"
          stroke="#03a5a5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5px"
          fill="none"
        />
        <path
          data-name="Vector"
          d="M6 0H0"
          transform="translate(2 17.5)"
          stroke="#03a5a5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5px"
          fill="none"
        />
        <path
          data-name="Vector"
          d="M7 3.5A3.5 3.5 0 1 1 3.5 0 3.5 3.5 0 0 1 7 3.5z"
          transform="translate(10.5 14)"
          stroke="#03a5a5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5px"
          fill="none"
        />
      </g>
    </svg>
  );
};
