import { configureStore } from "@reduxjs/toolkit";
import 'regenerator-runtime/runtime';
import rootReducer from './reducers';
import createSagaMiddleware from "redux-saga";
import logger from 'redux-logger';
import rootSaga from "./saga";
import barcodeScannerMiddleware from './barcodeScannerMiddleware';

const sagaMiddleware = createSagaMiddleware();

const devMode = process.env.NODE_ENV === "development";

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({thunk: false}).concat(sagaMiddleware).concat(barcodeScannerMiddleware).concat(logger);

export const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  devTools: devMode,
});

sagaMiddleware.run(rootSaga);
