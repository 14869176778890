import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  glassList: [],
  glassDropDown: {},
  glassAdded: [],
  glassAddedError: "",
  glassEdited: [],
  glassEditedError: "",
  pageNo: 0,
  hasMore: true,
};

export const glassSlice = createSlice({
  name: "glass",
  initialState,
  reducers: {
    getGlass() {},
    setGlass(state, action) {
      return Object.assign({}, state, {
        glassList: state.pageNo > 0 ? [...state.glassList, ...action.payload] : isEmpty(action.payload) ? [] : action.payload,
        hasMore: action.payload.length < 15 ? false : true 
      });
    },
    setPageno(state, action) {
      return Object.assign({}, state, {
        pageNo: action.payload,
      });
    },

    getGlassDropDowns() {},
    setGlassDropDowns(state, action) {
      return Object.assign({}, state, {
        glassDropDown: isEmpty(action.payload) ? {} : action.payload,
      });
    },

    getBrandDropDowns() {},
    setBrandDropDowns(state, action) {
      return Object.assign({}, state, {
        brandDropDown: isEmpty(action.payload) ? {} : action.payload,
      });
    },

    getSaveGlass() {},
    getSaveGlassSuccess(state, action) {
      return Object.assign({}, state, {
        glassAdded: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getSaveGlassError(state, action) {
      return Object.assign({}, state, {
        glassAddedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    getUpdateGlass() {},
    getUpdateGlassSuccess(state, action) {
      return Object.assign({}, state, {
        glassEdited: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getUpdateGlassError(state, action) {
      return Object.assign({}, state, {
        glassEditedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    clearGlass(state, action) {
      return Object.assign({}, state, initialState);
    }
  },
});

export const {
  getGlass,
  setGlass,
  setPageno,
  setGlassDropDowns,
  getGlassDropDowns,
  getSaveGlass,
  getSaveGlassSuccess,
  getSaveGlassError,
  getUpdateGlass,
  getUpdateGlassSuccess,  
  getUpdateGlassError,
  getBrandDropDowns,
  setBrandDropDowns,
  clearGlass
} = glassSlice.actions;

export default glassSlice.reducer;
