import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import { CircleTick } from "../svg";
import {
  ContinueButton,
  HeadingTypography,
  SubLabelTypography,
} from "../wrapperComponents";

const SuccessModal = (props) => {
  return (
    <Dialog
      PaperProps={{ classes: { root: props.paperWidth } }}
      open={props?.success}
    >
      <DialogContent>
        <div style={{ textAlign: "center", width: "320px" }}>
          <CircleTick width={"79"} height={"79"} color={"#3bd88e"} />
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            {props?.successHeader}
          </SubLabelTypography>
          <HeadingTypography sx={{ pb: "20px" }}>
            {props?.succesSubHeader}
          </HeadingTypography>
          <ContinueButton onClick={props?.handleSuccessClose} marginLeft={"0px"}>{props?.button}</ContinueButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;
