import React from "react";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import {
  BackIcon,
  StyledTypography,
  SubLabelTypography,
  OrderIndentIcon,
  ListTable
} from "../../../components";
import { Typography } from "@mui/material";
import moment from "moment";

function TransferProducts(props) {
  const history = useNavigate();
  const classes = useStyles();

  const handleBack = () => {
    props.handleBack();
  };

   let tableData = [];

   props?.data?.inventoryDetails?.map((inventory) => {
      const inventoryData = {
        brand: inventory?.barcodeDetails?.Brand,
        model: inventory?.barcodeDetails?.ModelNo,
        color: inventory?.barcodeDetails?.FrameColor,
        colorCode: inventory?.barcodeDetails?.ColorCode,
        sizeActual: inventory?.barcodeDetails?.SizeActual
      };
      tableData.push(inventoryData);
      return tableData;
   });
  return (
    <div>
      <div onClick={handleBack} style={{paddingBottom:"20px",cursor:"pointer"}}>
        <BackIcon />
      </div>
      <div className={classes.alignItems}>
        <div className={classes.box} >
          <div className={classes.alignEntireFlex} style={{alignItems:"flex-start"}}>
              <div style={{marginRight:"22px"}}>
                <SubLabelTypography>TRANSFER ID</SubLabelTypography>
                <StyledTypography color="#2b2b2b" letter="0px" weight={600}>{props?.data?.transferId}</StyledTypography>
              </div>
              <OrderIndentIcon width="31px" height="31px" />
          </div>
        </div>

        <div className={classes.box} >
          <div className={classes.alignEntireFlex} style={{alignItems:"flex-start"}}>
              <div style={{marginRight:"22px"}}>
                <SubLabelTypography>TRANSFER DATE</SubLabelTypography>
                <StyledTypography color="#2b2b2b" letter="0px" weight={600}>{moment(props?.data?._created_at).format('ll')}</StyledTypography>
              </div>
              <OrderIndentIcon width="31px" height="31px" />
          </div>
        </div>

        <div className={classes.box} >
          <div className={classes.alignEntireFlex} style={{alignItems:"flex-start"}}>
              <div style={{marginRight:"22px"}}>
                <SubLabelTypography>REASON</SubLabelTypography>
                <StyledTypography color="#2b2b2b" letter="0px" weight={600}>{props?.data?.reason}</StyledTypography>
              </div>
              <OrderIndentIcon width="31px" height="31px" />
          </div>
        </div>

        <div className={classes.box} >
          <div className={classes.alignEntireFlex} style={{alignItems:"flex-start"}}>
              <div style={{marginRight:"22px"}}>
                <SubLabelTypography>Total Inventories</SubLabelTypography>
                <StyledTypography color="#2b2b2b" letter="0px" weight={600}>{tableData.length}</StyledTypography>
              </div>
              <OrderIndentIcon width="31px" height="31px" />
          </div>
        </div>
      </div>

      <Typography sx={{ fontSize: "18px", fontWeight: 600, margin:"20px 0px 10px" }}>
         Transfer Product
      </Typography>
      <ListTable
            dataList={tableData}
            Header={[
              "Brand",
              "Model",
              "Color",
              "Color Code",
              "Size Actual",
            ]}
            paddingLeft="100px"
            tableData={[
              { type: ["TEXT"], name: "brand" },
              { type: ["TEXT"], name: "model" },
              { type: ["TEXT"], name: "color" },
              { type: ["TEXT"], name: "colorCode" },
              { type: ["TEXT"], name: "sizeActual" },
            ]}
          />{" "}
    </div>
  );
}

export default TransferProducts;
