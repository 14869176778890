import {
  Button,
  Dialog,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ContinueButton,
  BackIcon,
  FilterIcon,
  ListTable,
  SearchIcon,
  StyledTypography,
  SubLabelTypography,
  Footer,
} from "../../../components";
import config from "./config";
import Scanner from "./Scanner";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";
import StoreTransferModal from "./StoreTransferModal";
import {
  BarcodeScanner,
  enableBarcodeScanner,
  disableBarcodeScanner,
  setHistoryInfo,
} from "react-usb-barcode-scanner";

// import BarcodeScannerComponent from "../../../components/Scanner/Scanner";
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {
  getInventory,
  deleteInventory,
  getSaveExchange,
} from "../../../app/reducers/stockTransferSlice";
import { useDispatch, useSelector } from "react-redux";
import { TickIcon } from "../../../components/svg/TickIcon";
import _ from "lodash";

function ScanAddStockTransfer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openChangeStore, setOpenChangeStore] = useState(true);
  const [selectedStore, setSelectedStore] = useState({});

  const [openBarcode, setOpenBarcode] = useState(false);
  const [enteredBarcode, setEnteredBarcode] = useState("");

  const [openScanner, setOpenScanner] = useState(false);
  const [scannedData, setScannedData] = useState("");
  const [isScanned, setIsScanned] = useState(false);
  const [usedBarcodesEntered, setUsedBarcodesEntered] = useState([]);
  const [success, setSuccess] = useState(false);

  const inventoryList = useSelector(
    (state) => state.stockTransferReducer.inventory
  );

  const barcodeUsb = useSelector(
    (state) => state.stockTransferReducer.barcodeUsb
  );

  const tableData = [];

  if (!_.isEmpty(inventoryList)) {
    inventoryList?.map((inventory) => {
      const stocksData = {
        barcode: inventory?.barcodeId,
        brand: inventory?.Brand,
        model: inventory?.ModelNo,
        color: inventory?.FrameColor,
        colorCode: inventory?.ColorCode,
        sizeActual: inventory?.SizeActual,
        data: inventory,
      };
      tableData.push(stocksData);

      return tableData;
    });
  }
  const handleDelete = (e, data, index) => {
    dispatch(deleteInventory(index));
  };

  const handleSubmit = (data) => {
    setSelectedStore(data);
    setOpenChangeStore(false);
  };

  const handleBarcode = () => {
    setOpenBarcode(true);
  };

  const addBarcode = () => {
    if (!usedBarcodesEntered.includes(enteredBarcode)) {
      const usedBarcodesEnteredLocal = [...usedBarcodesEntered];
      usedBarcodesEnteredLocal.push(enteredBarcode);
      setUsedBarcodesEntered(usedBarcodesEnteredLocal);
      dispatch(getInventory(enteredBarcode));
    }
    setOpenBarcode(false);
  };

  const handleChange = (event) => {
    setEnteredBarcode(event.target.value);
  };

  const handleScan = () => {
    setOpenScanner(true);
    // props.enableBarcodeScanner();
  };


  useEffect(() => {
    // props.disableBarcodeScanner();
  },[]);

  useEffect(() => {
    if (!_.isEmpty(barcodeUsb) && openScanner) {
      if (!usedBarcodesEntered.includes(barcodeUsb)) {
        const usedBarcodesEnteredLocal = [...usedBarcodesEntered];
        usedBarcodesEnteredLocal.push(barcodeUsb);
        setUsedBarcodesEntered(usedBarcodesEnteredLocal);
        dispatch(getInventory(barcodeUsb));
      }
    }
  }, [barcodeUsb]);

  const handleClose = () => {
    setOpenChangeStore(false);
    setOpenBarcode(false);
    setSuccess(false);
  };

  const handleChangeStore = () => {
    setOpenChangeStore(true);
  };

  const handleSubmitExchange = () => {
    let temp = [];
    inventoryList?.map((data) => {
      temp.push({ barcodeId: data?.barcodeId });
    });

    const obj = {
      inventories: temp,
      reason: "Intial onboarding",
      storeId: selectedStore.storeId,
    };
  
    dispatch(getSaveExchange(obj));
    setSuccess(true);
  };

  
  return (
    <>
      <>
        <div>
          <div
            className={classes.alignEntireFlex}
            style={{ marginBottom: "15px" }}
          >
            <Typography
              sx={{ fontSize: "18px", fontWeight: 600, color: "#03a5a5" }}
            >
              STOCK TRANSFER
            </Typography>
          </div>

          <Grid container alignItems={"center"}>
            <Grid item xs={3} sx={{ padding: "0px 0px 12px 0px" }}>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Search Brand Name/Model"
                fullWidth
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon width={"12"} height={"12"} />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.notchedOutline,
                  },
                }}
                style={{ backgroundColor: "#fff", borderRadius: "35px" }}
              />
            </Grid>
            <Grid item xs={1}>
              <div
                className={classes.alignItems}
                style={{ marginLeft: "20px" }}
              >
                <FilterIcon />
                <SubLabelTypography margin={"0px 0px 0px 4px"}>
                  FILTER
                </SubLabelTypography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.alignItems}>
                <SubLabelTypography margin={"0px 0px 0px 12px"}>
                  {"SELECTED STORE: "}
                </SubLabelTypography>
                <StyledTypography color={"#2b2b2b"} weight={"600"}>
                  {selectedStore.name || "-"}
                </StyledTypography>
                <Button
                  onClick={handleChangeStore}
                  style={{ textTransform: "none", color: "#03a5a5" }}
                >
                  Change
                </Button>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <ContinueButton onClick={handleBarcode}>
                  Enter Barcode
                </ContinueButton>
                <ContinueButton onClick={handleScan}>
                  Scan &#38; Add
                </ContinueButton>
              </div>
            </Grid>
          </Grid>
          {  openScanner ? <BarcodeScanner config={config} /> : ""}
          <div className={classes.alignEntire} style={{ marginBottom: "15px" }}>
            <StyledTypography color="#242424" letter="0px" weight={600}>
              {"Scan & Add Products"}
            </StyledTypography>
            <SubLabelTypography>
              Scanned Products:
              <span style={{ fontWeight: 600, color: "#242424" }}>
                {_.isEmpty(inventoryList) ? 0 : inventoryList.length}
              </span>
            </SubLabelTypography>
          </div>
          <ListTable
            dataList={tableData}
            isExchange={true}
            handleView={handleDelete}
            Header={[
              "Barcode",
              "Brand",
              "Model",
              "Color",
              "Color Code",
              "Size Actual",
              "",
            ]}
            paddingLeft="100px"
            tableData={[
              { type: ["TEXT"], name: "barcode" },
              { type: ["TEXT"], name: "brand" },
              { type: ["TEXT"], name: "model" },
              { type: ["TEXT"], name: "color" },
              { type: ["TEXT"], name: "colorCode" },
              { type: ["TEXT"], name: "sizeActual" },
              { type: ["DELETE"], name: "status" },
            ]}
          />
        </div>
        <Footer
          back={"Cancel"}
          handleBackButton={props?.handleBack}
          disabled={inventoryList.length > 0 ? false : true}
          onContinue={"Confirm"}
          handleContinueButton={handleSubmitExchange}
        />

        <StoreTransferModal
          open={openChangeStore}
          close={handleClose}
          handleSubmit={handleSubmit}
        />
        {success ? (
          <div>
            <Dialog
              open={success}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ borderRadius: "8px" }}
            >
              <div style={{ padding: "20px 60px 40px" }}>
                <div style={{ textAlign: "center" }}>
                  <div style={{ paddingLeft: "25px" }}>
                    <TickIcon />
                  </div>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, mb: 3 }}
                  >
                    Stock Transferred Successfully to "{selectedStore?.name}"
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, mb: 3 }}
                  >
                    Total Quantity :
                    {_.isEmpty(inventoryList) ? 0 : inventoryList.length}
                  </Typography>
                  <ContinueButton onClick={props?.handleBack}>
                    Back To Stock Transfer
                  </ContinueButton>
                </div>
              </div>
            </Dialog>
          </div>
        ) : (
          " "
        )}
        <Dialog maxWidth={false} open={openBarcode} onClose={handleClose}>
          <div style={{ width: "487px", height: "180px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                padding: "0px 10px",
              }}
            >
              <StyledTypography
                sx={{ margin: "20px 60px 30px 11px" }}
                color={"#2b2b2b"}
                size={18}
                height={1.28}
                weight={700}
              >
                Add Products
              </StyledTypography>
              <div>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "#242424",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <Grid container sx={{ padding: "20px 0px 20px 20px" }}>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <TextField
                  fullWidth
                  size="small"
                  onChange={(event) => handleChange(event)}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder="Enter Barcode"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <ContinueButton onClick={addBarcode}>Add</ContinueButton>
              </Grid>
            </Grid>
          </div>
        </Dialog>
        {/* {openScanner ? (
          <>
            <BarcodeScannerComponent
            scan={openScanner}
            setBarcode={changeBarcode}
          />
          <div>{scannedData}data</div> */}
            {/* <BarcodeScannerComponent
            width={500}
            height={500}
            onUpdate={(err, result) => {
              if (result) onDetected(result.text);
              else onDetected("Not Found");
            }}
          />
            <BarcodeScanner config={config} />
          </>
        ) : (
          ""
        )} */}
      </>
    </>
  );
}

export default ScanAddStockTransfer;
