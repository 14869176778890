import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    notchedOutline: {
        borderRadius: "35px !important",
    },
    noOutline:{
        fontSize:"14px",
        padding:"0px",
        paddingRight:"0px !important"
    },
    root:{
        padding: "80px 24px 40px 26px",
        height: "calc(100vh - 2%)",
        overflow: "hidden",
    },
    alignItems:{
        display:"flex",alignItems:"center"
    },
    modelSize:{
        maxWidth:"750px"
    },
    alignEntireFlex:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start"
    },
    alignEntire:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    },
    box:{
        width:"200px",
        border: "1px solid #eaeaea",
        borderRadius: "5px",
        marginRight: "23px",
        padding: "20px 0px 20px 20px",
    }
})