import React, { useEffect, useState } from "react";
import { Grid, Autocomplete, TextField } from "@mui/material";
import {
  ContinueButton,
  StyledTypography,
  BackIcon,
  ContinueShoppingButton,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import Dropzone from "react-dropzone";
import S3 from "react-aws-s3";
import _ from "lodash";
import { getSaveGlass, getSaveGlassError } from "../../app/reducers/glassSlice";
import { AlertComp } from "../../components/Alert";
window.Buffer = window.Buffer || require("buffer").Buffer;

const AddGlass = (props) => {
  const dispatch = useDispatch();
  const dropDown = useSelector((state) => state.glassReducer.glassDropDown);
  const brandDropDown = useSelector(
    (state) => state.glassReducer.brandDropDown
  );
  const glassAddedError = useSelector(
    (state) => state.glassReducer.glassAddedError
  );
  const glassAdded = useSelector((state) => state.glassReducer.glassAdded);

  useEffect(() => {
    if (!_.isEmpty(glassAdded)) {
      props.handleBack();
    }
  }, [glassAdded]);

  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET, //"oicappsv3-dev",
    dirName: "eyeplay",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };

  const [error, setError] = useState({});
  const [imageUploading, setImageUploading] = useState(false);
  const [userValue, setUserValue] = useState({});
  const [colorValue, setColorValue] = useState([
    {
      ColorCode: "",
      FrameColor: "",
      LensColor: "",
      RimColor: "",
      TempleColor: "",
      SizeArr: [
        {
          Size: "",
          Width: "",
          BridgeSize: "",
          TempleSize: "",
          Height: "",
          Quantity: "",
        },
      ],
      ThumbnailImage: {},
      ProductImages: [],
    },
  ]);

  const handleBack = () => {
    props.handleBack();
  };
  const ReactS3Client = new S3(config);

  const uploadToS3 = () => {
    setImageUploading(true);
    const promise = colorValue.map(async (color, index) => {
      if (!_.isEmpty(color?.ThumbnailImage)) {
        const currentTime = Math.floor(Date.now() / 1000);
        return await ReactS3Client.uploadFile(
          color?.ThumbnailImage,
          `inventory/thumbnails/${currentTime}${index}`
        );
      } else {
        return new Promise((resolve, reject) =>
          resolve({
            location: "",
          })
        );
      }
    });

  
    return Promise.all(promise);
  };

  const uploadProductToS3 = (color, i) => {
    setImageUploading(true);
    const promise = color.ProductImages.map(async (product, index) => {
      if (!_.isEmpty(product)) { 
        const currentTime = Math.floor(Date.now() / 1000);
        return await ReactS3Client.uploadFile(
          product,
          `inventory/productImages/${currentTime}${index}${i}`
        );
      } else {
        return new Promise((resolve, reject) =>
          resolve({
            location: "",
          })
        );
      }
    });
    return Promise.all(promise);
  };

  const handleImageUpload = async () => {
    const data = await uploadToS3();
    let prod;
    if (data) {
      prod = colorValue?.map(async (color, index) => {
        color["ThumbnailImage"] = data[index].location;
        const data3 = await uploadProductToS3(color, index);
        let tempArray = [];

        data3.map((data) => tempArray.push(data.location));
        color["ProductImages"] = tempArray;
        return color;
      });
    }
    return Promise.all(prod);
  };

  const generateMultiProducts = () => {
    let arr = [];
    colorValue.map((color) => {
      color.SizeArr.map((size) => {
        const temp = {};
        const val =
          size?.Width +
          "-" +
          size?.BridgeSize +
          "-" +
          size.TempleSize +
          "-" +
          size.Height;
        const sizeValue = {
          SizeActual: val,
        };
        Object.assign(temp, userValue, color, size, sizeValue);
        arr.push(temp);
      });
    });
    return arr;
  };

  const sizeValidation = (name) => {
    let temp = 0;
    colorValue.map((color) => {
      temp += color?.SizeArr.filter((val) => val[name]).length;
    });
    return temp;
  };

  const validation = async () => {
    let total = 0;

    colorValue.map((color) => {
      total += color?.SizeArr.length;
      return total;
    });

    let isValid = false;
    if (_.isEmpty(userValue?.Category)) {
      isValid = false;
      setError({ Category: "Category is Mandatory" });
    } else if (_.isEmpty(userValue?.Brand)) {
      isValid = false;
      setError({ Brand: "Brand is Mandatory" });
    } else if (_.isEmpty(userValue?.ModelNo)) {
      isValid = false;
      setError({ ModelNo: "Model is Mandatory" });
    } else if (_.isEmpty(userValue?.Shape)) {
      isValid = false;
      setError({ Shape: "Shape is Mandatory" });
    } else if (_.isEmpty(userValue?.Gender)) {
      isValid = false;
      setError({ Gender: "Gender is Mandatory" });
    } else if (_.isEmpty(userValue?.FrameType)) {
      isValid = false;
      setError({ FrameType: "FrameType is Mandatory" });
    } else if (_.isEmpty(userValue?.FrameMaterial)) {
      isValid = false;
      setError({ FrameMaterial: "FrameMaterial is Mandatory" });
    } else if (_.isEmpty(userValue?.TempleMaterial)) {
      isValid = false;
      setError({ TempleMaterial: "TempleMaterial is Mandatory" });
    } else if (_.isEmpty(userValue?.CostPrice)) {
      isValid = false;
      setError({ CostPrice: "CostPrice is Mandatory" });
    } else if (_.isEmpty(userValue?.SellPrice)) {
      isValid = false;
      setError({ SellPrice: "SellPrice is Mandatory" });
    } else if (
      colorValue.filter((color) => color?.ColorCode).length !==
      colorValue.length
    ) {
      isValid = false;
      setError({ ColorCode: "ColorCode is Mandatory" });
    } else if (
      colorValue.filter((color) => color?.FrameColor).length !==
      colorValue.length
    ) {
      isValid = false;
      setError({ FrameColor: "FrameColor is Mandatory" });
    } else if (
      colorValue.filter((color) => color?.RimColor).length !== colorValue.length
    ) {
      isValid = false;
      setError({ RimColor: "RimColor is Mandatory" });
    } else if (
      colorValue.filter((color) => color?.TempleColor).length !==
      colorValue.length
    ) {
      isValid = false;
      setError({ TempleColor: "TempleColor is Mandatory" });
    } else if (sizeValidation("Size") !== total) {
      isValid = false;
      setError({ Size: "Size is Mandatory" });
    } else if (sizeValidation("Width") !== total) {
      isValid = false;
      setError({ Width: "Width is Mandatory" });
    } else if (sizeValidation("BridgeSize") !== total) {
      isValid = false;
      setError({ BridgeSize: "BridgeSize is Mandatory" });
    } else if (sizeValidation("TempleSize") !== total) {
      isValid = false;
      setError({ TempleSize: "TempleSize is Mandatory" });
    } else if (sizeValidation("Height") !== total) {
      isValid = false;
      setError({ Height: "Height is Mandatory" });
    } else if (sizeValidation("Quantity") !== total) {
      isValid = false;
      setError({ Quantity: "Quantity is Mandatory" });
    } else {
      isValid = true;
    }

    return isValid;
  };

  const handleSubmitGlass = async () => {
    const valid = await validation();
    if (valid) {
      const data = await handleImageUpload();
      setColorValue(data);
      const data2 = generateMultiProducts();
      const obj = {
        inventories: data2,
      };
      dispatch(getSaveGlass(obj));
    }
  };

  const handleChange = (event, value, name) => {
    if (name === "Brand") {
      setUserValue({ ...userValue, [name]: value.name });
    } else {
      if (value) {
        setUserValue({ ...userValue, [name]: value });
      } else {
        setUserValue({ ...userValue, [name]: event.target.value });
      }
    }
  };

  const handleImageChange = (name, index, files) => {
    if (name === "ThumbnailImage") {
      const fileWithPreview = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      const temp = colorValue.map((color, i) => {
        if (index === i) {
          color[name] = fileWithPreview[0];
        }
        return color;
      });
      setColorValue(temp);
    } else {
      const fileWithPreview = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      const temp = colorValue.map((color, i) => {
        if (index === i) {
          color[name] = fileWithPreview;
        }
        return color;
      });
      setColorValue(temp);
    }
  };

  const handleColorChange = (event, value, name, index, Files) => {
    if (value) {
      const temp = colorValue.map((color, i) => {
        if (index === i) {
          color[name] = value;
        }
        return color;
      });
      setColorValue(temp);
    } else {
      const temp = colorValue.map((color, i) => {
        if (index === i) {
          color[name] = event.target.value;
        }
        return color;
      });
      setColorValue(temp);
    }
  };

  const handleColorDelete = (event, colorIndex) => {
    const temp = colorValue.filter((color, i) => i !== colorIndex);
    setColorValue(temp);
  };

  const handleColorAdd = () => {
    const addObj = {
      ColorCode: "",
      FrameColor: "",
      LensColor: "",
      RimColor: "",
      TempleColor: "",
      SizeArr: [
        {
          Size: "",
          Width: "",
          BridgeSize: "",
          TempleSize: "",
          Height: "",
          Quantity: "",
        },
      ],
      ThumbnailImage: {},
      ProductImages: [],
    };
    setColorValue([...colorValue, addObj]);
  };

  const handleSizeChange = (event, value, name, index, colorIndex) => {
    if (value) {
      const temp = colorValue.map((color, i) => {
        if (colorIndex === i) {
          color.SizeArr.map((size, i) => {
            if (index === i) {
              size[name] = value;
            }
            return size;
          });
        }
        return color;
      });
      setColorValue(temp);
    } else {
      const temp = colorValue.map((color, i) => {
        if (colorIndex === i) {
          color.SizeArr.map((size, i) => {
            if (index === i) {
              size[name] = event.target.value;
            }
            return size;
          });
        }
        return color;
      });
      setColorValue(temp);
    }
  };

  const handleSizeDelete = (e, colorIndex, index) => {
    let tempColor = [];

    const temp = colorValue.map((color, i) => {
      if (colorIndex === i) {
        color.SizeArr = color.SizeArr.filter((value, i) => i !== index);
      }
      return color;
    });
    setColorValue(temp);
  };

  const handleSizeAdd = (e, colorIndex) => {
    const addObj = {
      Size: "",
      Width: "",
      BridgeSize: "",
      TempleSize: "",
      Height: "",
      Quantity: "",
    };

    const temp = colorValue.map((color, i) => {
      if (colorIndex === i) {
        color.SizeArr.push(addObj);
      }
      return color;
    });
    setColorValue(temp);
  };

  const handleAlertClose = () => {
    dispatch(getSaveGlassError(""));
  };

  useEffect(() => {
    if (!_.isEmpty(glassAddedError)) {
      const temp = colorValue.map((color, i) => {
        color.ThumbnailImage = "";
        color.ProductImages = [];
        return color;
      });
      setColorValue(temp);
    }
  }, [glassAddedError]);

  return (
    <>
      {glassAddedError ? (
        <AlertComp
          type="error"
          message={glassAddedError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          aligntItems: "center",
        }}
      >
        <div onClick={handleBack}>
          <BackIcon />
        </div>
        <StyledTypography
          sx={{ padding: "0px 0px 0px 10px" }}
          color={"#03a5a5"}
          size={21}
          height={1.23}
          weight={600}
        >
          ADD GLASS
        </StyledTypography>
      </div>

      <StyledTypography
        sx={{ padding: "10px 10px 10px 10px" }}
        color={"#000"}
        size={16}
        height={1.13}
        weight={600}
      >
        Basic Information
      </StyledTypography>

      <Grid container>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2+b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                CATEGORY
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={dropDown.FrameCategory}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "Category")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    error={error.Category}
                    helperText={error.Category}
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                BRAND
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={brandDropDown}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "Brand")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    error={error.Brand}
                    helperText={error.Brand}
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                MODEL
              </StyledTypography>
              <TextField
                error={error.ModelNo}
                helperText={error.ModelNo}
                fullWidth
                onChange={(event) => handleChange(event, "", "ModelNo")}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                SHAPE
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={dropDown.Shape}
                placeholder="Select"
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "Shape")
                }
                size="medium"
                renderInput={(params) => (
                  <TextField
                    error={error.Shape}
                    helperText={error.Shape}
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                GENDER
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={dropDown.Gender}
                placeholder="Select"
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "Gender")
                }
                size="medium"
                renderInput={(params) => (
                  <TextField
                    error={error.Gender}
                    helperText={error.Gender}
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                FRAME TYPE
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={dropDown.FrameType}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "FrameType")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    error={error.FrameType}
                    helperText={error.FrameType}
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                FRAME MATERIAL
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={dropDown.FrameMaterial}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "FrameMaterial")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    error={error.FrameMaterial}
                    helperText={error.FrameMaterial}
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                TEMPLE MATERIAL
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={dropDown.FrameMaterial}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "TempleMaterial")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    error={error.TempleMaterial}
                    helperText={error.TempleMaterial}
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                COST PRICE (&#8377;)
              </StyledTypography>
              <TextField
                fullWidth
                error={error.CostPrice}
                helperText={error.CostPrice}
                onChange={(event) => handleChange(event, "", "CostPrice")}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="0.00"
              />
            </div>
          </Grid>
          <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                SELL PRICE (&#8377;)
              </StyledTypography>
              <TextField
                fullWidth
                error={error.SellPrice}
                helperText={error.SellPrice}
                onChange={(event) => handleChange(event, "", "SellPrice")}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="0.00"
              />
            </div>
          </Grid>
        </Grid>

        <StyledTypography
          sx={{ padding: "10px 10px 10px 10px" }}
          color={"#000"}
          size={16}
          height={1.13}
          weight={600}
        >
          ADD COLOR &#38; SIZE
        </StyledTypography>
        {colorValue.map((color, colorIndex) => {
          return (
            <>
              <Grid container>
                <Grid container>
                  <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
                    <div>
                      <StyledTypography
                        sx={{ padding: "20px 10px 30px 0px" }}
                        color={"#2b2b2b"}
                        size={17}
                        height={1.18}
                        weight={600}
                      >
                        COLOR CODE
                      </StyledTypography>
                      <TextField
                        fullWidth
                        value={color?.ColorCode}
                        error={error.ColorCode}
                        helperText={error.ColorCode}
                        onChange={(event) =>
                          handleColorChange(event, "", "ColorCode", colorIndex)
                        }
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "25px",
                        }}
                        placeholder="Enter Color Code"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
                    <div>
                      <StyledTypography
                        sx={{ padding: "20px 10px 30px 0px" }}
                        color={"#2b2b2b"}
                        size={17}
                        height={1.18}
                        weight={600}
                      >
                        FRAME COLOR
                      </StyledTypography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="medium"
                        value={color?.FrameColor}
                        options={dropDown.RimColor}
                        onChange={(event, newValue) =>
                          handleColorChange(
                            event,
                            newValue,
                            "FrameColor",
                            colorIndex
                          )
                        }
                        placeholder="Select"
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            error={error.FrameColor}
                            helperText={error.FrameColor}
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "25px",
                            }}
                            {...params}
                            placeholder="Select"
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
                    <div>
                      <StyledTypography
                        sx={{ padding: "20px 10px 30px 0px" }}
                        color={"#2b2b2b"}
                        size={17}
                        height={1.18}
                        weight={600}
                      >
                        LENS COLOR
                      </StyledTypography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="medium"
                        value={color?.LensColor}
                        options={dropDown.LensColor}
                        onChange={(event, newValue) =>
                          handleColorChange(
                            event,
                            newValue,
                            "LensColor",
                            colorIndex
                          )
                        }
                        placeholder="Select"
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            error={error.LensColor}
                            helperText={error.LensColor}
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "25px",
                            }}
                            {...params}
                            placeholder="Select"
                          />
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
                    <div>
                      <StyledTypography
                        sx={{ padding: "20px 10px 30px 0px" }}
                        color={"#2b2b2b"}
                        size={17}
                        height={1.18}
                        weight={600}
                      >
                        RIM COLOR
                      </StyledTypography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="medium"
                        value={color?.RimColor}
                        options={dropDown.RimColor}
                        onChange={(event, newValue) =>
                          handleColorChange(
                            event,
                            newValue,
                            "RimColor",
                            colorIndex
                          )
                        }
                        placeholder="Select"
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            error={error.RimColor}
                            helperText={error.RimColor}
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "25px",
                            }}
                            {...params}
                            placeholder="Select"
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
                    <div>
                      <StyledTypography
                        sx={{ padding: "20px 10px 30px 0px" }}
                        color={"#2b2b2b"}
                        size={17}
                        height={1.18}
                        weight={600}
                      >
                        TEMPLE COLOR
                      </StyledTypography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="medium"
                        value={color?.TempleColor}
                        options={dropDown.RimColor}
                        onChange={(event, newValue) =>
                          handleColorChange(
                            event,
                            newValue,
                            "TempleColor",
                            colorIndex
                          )
                        }
                        placeholder="Select"
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            error={error.TempleColor}
                            helperText={error.TempleColor}
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "25px",
                            }}
                            {...params}
                            placeholder="Select"
                          />
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
                {color.SizeArr.map((size, index) => {
                  return (
                    <Grid container>
                      <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                        <div>
                          <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={17}
                            height={1.18}
                            weight={600}
                          >
                            SIZE
                          </StyledTypography>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size="medium"
                            value={size.Size}
                            options={dropDown.Size}
                            onChange={(event, newValue) =>
                              handleSizeChange(
                                event,
                                newValue,
                                "Size",
                                index,
                                colorIndex
                              )
                            }
                            placeholder="Select"
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                error={error.Size}
                                helperText={error.Size}
                                sx={{
                                  backgroundColor: "#fff",
                                  borderRadius: "25px",
                                }}
                                {...params}
                                placeholder="Select"
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md={1.2} lg={1.2} sx={{ padding: "15px" }}>
                        <div>
                          <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={12}
                            height={1.8}
                            weight={600}
                            letter={0.1}
                          >
                            WIDTH
                          </StyledTypography>
                          <TextField
                            fullWidth
                            value={size.Width}
                            error={error.Width}
                            helperText={error.Width}
                            onChange={(event) =>
                              handleSizeChange(
                                event,
                                "",
                                "Width",
                                index,
                                colorIndex
                              )
                            }
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "25px",
                            }}
                            placeholder="0"
                          />
                        </div>
                      </Grid>
                      <Grid item md={1.2} lg={1.2} sx={{ padding: "15px" }}>
                        <div>
                          <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={12}
                            height={1.8}
                            weight={600}
                            letter={0.1}
                          >
                            BRIDGE SIZE
                          </StyledTypography>
                          <TextField
                            fullWidth
                            value={size.BridgeSize}
                            error={error.BridgeSize}
                            helperText={error.BridgeSize}
                            onChange={(event) =>
                              handleSizeChange(
                                event,
                                "",
                                "BridgeSize",
                                index,
                                colorIndex
                              )
                            }
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "25px",
                            }}
                            placeholder="0"
                          />
                        </div>
                      </Grid>
                      <Grid item md={1.2} lg={1.2} sx={{ padding: "15px" }}>
                        <div>
                          <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={12}
                            height={1.8}
                            weight={600}
                            letter={0.1}
                          >
                            TEMPLE SIZE
                          </StyledTypography>
                          <TextField
                            fullWidth
                            value={size.TempleSize}
                            error={error.TempleSize}
                            helperText={error.TempleSize}
                            onChange={(event) =>
                              handleSizeChange(
                                event,
                                "",
                                "TempleSize",
                                index,
                                colorIndex
                              )
                            }
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "25px",
                            }}
                            placeholder="0"
                          />
                        </div>
                      </Grid>
                      <Grid item md={1.2} lg={1.2} sx={{ padding: "15px" }}>
                        <div>
                          <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={12}
                            height={1.8}
                            weight={600}
                            letter={0.1}
                          >
                            HEIGHT
                          </StyledTypography>
                          <TextField
                            fullWidth
                            value={size.Height}
                            error={error.Height}
                            helperText={error.Height}
                            onChange={(event) =>
                              handleSizeChange(
                                event,
                                "",
                                "Height",
                                index,
                                colorIndex
                              )
                            }
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "25px",
                            }}
                            placeholder="0"
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        md={0.8}
                        lg={0.8}
                        sx={{ padding: "15px" }}
                      ></Grid>
                      <Grid item md={1.4} lg={1.4} sx={{ padding: "15px" }}>
                        <div>
                          <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={12}
                            height={1.8}
                            weight={600}
                            letter={0.1}
                          >
                            QTY
                          </StyledTypography>
                          <TextField
                            fullWidth
                            value={size.Quantity}
                            error={error.Quantity}
                            helperText={error.Quantity}
                            onChange={(event) =>
                              handleSizeChange(
                                event,
                                "",
                                "Quantity",
                                index,
                                colorIndex
                              )
                            }
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "25px",
                            }}
                            placeholder="0"
                          />
                        </div>
                      </Grid>
                      <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                        <div>
                          <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={12}
                            height={1.18}
                            weight={600}
                          ></StyledTypography>
                          {index !== color.SizeArr.length - 1 ? (
                            <DeleteIcon
                              onClick={(event) =>
                                handleSizeDelete(event, colorIndex, index)
                              }
                            />
                          ) : (
                            <ContinueShoppingButton
                              onClick={(event) =>
                                handleSizeAdd(event, colorIndex)
                              }
                            >
                              Add Size
                            </ContinueShoppingButton>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <StyledTypography
                sx={{ padding: "10px 10px 10px 10px" }}
                color={"#000"}
                size={16}
                height={1.13}
                weight={600}
              >
                Add Product Images
              </StyledTypography>
              <StyledTypography
                sx={{ padding: "10px 10px 10px 10px" }}
                color={"#827e7e"}
                size={12}
                height={1.13}
                weight={600}
              >
                (Supported file format JPEG, PNG)
              </StyledTypography>
              <Grid container>
                <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
                  <StyledTypography
                    sx={{ padding: "20px 10px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={12}
                    height={1.18}
                    weight={600}
                    maxFiles={1}
                  >
                    THUMBNAIL IMAGE
                  </StyledTypography>
                  <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "20px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(
                          "ThumbnailImage",
                          colorIndex,
                          acceptedFiles
                        );
                      }}
                      name="thumbNail"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop Thumbnail image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div>
                      <img
                        style={{
                          width: "220px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={color.ThumbnailImage?.preview}
                        alt="Thumbnail"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item md={8} lg={8} sx={{ padding: "15px" }}>
                  <StyledTypography
                    sx={{ padding: "20px 10px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={12}
                    height={1.18}
                    weight={600}
                  >
                    PRODUCT IMAGES
                  </StyledTypography>
                  <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "60px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(
                          "ProductImages",
                          colorIndex,
                          acceptedFiles
                        );
                      }}
                      name="product"
                      maxFiles={4}
                      multiple={true}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop Product image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "140px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={color.ProductImages[0]?.preview}
                        alt="Product"
                      />
                      <img
                        style={{
                          width: "140px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={color.ProductImages[1]?.preview}
                        alt="Product"
                      />
                      <img
                        style={{
                          width: "140px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={color.ProductImages[2]?.preview}
                        alt="Product"
                      />
                      <img
                        style={{
                          width: "140px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={color.ProductImages[3]?.preview}
                        alt="Product"
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div style={{ padding: "30px 0px 0px 0px" }}>
                {colorIndex !== colorValue.length - 1 ? (
                  <ContinueShoppingButton
                    onClick={(event) => handleColorDelete(event, colorIndex)}
                  >
                    {" "}
                    Delete Color - {color?.ColorCode}
                  </ContinueShoppingButton>
                ) : (
                  <ContinueShoppingButton onClick={handleColorAdd}>
                    Add Color
                  </ContinueShoppingButton>
                )}
              </div>
            </>
          );
        })}

        <div style={{ padding: "30px 0px 0px 0px" }}>
          <ContinueButton onClick={handleSubmitGlass}>Submit</ContinueButton>
        </div>
      </Grid>
    </>
  );
};

export default AddGlass;
