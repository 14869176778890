import React from "react";

export const StatusOutline = (props) => {
    const color = props?.color ?? "#827e7e";
    const width = props?.width ?? "24";
    const height = props?.height ?? "24";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g data-name="vuesax/outline/status-up">
        <path
          d="M.75 3.57A.755.755 0 0 1 0 2.82V.75A.755.755 0 0 1 .75 0a.755.755 0 0 1 .75.75v2.07a.749.749 0 0 1-.75.75z"
          transform="translate(6.13 15.33)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M.75 5.65A.755.755 0 0 1 0 4.9V.75A.755.755 0 0 1 .75 0a.755.755 0 0 1 .75.75V4.9a.749.749 0 0 1-.75.75z"
          transform="translate(11.25 13.25)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M.75 7.72A.755.755 0 0 1 0 6.97V.75A.755.755 0 0 1 .75 0a.755.755 0 0 1 .75.75v6.22a.743.743 0 0 1-.75.75z"
          transform="translate(16.37 11.18)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M.753 8.08a.757.757 0 0 1-.73-.57.748.748 0 0 1 .55-.91A18.123 18.123 0 0 0 9.963.8l.46-.54a.752.752 0 0 1 1.14.98l-.46.54A19.513 19.513 0 0 1 .933 8.06a.554.554 0 0 1-.18.02z"
          transform="translate(6.127 5.1)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M3.68 4.42a.755.755 0 0 1-.75-.75V1.5H.75A.755.755 0 0 1 0 .75.755.755 0 0 1 .75 0h2.93a.755.755 0 0 1 .75.75v2.93a.741.741 0 0 1-.75.74z"
          transform="translate(13.44 5.1)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M13.75 21.5h-6C2.32 21.5 0 19.18 0 13.75v-6C0 2.32 2.32 0 7.75 0h6c5.43 0 7.75 2.32 7.75 7.75v6c0 5.43-2.32 7.75-7.75 7.75zm-6-20C3.14 1.5 1.5 3.14 1.5 7.75v6C1.5 18.36 3.14 20 7.75 20h6c4.61 0 6.25-1.64 6.25-6.25v-6c0-4.61-1.64-6.25-6.25-6.25z"
          transform="translate(1.25 1.25)"
          fill={color}
        />
      </g>
    </svg>
  );
};
