import React from "react";
import {
    HeadingTypography,
    SubLabelTypography,
    ContinueButton
} from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";
import Dropzone from "react-dropzone";
import {
    Dialog,
    DialogContent,
    TextField
} from "@mui/material";
import moment from "moment";
import {
    StyledTypography
} from "../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AlertComp } from "../../components/Alert";

function IndentSummary(props) {
    const classes = useStyles();

    return (
        <Dialog PaperProps={{ classes: { root: classes.paperWidth } }} open={props?.open}>
            <DialogContent>
                <div>
                    <div
                        className={classes.alignStraightBetween}
                        style={{ marginBottom: "12px" }}
                    >
                        <HeadingTypography>Upload Invoice</HeadingTypography>
                        <div style={{ cursor: "pointer" }} onClick={props?.handleClose}>
                            <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
                        </div>
                    </div>
                    {
                        props.saveInvoiceResponse.error && (<AlertComp
                            type="error"
                            message={props.saveInvoiceResponse.error}
                            handleAlertClose={props.handleAlertClose}
                        />
                        )}

                    {
                        props.saveInvoiceResponse.message && (<AlertComp
                            type="success"
                            message={props.saveInvoiceResponse.message}
                            handleAlertClose={props.handleAlertClose}
                        />
                        )}
                    <div>
                        <div className={classes.alignStraight} style={{ marginBottom: "18px" }}>
                            <div
                                className={classes.alignStraight}
                                style={{ marginRight: "20px" }}
                            >
                                <SubLabelTypography margin={"0px 6px 0px 0px"}>
                                    INDENT ID/DATE:
                                </SubLabelTypography>
                                <HeadingTypography size={"13px"}>
                                    {props.currentIndent.indentId} / {moment(props.currentIndent.indentDate).format("ll")}
                                </HeadingTypography>
                            </div>
                            <div
                                className={classes.alignStraight}
                                style={{ marginRight: "20px" }}
                            >
                                <SubLabelTypography margin={"0px 6px 0px 0px"}>
                                    GRAND TOTAL (&#8377;):
                                </SubLabelTypography>
                                <HeadingTypography size={"13px"}>
                                    {props.currentIndent.amount}
                                </HeadingTypography>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <StyledTypography
                                sx={{ padding: "20px 10px 30px 0px" }}
                                color={"#2b2b2b"}
                                size={17}
                                height={1.18}
                                weight={600}
                            >
                                INVOICE DATE
                            </StyledTypography>
                            <DatePicker
                                selected={props.invoiceDetails.invoiceDate}
                                autoComplete="off"
                                onChange={(date) => props.handleInputChange("invoiceDate", date)}
                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Invoice Date"
                                customInput={
                                    <TextField
                                        error={props.error.invoiceDate}
                                        helperText={props.error.invoiceDate}
                                        fullWidth
                                        type="text"
                                        placeholder="Invoice Date"
                                        variant="outlined"
                                    />
                                }
                            />
                        </div>
                        <div>

                            <StyledTypography
                                sx={{ padding: "20px 10px 30px 0px" }}
                                color={"#2b2b2b"}
                                size={17}
                                height={1.18}
                                weight={600}
                            >
                                INVOICE ID
                            </StyledTypography>
                            <TextField
                                onChange={(event) => props.handleInputChange("invoiceId", event.target.value)}
                                fullWidth
                                error={props.error.invoiceId}
                                helperText={props.error.invoiceId}
                                type="text"
                                placeholder="Invoice ID"
                                variant="outlined"
                                value={props.invoiceDetails.invoiceId || ""}
                            />
                        </div>
                        <div>

                            <StyledTypography
                                sx={{ padding: "20px 10px 30px 0px" }}
                                color={"#2b2b2b"}
                                size={17}
                                height={1.18}
                                weight={600}
                            >
                                INVOICE
                            </StyledTypography>
                            <div
                                style={{
                                    borderRadius: "3px",
                                    border: "solid 1px #d8d8d8",
                                    backgroundColor: "#fafafa",
                                    padding: "20px 23px 57px 33px",
                                }}
                            >
                                <Dropzone
                                    onDrop={(acceptedFiles) => {
                                        props.handleInputChange(
                                            "invoiceImage",
                                            acceptedFiles
                                        );
                                    }}
                                    name="invoice"
                                    multiple={false}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input {...getInputProps()} />
                                            <span style={{ fontSize: ".8rem" }}>
                                                Drop Invoice image here, or click to select file
                                            </span>
                                        </div>
                                    )}
                                </Dropzone>
                                <div>
                                    <img
                                        style={{
                                            width: "220px",
                                            height: "150px",
                                            margin: "0",
                                            display: "block",
                                        }}
                                        src={props?.invoiceDetails?.invoiceImage?.preview || props?.invoiceDetails?.invoiceImage}
                                        alt="Invoice"
                                    />
                                </div>
                            </div>
                            {props.error.invoiceImage && (
                                <p style={{ color: "#d32f2f", fontSize: "0.75rem" }}>Invoice Image is Mandatory</p>
                            )}
                            <div
                                style={{ padding: "20px 23px 0px 0px" }}
                            >
                                <ContinueButton onClick={props.handleSubmit}>Submit</ContinueButton>
                            </div>
                        </div>
                    </div>
                </div>

            </DialogContent>
        </Dialog >
    );
};

export default IndentSummary;
