import { combineReducers } from 'redux'
import indentReducer from './indentSlice';
import returnsReducer from './returnsSlice';
import assigneeReducer from './assigneeSlice';
import orderReducer from './orderSlice';
import userReducer from './userSlice';
import glassReducer from './glassSlice';
import brandReducer from './brandSlice';
import onboardingReducer from './onboardingSlice';
import stockTransferReducer from './stockTransferSlice';
import lensCatalogReducer from './lensCatalogSlice';
import storeReducer from './storeSlice';
import WarehouseLoginReducer from './warehouseLoginSlice';
import { barcodeScannerReducer as barcodeScanner } from 'react-usb-barcode-scanner';

export default combineReducers({
    indentReducer,
    returnsReducer,
    assigneeReducer,
    orderReducer,
    userReducer,
    glassReducer,
    brandReducer,
    onboardingReducer,
    stockTransferReducer,
    lensCatalogReducer,
    storeReducer,
    WarehouseLoginReducer,
    barcodeScanner
});