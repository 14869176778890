import React, { useEffect, useState } from "react";
import {
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  ContinueButton,
  StyledTypography,
  BackIcon,
  ContinueShoppingButton,
} from "../../components";
import Dropzone from "react-dropzone";
import S3 from "react-aws-s3";
import { useDispatch, useSelector } from "react-redux";
import {
  getUpdateGlass,
  getUpdateGlassError,
} from "../../app/reducers/glassSlice";
import _ from "lodash";
import { AlertComp } from "../../components/Alert";
window.Buffer = window.Buffer || require("buffer").Buffer;

const EditGlass = (props) => {
  const dispatch = useDispatch();
  const dropDown = useSelector((state) => state.glassReducer.glassDropDown);
  const glassEdited = useSelector((state) => state.glassReducer.glassEdited);
  const glassEditedError = useSelector(
    (state) => state.glassReducer.glassEditedError
  );
  const brandDropDown = useSelector(
    (state) => state.glassReducer.brandDropDown
  );
  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET,
    dirName: "eyeplay",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };

  const [imageUploaded, setImageUploaded] = useState(false);
  const [thumbNailUploaded, setThumbNailUploaded] = useState(false);
  const [userValue, setUserValue] = useState({
    CostPrice: props?.row?.CostPrice,
    SellPrice: props?.row?.SellPrice,
  });

  const [enableEdit, setEnableEdit] = useState(false);
  const [thumbNail, setThumbNail] = useState(props?.row?.ThumbnailImage);
  const [product, setProduct] = useState(props?.row?.ProductImages);

  const handleBack = () => {
    props.handleBack();
  };

  const handleChange = (event, value, name) => {
    if (name === "Brand") {
      setUserValue({ ...userValue, [name]: value.name });
    } else {
      if (value) {
        setUserValue({ ...userValue, [name]: value });
      } else {
        setUserValue({ ...userValue, [name]: event.target.value });
      }
    }
  };

  const handleCancel = () => {
    setEnableEdit(false);
  };

  const ReactS3Client = new S3(config);

  const uploadToS3 = async () => {    
    const currentTime = Math.floor(Date.now() / 1000);
    return await ReactS3Client.uploadFile(
      thumbNail,
      `inventory/thumbnails/${currentTime}${11}`
    );
  };

  const uploadProductToS3 = () => {
    const promise = product.map(async (prod, index) => {      
      const currentTime = Math.floor(Date.now() / 1000);
      return await ReactS3Client.uploadFile(
        prod,
        `inventory/productImages/${currentTime}${index}`
      );
    });
    return Promise.all(promise);
  };

  const handleImageUpload = async () => {
    let obj = {};
    const data = thumbNailUploaded ? await uploadToS3() : thumbNail;
    if (thumbNailUploaded) {
      Object.assign(obj, { ThumbnailImage: data.location });
      setThumbNail(data.location);
    } else {
      Object.assign(obj, { ThumbnailImage: thumbNail });
    }
    if (data) {
      if (imageUploaded) {
        const data3 = await uploadProductToS3();
        let tempArray = [];
        data3.map((data) => tempArray.push(data.location));
        setProduct(tempArray);
        Object.assign(obj, { ProductImages: tempArray });
      } else {
        Object.assign(obj, { ProductImages: product });
      }
    }
    return obj;
  };

  const handleImageChange = (name, files) => {
    if (name === "ThumbnailImage") {
      setThumbNailUploaded(true);
      const fileWithPreview = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setThumbNail(fileWithPreview[0]);
    } else {
      setImageUploaded(true);
      const fileWithPreview = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setProduct(fileWithPreview);
    }
  };

  const editGlass = () => {
    setEnableEdit(true);
  };

  const handleEdit = async () => {
    const data = await handleImageUpload();
    const obj = {
      _id: props.row?._id,
      ...userValue,
      ...data,
    };
    dispatch(getUpdateGlass(obj));
  };

  useEffect(() => {
    if (!_.isEmpty(glassEdited)) {
      props.handleBack();
    }
  }, [glassEdited]);

  const handleAlertClose = () => {
    dispatch(getUpdateGlassError(""));
  };

  const brandValue = {
    name: props?.row?.Brand,
  };
  return (
    <>
      {glassEditedError ? (
        <AlertComp
          type="error"
          message={glassEditedError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          aligntItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            aligntItems: "center",
          }}
        >
          <div onClick={handleBack}>
            <BackIcon />
          </div>
          <StyledTypography
            sx={{ padding: "0px 0px 0px 10px" }}
            color={"#03a5a5"}
            size={21}
            height={1.23}
            weight={600}
          >
            View GLASS
          </StyledTypography>
        </div>
        <div>
          <ContinueButton onClick={editGlass}>Edit Glass</ContinueButton>
        </div>
      </div>
      <StyledTypography
        sx={{ padding: "10px 10px 10px 10px" }}
        color={"#000"}
        size={16}
        height={1.13}
        weight={600}
      >
        Basic Information
      </StyledTypography>

      <Grid container>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                CATEGORY
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={dropDown.FrameCategory}
                defaultValue={props.row?.Category}
                disabled
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                BRAND
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                placeholder="Select"
                options={brandDropDown}
                getOptionLabel={(option) => option.name}
                defaultValue={brandValue}
                disabled
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                MODEL
              </StyledTypography>
              <TextField
                disabled
                value={props.row?.ModelNo}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                SHAPE
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={dropDown.Shape}
                defaultValue={props.row?.Shape}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "Shape")
                }
                placeholder="Select"
                disabled={!enableEdit}
                size="medium"
                renderInput={(params) => (
                  <TextField
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                GENDER
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                placeholder="Select"
                defaultValue={props.row?.Gender}
                options={dropDown.Gender}
                disabled={!enableEdit}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "Gender")
                }
                size="medium"
                renderInput={(params) => (
                  <TextField
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                FRAME TYPE
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={dropDown.FrameType}
                disabled={!enableEdit}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "FrameType")
                }
                defaultValue={props.row?.FrameType}
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                FRAME MATERIAL
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={dropDown.FrameMaterial}
                disabled={!enableEdit}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "FrameMaterial")
                }
                defaultValue={props.row?.FrameMaterial}
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                TEMPLE MATERIAL
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={dropDown.FrameMaterial}
                disabled={!enableEdit}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "TempleMaterial")
                }
                defaultValue={props.row?.TempleMaterial}
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                COST PRICE (&#8377;)
              </StyledTypography>
              <TextField
                fullWidth
                value={userValue?.CostPrice}
                disabled={!enableEdit}
                onChange={(event) => handleChange(event, "", "CostPrice")}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="0.00"
              />
            </div>
          </Grid>
          <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                SELL PRICE (&#8377;)
              </StyledTypography>
              <TextField
                fullWidth
                value={userValue?.SellPrice}
                disabled={!enableEdit}
                onChange={(event) => handleChange(event, "", "SellPrice")}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="0.00"
              />
            </div>
          </Grid>
        </Grid>

        <StyledTypography
          sx={{ padding: "10px 10px 10px 10px" }}
          color={"#000"}
          size={16}
          height={1.13}
          weight={600}
        >
          ADD COLOR &#38; SIZE
        </StyledTypography>

        <Grid container>
          <Grid container>
            <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  COLOR CODE
                </StyledTypography>
                <TextField
                  fullWidth
                  value={props?.row?.ColorCode}
                  disabled
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder="Enter Color Code"
                />
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  FRAME COLOR
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="medium"
                  options={dropDown.RimColor}
                  disabled={!enableEdit}
                  onChange={(event, newValue) =>
                    handleChange(event, newValue, "FrameColor")
                  }
                  defaultValue={props.row?.FrameColor}
                  placeholder="Select"
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  LENS COLOR
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="medium"
                  options={dropDown.LensColor}
                  disabled={!enableEdit}
                  onChange={(event, newValue) =>
                    handleChange(event, newValue, "LensColor")
                  }
                  defaultValue={props.row?.LensColor}
                  placeholder="Select"
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  RIM COLOR
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="medium"
                  options={dropDown.RimColor}
                  disabled={!enableEdit}
                  onChange={(event, newValue) =>
                    handleChange(event, newValue, "RimColor")
                  }
                  defaultValue={props.row?.RimColor}
                  placeholder="Select"
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  TEMPLE COLOR
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="medium"
                  options={dropDown.RimColor}
                  disabled={!enableEdit}
                  onChange={(event, newValue) =>
                    handleChange(event, newValue, "TempleColor")
                  }
                  defaultValue={props.row?.TempleColor}
                  placeholder="Select"
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  SIZE
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="medium"
                  options={dropDown.Size}
                  disabled={!enableEdit}
                  onChange={(event, newValue) =>
                    handleChange(event, newValue, "Size")
                  }
                  defaultValue={props?.row?.Size}
                  placeholder="Select"
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={1.4} lg={1.4} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={9}
                  height={1.8}
                  weight={600}
                  letter={0.1}
                >
                  WIDTH
                </StyledTypography>
                <TextField
                  fullWidth
                  disabled
                  defaultValue={props?.row?.Width}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder=""
                />
              </div>
            </Grid>
            <Grid item md={1.4} lg={1.4} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={9}
                  height={1.8}
                  weight={600}
                  letter={0.1}
                >
                  BRIDGE SIZE
                </StyledTypography>
                <TextField
                  fullWidth
                  disabled
                  defaultValue={props?.row?.BridgeSize}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder=""
                />
              </div>
            </Grid>
            <Grid item md={1.4} lg={1.4} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={9}
                  height={1.8}
                  weight={600}
                  letter={0.1}
                >
                  TEMPLE SIZE
                </StyledTypography>
                <TextField
                  fullWidth
                  disabled
                  defaultValue={props?.row?.TempleSize}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder=""
                />
              </div>
            </Grid>
            <Grid item md={1.4} lg={1.4} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={9}
                  height={1.8}
                  weight={600}
                  letter={0.1}
                >
                  HEIGHT
                </StyledTypography>
                <TextField
                  fullWidth
                  disabled
                  defaultValue={props?.row?.Height}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder=""
                />
              </div>
            </Grid>
            <Grid item md={1.4} lg={1.4} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={9}
                  height={1.8}
                  weight={600}
                  letter={0.1}
                >
                  QTY
                </StyledTypography>
                <TextField
                  fullWidth
                  disabled
                  defaultValue={props?.row?.Quantity}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder=""
                />
              </div>
            </Grid>
          </Grid>

          <StyledTypography
            sx={{ padding: "10px 10px 10px 10px" }}
            color={"#000"}
            size={16}
            height={1.13}
            weight={600}
          >
            Add Product Images
          </StyledTypography>
          <StyledTypography
            sx={{ padding: "10px 10px 10px 10px" }}
            color={"#827e7e"}
            size={12}
            height={1.13}
            weight={600}
          >
            (Supported file format JPEG, PNG)
          </StyledTypography>
        </Grid>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <StyledTypography
              sx={{ padding: "20px 10px 30px 0px" }}
              color={"#2b2b2b"}
              size={12}
              height={1.18}
              weight={600}
              maxFiles={1}
            >
              THUMBNAIL IMAGE
            </StyledTypography>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "20px 23px 57px 33px",
              }}
            >
              <Dropzone
                disabled={!enableEdit}
                onDrop={(acceptedFiles) => {
                  handleImageChange("ThumbnailImage", acceptedFiles);
                }}
                name="thumbNail"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop Thumbnail image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div>
                <img
                  style={{
                    width: "220px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={thumbNail?.preview || thumbNail}
                  alt="Thumbnail"
                />
              </div>
            </div>
          </Grid>
          <Grid item md={8} lg={8} sx={{ padding: "15px" }}>
            <StyledTypography
              sx={{ padding: "20px 10px 30px 0px" }}
              color={"#2b2b2b"}
              size={12}
              height={1.18}
              weight={600}
            >
              PRODUCT IMAGES
            </StyledTypography>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "60px 23px 57px 33px",
              }}
            >
              <Dropzone
                disabled={!enableEdit}
                onDrop={(acceptedFiles) => {
                  handleImageChange("ProductImages", acceptedFiles);
                }}
                name="product"
                maxFiles={4}
                multiple={true}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop Product image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    width: "140px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={product[0]?.preview || product[0]}
                  alt="Product"
                />
                <img
                  style={{
                    width: "140px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={product[1]?.preview || product[1]}
                  alt="Product"
                />
                <img
                  style={{
                    width: "140px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={product[2]?.preview || product[2]}
                  alt="Product"
                />
                <img
                  style={{
                    width: "140px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={product[3]?.preview || product[3]}
                  alt="Product"
                />
              </div>
            </div>
          </Grid>
        </Grid>
        {enableEdit ? (
          <div style={{ padding: "30px 0px 0px 0px" }}>
            <ContinueShoppingButton onClick={handleCancel}>
              Cancel
            </ContinueShoppingButton>
          </div>
        ) : (
          ""
        )}
        <div style={{ padding: "30px 0px 0px 0px" }}>
          <ContinueButton disabled={!enableEdit} onClick={handleEdit}>Submit</ContinueButton>
        </div>
      </Grid>
    </>
  );
};

export default EditGlass;
