import React from "react";

export const FlashOutline = (props) => {
    const color = props?.color ?? "#827e7e";
    const width = props?.width ?? "24";
    const height = props?.height ?? "24";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        d="M6.02 21.5a1.243 1.243 0 0 1-.48-.09c-.4-.15-1.08-.64-1.08-2.19v-6.45H2.12a1.951 1.951 0 0 1-1.99-1 1.956 1.956 0 0 1 .56-2.16l7.57-8.6C9.28-.15 10.11-.07 10.51.08s1.08.64 1.08 2.19v6.45h2.34a1.951 1.951 0 0 1 1.99 1 1.956 1.956 0 0 1-.56 2.16l-7.57 8.6a2.437 2.437 0 0 1-1.77 1.02zM9.96 1.495a3.286 3.286 0 0 0-.57.52l-7.57 8.6a1.1 1.1 0 0 0-.32.56 1.319 1.319 0 0 0 .62.11h3.09a.755.755 0 0 1 .75.75v7.2a1.587 1.587 0 0 0 .13.78 3.286 3.286 0 0 0 .57-.52l7.57-8.6a1.1 1.1 0 0 0 .32-.56 1.319 1.319 0 0 0-.62-.11h-3.09a.755.755 0 0 1-.75-.75v-7.2a1.5 1.5 0 0 0-.13-.78z"
        transform="translate(3.97 1.245)"
        fill={color}
        data-name="vuesax/outline/flash"
      />
    </svg>
  );
};
