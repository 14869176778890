import { List, ListItemText, ListItemButton, Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ListTable } from "../../components";
import { makeStyles } from "@mui/styles";
import StatusModal from "../../components/Modal/StatusModal";
import {
  getReturns,
  setPagenoReturns,
  clearReturns,
} from "../../app/reducers/returnsSlice";
import StepperComponent from "../Indents/StepperComponent";
import AssigneeModal from "../../components/Modal/AssigneeModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import JobOrderModal from "../../components/Modal/JobOrderModal";

const Returns = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [statusOpen, setStatusOpen] = useState(false);
  const [assigneeOpen, setAssigneeOpen] = useState(false);
  const [returnDetails, setReturnDetails] = useState({});
  const [isRequireFitting, setIsRequireFitting] = useState(false);
  const [isQc, setIsQc] = useState(false);
  const [isShipment, setIsShipment] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [statusDetails, setStatusDetails] = useState("");
  const [openStepperChart, setOpenStepperChart] = useState(false);
  const [stepperChart, setStepperChart] = useState({});
  const [jobCardData, setJobCardData] = useState({});
  const [openJobCardOrder, setOpenJobCardOrder] = useState(false);

  const returnsList = useSelector((state) => state.returnsReducer.returnsList);
  const assigneeList = useSelector(
    (state) => state.assigneeReducer.assigneeList
  );
  const isAssigneeSelected = useSelector(
    (state) => state.assigneeReducer.assigneeSelectedReturns
  );
  const updatedStatus = useSelector(
    (state) => state.returnsReducer.updatedStatus
  );
  const shipmentStatus = useSelector(
    (state) => state.returnsReducer.shipmentStatus
  );

  const deliveryStatus = useSelector(
    (state) => state.returnsReducer.deliveryStatus
  );

  const pageNo = useSelector((state) => state.returnsReducer.pageNoReturns);
  const hasMore = useSelector((state) => state.returnsReducer.hasMoreReturns);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getReturns(0));
  }, [deliveryStatus, isAssigneeSelected, updatedStatus, shipmentStatus]);

  useEffect(() => {
    setLoading(false);
  }, [returnsList]);

  const getAssigneeRoleByStatus = (role, requireFitting) => {
    let assigneeRole;
    if (role === "Created" || role === "Shipped From Store") {
      assigneeRole = !requireFitting ? "qc" : "pickup";
    } else if (role === "Delivered To Warehouse") {
      assigneeRole = !requireFitting ? "qc" : "pickup";
    } else if (role === "Pick Up") {
      assigneeRole = !requireFitting ? "qc" : "fitter";
    } else if (role === "Fitted") {
      assigneeRole = !requireFitting ? "qc" : "qc";
    } else if (role === "Job Order QC Passed") {
      assigneeRole = !requireFitting ? "packing" : "packing";
    } else if (role === "Job Order QC Failed") {
      assigneeRole = !requireFitting ? "qc" : "qc";
    } else if (role === "Packed") {
      assigneeRole = !requireFitting ? "shipping" : "shipping";
    } else if (role === "Shipped From Warehouse") {
      assigneeRole = "shipping";
    } else if (role === "Delivered To Store") {
      assigneeRole = "shipping";
    } else if (role === "QC Passed") {
      assigneeRole = "shipping";
    } else if (role === "QC Failed") {
      assigneeRole = "shipping";
    } else if (role === "Completed") {
      assigneeRole = "shipping";
    } else if (role === "Intend Closed") {
      assigneeRole = "shipping";
    } else {
      assigneeRole = !requireFitting ? "qc" : "pickup";
    }
    return assigneeRole;
  };

  const getNextRole = (role, requireFitting) => {
    let nextRole;
    if (role === "Shipped From Store") {
      nextRole = "Delivered To Warehouse";
    } else if (role === "Delivered To Warehouse") {
      nextRole = !requireFitting ? "QC" : "Pick Up";
    } else if (role === "Pick Up") {
      nextRole = !requireFitting ? "QC" : "Fitted";
    } else if (role === "Fitted") {
      nextRole = !requireFitting ? "QC" : "QC";
    } else if (role === "Job Order QC Passed") {
      nextRole = !requireFitting ? "Packed" : "Packed";
    } else if (role === "Job Order QC Failed") {
      nextRole = !requireFitting ? "QC" : "QC";
    } else if (role === "Packed") {
      nextRole = !requireFitting
        ? "Shipped From Warehouse"
        : "Shipped From Warehouse";
    } else if (role === "Shipped From Warehouse") {
      nextRole = "Shipping";
    } else {
      nextRole = !requireFitting ? "Pending" : "Pending";
    }
    return nextRole;
  };

  let tableData = [];

  const data = returnsList?.map((returns) => {
    const frame = _.isEmpty(returns?.products.name)
      ? ["-", "", "", ""]
      : returns?.products.name.split(" ");

    let requireFitting = false;
    if (_.isEmpty(returns?.products?.lensItems)) {
      requireFitting = false;
    } else {
      requireFitting = true;
    }

    const assignee = getAssigneeRoleByStatus(
      returns?.products?.status,
      requireFitting
    );
    const returnData = {
      returnId:
        returns?.returnId + "/" + moment(returns?._created_at).format("ll"),
      storeId: returns?.storeId,
      orderId: returns?.orderId + "/" + moment(returns?.orderDate).format("ll"),
      frameInfo: frame[1].concat(frame[2]).concat(frame[3]) || " - ",
      lensType: returns?.products?.lensName || " - ",
      currentAssignee: _.isEmpty(returns?.products?.assignees)
          ? returns?.products?.status === "Shipped From Store" ||
          returns?.products?.status === "Created"
          ? "-"
          : "Assign"
        : returns?.products?.assignees[assignee].name,
      status: returns?.products?.status,
      data: returns,
      assgineeClick: _.isEmpty(returns?.products?.assignees)
        ? returns?.products?.status === "Shipped From Store" ||
          returns?.products?.status === "Created"
          ? false
          : true
        : false,
      refreshIcon:
        returns?.products?.status === "Created" ||
        returns?.products?.status === "Intend Closed" ||
        returns?.products?.status === "Delivered To Store" ||
        returns?.products?.status === "QC Passed" ||
        returns?.products?.status === "QC Failed" ||
        returns?.products?.status === "Shipped From Warehouse"
          ? ""
          : _.isEmpty(returns?.products?.assignees) && returns?.products?.status === "Delivered To Warehouse"
          ? ""
          : "refresh",
    };
    tableData.push(returnData);

    return tableData;
  });

  const handleViewClick = (e, data) => {
    setAssigneeOpen(true);
    setReturnDetails({
      orderId: data?.orderId,
      returnId: data?.data?.returnId,
      returnData: data?.returnId,
      storeId: data?.data?.storeId,
      product_id: data?.data?.products?._id,
    });
    if (_.isEmpty(data?.data?.products?.lensItems)) {
      setIsRequireFitting(false);
    } else {
      setIsRequireFitting(true);
    }
  };

  const handleStatusClick = (e, data) => {
    let requireFitting = false;

    if (_.isEmpty(data?.data?.products?.lensItems) === false) {
      requireFitting = true;
    }

    const role = getNextRole(data?.data?.products?.status, requireFitting);

    if (data?.data?.products?.status === "Shipped From Store") {
      setIsDelivery(true);
      setIsQc(false);
      setIsShipment(false);
    } else if (
      data?.data?.products?.status === "Delivered To Warehouse" &&
      !requireFitting
    ) {
      setIsQc(true);
      setIsShipment(false);
      setIsDelivery(false);
    } else {
      if (data?.status === "Fitted" && requireFitting) {
        setIsQc(true);
        setIsShipment(false);
        setIsDelivery(false);
      } else if (
        (data?.status === "Job Order Assigned" || data?.status === "Created") &&
        !requireFitting
      ) {
        setIsQc(true);
        setIsShipment(false);
        setIsDelivery(false);
      } else if (data?.status === "Job Order QC Passed") {
        setIsQc(false);
        setIsShipment(false);
        setIsDelivery(false);
      } else if (data?.status === "Job Order QC Failed") {
        setIsQc(true);
        setIsShipment(false);
        setIsDelivery(false);
      } else if (data?.status === "Packed") {
        setIsShipment(true);
        setIsQc(false);
        setIsDelivery(false);
      } else {
        setIsQc(false);
        setIsShipment(false);
        setIsDelivery(false);
      }
    }

    setStatusDetails(role);
    setStatusOpen(true);
    setReturnDetails({
      orderId: data?.orderId,
      returnId: data?.data?.returnId,
      returnData: data?.returnId,
      storeId: data?.data?.storeId,
      product_id: data?.data?.products?._id,
    });
  };
  const handleClose = () => {
    setAssigneeOpen(false);
    setStatusOpen(false);
    setOpenStepperChart(false);    
    setOpenJobCardOrder(false);
    setReturnDetails({});
  };

  const handlePrintJob = (e, data) => {
    setReturnDetails({
      orderId: data?.orderId,
      returnId: data?.data?.returnId,
      returnData: data?.returnId,
      storeId: data?.data?.storeId,
      storeName: data?.data?.storeName,
      product_id: data?.data?.products?._id,
    });
    const frame = _.isEmpty(data.data?.products.name) ? "" : data.data?.products.name.split(" ");
    const lens = data.data?.products?.lensName || " - ";
    const prescription = data.data?.products?.prescriptions || [];
    const frameSize = data.data?.products?.frameSize || {};
    setJobCardData({
      frame:
        _.isEmpty(frame) ? " - " : frame[1].concat(" ").concat(frame[2]).concat(" ").concat(frame[3]),  
      lens,
      prescription,
      frameSize,
    });
    setOpenJobCardOrder(true);
  };

  const handleChartIcon = (e, data) => {
    setStepperChart({
      status: data.data?.products?.status,
      assignee: _.isEmpty(data.data.products.assignees) ? {} : data.data.products.assignees,
    });
    setReturnDetails({
      orderId: data?.orderId,
      returnId: data?.data?.returnId,
      returnData: data?.returnId,
      storeId: data?.data?.storeId,
      product_id: data?.data?.products?._id,
    });
    setOpenStepperChart(true);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoReturns(pageNo + 1));
    dispatch(getReturns(pageNo + 1));
  };

  return (
    <div
      style={{
        padding: "90px 24px 40px 26px",
        height: "calc(100vh - 2%)",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          aligntItems: "center",
          paddingBottom: "15px",
        }}
      >
        <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
          Returns
        </div>
      </div>
      <ListTable
        dataList={tableData}
        handleViewClick={handleViewClick}
        handleStatusClick={handleStatusClick}
        handleChartIcon={handleChartIcon}
        handlePrintJob={handlePrintJob}
        isLoading={loading}
        handleLoadmore={handleLoadmore}
        hasMore={hasMore}
        Header={[
          "Return ID/Return Date",
          "Store ID",
          "Order ID/Order Date",
          "Frame Info",
          "Lens Type",
          "Current Assignee",
          "Status",
          "",
        ]}
        tableData={[
          { type: ["TEXT"], name: "returnId" },
          { type: ["TEXT"], name: "storeId" },
          { type: ["TEXT"], name: "orderId" },
          { type: ["TEXT"], name: "frameInfo" },
          { type: ["TEXT"], name: "lensType" },
          { type: ["TEXT_LINK"], name: "currentAssignee" },
          { type: ["ORDER_STATUS"], name: "status" },
          { type: ["CHART_ICON"], name: "" },
        ]}
      />
      {statusOpen ? (
        <StatusModal
          open={statusOpen}
          handleClose={handleClose}
          fromReturns={true}
          returnDetails={returnDetails}
          statusDetails={statusDetails}
          isShipment={isShipment}
          isQc={isQc}
          isDelivery={isDelivery}
        />
      ) : (
        " "
      )}
       {openJobCardOrder ? (
        <JobOrderModal
          open={openJobCardOrder}
          handleClose={handleClose}
          data={jobCardData}
          fromReturns={true}
          returnDetails={returnDetails}
        />
      ) : (
        " "
      )}
      {openStepperChart ? (
        <StepperComponent
          open={openStepperChart}
          handleClose={handleClose}
          data={stepperChart}
          fromReturns={true}
          returnDetails={returnDetails}
        />
      ) : (
        " "
      )}
      {assigneeOpen ? (
        <AssigneeModal
          open={assigneeOpen}
          handleClose={handleClose}
          fromReturns={true}
          assigneeList={assigneeList}
          requireFitting={isRequireFitting}
          returnDetails={returnDetails}
        />
      ) : (
        " "
      )}
    </div>
  );
};

export default Returns;
