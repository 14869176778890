import { Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: left;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
    }
    `
);

function ViewDetailTable(props) {
  const { heading, prescriptionData, frameData } = props;
  return (
    <div style={{ paddingBottom: "30px" }}>
      <Typography sx={{ color: "#858585", pb: "8px" }}>{heading}</Typography>
      <Root sx={{ width: 500, maxWidth: "100%" }}>
        {heading === "Prescription" ? (
            <table aria-label="custom pagination table">
              <thead>
                    {(prescriptionData?.slice(0, 1)).map((pres) => (
                      <tr>
                        {Object.keys(pres)
                          .map((val) => (
                          <th style={{ textAlign: "center", padding:  val !== "_id" ? "12px"  : "0px", width:val !== "_id" ? "auto" : "0px"}}>
                            { val !== "_id" ? val.toUpperCase() : ""}
                          </th>
                        ))}
                      </tr>
                    ))}
              </thead>
              {prescriptionData?.map((pres) => (
              <tbody>
                {Object.values(pres)
                  .map((row) => (
                    <td style={{ textAlign: "center", padding:row !== pres?._id ? "12px" : "0px",width:row !== pres?._id ? "auto" : "0px" }}>
                      { row !== pres?._id ? row : ""}
                    </td>
                  ))}
              </tbody>
              ))}
            </table>
        ) 
        : (
          <table aria-label="custom pagination table">
            <thead>
              <tr>
                {Object.keys(frameData).map((val) => (
                  <th style={{ textAlign: "center", padding: "12px" }}>
                    {val.toUpperCase()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.values(frameData).map((row) => (
                <td style={{ textAlign: "center", padding: "12px" }}>{row}</td>
              ))}
            </tbody>
          </table>
        )}
      </Root>
    </div>
  );
}

export default ViewDetailTable;
