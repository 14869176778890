import React from "react";

export const OrderIndentIcon = (props) => {
  const color = props?.color ?? "#03a5a5";
  const width = props?.width ?? "14";
  const height = props?.height ?? "14";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 31"
    >
      <g data-name="Group 1843" transform="translate(-477 -172)">
        <rect
          data-name="Rectangle 424"
          width="20"
          height="20"
          rx="3"
          transform="translate(477 172)"
          opacity="30%"
          fill={color}
        />
        <path
          d="M11.057 16.117H1.312A1.318 1.318 0 0 1 0 14.8V5.06C0 1.747 1.747 0 5.06 0h6c3.313 0 5.06 1.747 5.06 5.06v6c-.003 3.31-1.75 5.057-5.063 5.057zm-6-14.992c-2.684 0-3.935 1.252-3.935 3.935v9.74a.186.186 0 0 0 .187.187h9.745c2.684 0 3.935-1.252 3.935-3.935v-6c0-2.684-1.252-3.935-3.935-3.935z"
          transform="translate(479.937 174.937)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M8.058 1.124H.562A.566.566 0 0 1 0 .562.566.566 0 0 1 .562 0h7.5a.566.566 0 0 1 .562.562.566.566 0 0 1-.566.562z"
          transform="translate(483.685 180.559)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M5.81 1.124H.562A.566.566 0 0 1 0 .562.566.566 0 0 1 .562 0H5.81a.566.566 0 0 1 .562.562.566.566 0 0 1-.562.562z"
          transform="translate(483.685 184.307)"
          fill={color}
        />
      </g>
    </svg>
  );
};
