import React from "react";

export const ChartIcon = (props) => {
  const width = props?.width ?? "79";
  const height = props?.height ?? "79";
  const color = props?.color ?? "#a0a0a0";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.063"
      height="24.063"
      viewBox="0 0 24.063 24.063"
    >
      <g data-name="vuesax/bold/document-text">
        <path
          d="M1.123.2A.655.655 0 0 0 0 .637v3.5a2.733 2.733 0 0 0 2.757 2.676c.953.01 2.276.01 3.409.01a.632.632 0 0 0 .472-1.073C5.194 4.3 2.607 1.679 1.123.2z"
          transform="translate(14.719 2.02)"
          fill="#03a5a5"
        />
        <path
          data-name="Vector"
          d="M18.048 8.212h-2.9A4.318 4.318 0 0 1 10.839 3.9V1a1.006 1.006 0 0 0-1-1H5.585A5.291 5.291 0 0 0 0 5.585v8.883a5.291 5.291 0 0 0 5.585 5.585h7.881a5.291 5.291 0 0 0 5.585-5.585V9.214a1.006 1.006 0 0 0-1.003-1.002zm-9.024 7.58H5.013a.752.752 0 0 1 0-1.5h4.011a.752.752 0 0 1 0 1.5zm2.005-4.011H5.013a.752.752 0 1 1 0-1.5h6.016a.752.752 0 0 1 0 1.5z"
          transform="translate(2.507 2.005)"
          fill="#03a5a5"
        />
      </g>
    </svg>
  );
};
