import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
    HeadingTypography,
    ListTable,
    SubLabelTypography,
    ContinueButton
} from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";
import {
    Dialog,
    DialogContent,
} from "@mui/material";

function IndentSummary(props) {
    const classes = useStyles();

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Dialog PaperProps={{ classes: { root: classes.paperWidth } }} open={props?.open}>
            <DialogContent>
                <div>
                    <div
                        className={classes.alignStraightBetween}
                        style={{ marginBottom: "12px" }}
                    >
                        <HeadingTypography>Indent Summary</HeadingTypography>
                        <div style={{ cursor: "pointer" }} onClick={props?.handleClose}>
                            <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
                        </div>
                    </div>
                    <div ref={componentRef}>
                        <div className={classes.alignStraight} style={{ marginBottom: "18px" }}>
                            <div
                                className={classes.alignStraight}
                                style={{ marginRight: "20px" }}
                            >
                                <SubLabelTypography margin={"0px 6px 0px 0px"}>
                                    INDENT ID/DATE:
                                </SubLabelTypography>
                                <HeadingTypography size={"13px"}>
                                    {props?.orderDetails?.indentData?.indentId} / {props?.orderDetails?.indentData?.indentDate}
                                </HeadingTypography>
                            </div>
                            <div
                                className={classes.alignStraight}
                                style={{ marginRight: "20px" }}
                            >
                                <SubLabelTypography margin={"0px 6px 0px 0px"}>
                                    TOTAL AMOUNT (&#8377;):
                                </SubLabelTypography>
                                <HeadingTypography size={"13px"}>
                                    {props?.orderDetails?.indentData?.totalCostPrice}
                                </HeadingTypography>
                            </div>
                            <div
                                className={classes.alignStraight}
                                style={{ marginRight: "20px" }}
                            >
                                <SubLabelTypography margin={"0px 6px 0px 0px"}>
                                    GST (&#8377;):
                                </SubLabelTypography>
                                <HeadingTypography size={"13px"}>
                                    {props?.orderDetails?.indentData?.totalGstAmount}
                                </HeadingTypography>
                            </div>
                            <div
                                className={classes.alignStraight}
                                style={{ marginRight: "20px" }}
                            >
                                <SubLabelTypography margin={"0px 6px 0px 0px"}>
                                    Grand Total (&#8377;):
                                </SubLabelTypography>
                                <HeadingTypography size={"13px"}>
                                    {props?.orderDetails?.indentData?.amount}
                                </HeadingTypography>
                            </div>
                        </div>

                        <ListTable
                            dataList={props.orderDetails.orderData}
                            Header={[
                                "Job Order Id",
                                "Product",
                                "HSN Code",
                                "Cost",
                                "GST(%)",
                                "GST",
                                "Total Amount",
                            ]}
                            tableData={[
                                { type: ["TEXT"], name: "jobOrderId" },
                                { type: ["TEXT"], name: "name" },
                                { type: ["TEXT"], name: "hsnCode" },
                                { type: ["TEXT"], name: "costPrice" },
                                { type: ["TEXT"], name: "gstPercentage" },
                                { type: ["TEXT"], name: "gstCostPrice" },
                                { type: ["TEXT"], name: "inclGstCostPrice" },
                            ]}
                            overFlowX="unset"
                            maxHeight="none"
                        />
                    </div>
                </div>
            </DialogContent>
            <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "15px 20px 20px 0px",
                    }}
                >
                    <ContinueButton onClick={handlePrint}>Print</ContinueButton>
                </div>
        </Dialog>
    );
};

export default IndentSummary;
