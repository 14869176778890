import React, { useEffect, useState } from "react";
import {
  Grid,
  Autocomplete,
  TextField,
  InputAdornment,
  Chip,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ContinueButton,
  StyledTypography,
  BackIcon,
  ContinueShoppingButton,
} from "../../components";
import SuccessModal from "../../components/Modal/SuccessModal";
import { getGlassDropDowns } from "../../app/reducers/glassSlice";
import {
  getSaveLens,
  editLensErrors,
  editLens,
} from "../../app/reducers/lensCatalogSlice";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { WithContext as ReactTags } from "react-tag-input";
import S3 from "react-aws-s3";
import "./ReactInputTags.css";
import _ from "lodash";
import { AlertComp } from "../../components/Alert";
window.Buffer = window.Buffer || require("buffer").Buffer;

const EditCatalog = (props) => {
  const [success, setSuccess] = useState(false);

  const lensEdited = useSelector(
    (state) => state.lensCatalogReducer.lensEdited
  );
  const lensEditedError = useSelector(
    (state) => state.lensCatalogReducer.lensEditedError
  );

  const dispatch = useDispatch();

  const [enableEdit, setEnableEdit] = useState(false);

  const [thumbNail, setThumbNail] = useState(props?.row?.thumbnailImage);
  const [thumbNailUploaded, setThumbNailUploaded] = useState(false);

  useEffect(() => {
    dispatch(getGlassDropDowns());
  }, []);

  let defaultTags = [];

  props?.row?.features.map((data) => {
    defaultTags.push({
      id: data,
      text: data,
    });
  });

  const [tags, setTags] = useState(defaultTags);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  const [userValue, setUserValue] = useState({});

  const lensType = ["SINGLE VISION", "BIFOCAL", "PROGRESSIVE"];
  const model = ["Standard", "Advanced", "Premium"];
  const dropDown = useSelector((state) => state.glassReducer.glassDropDown);



  const handleBack = () => {
    props.handleBack();
  };

  const handleChange = (event, value, name) => {
    if (value) {
      setUserValue({ ...userValue, [name]: value });
    } else {
      setUserValue({ ...userValue, [name]: event.target.value });
    }
  };

  const handleImageChange = (acceptedFiles) => {
    setThumbNailUploaded(true);
    const fileWithPreview = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setThumbNail(fileWithPreview[0]);
  };

  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET,
    dirName: "eyeplay",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };

  const ReactS3Client = new S3(config);
  const random = Math.random();

  const uploadToS3 = async () => {
    return await ReactS3Client.uploadFile(
      thumbNail,
      `Master/lensCatalog/${userValue?.lensType}${userValue?.lensCoating}${random}`
    );
  };

  const imageUpload = async () => {

    let obj = {};
    const data = thumbNailUploaded ? await uploadToS3() : thumbNail;
    if (thumbNailUploaded && data) {
      Object.assign(obj, { thumbnailImage: data.location });
    } else {
      Object.assign(obj, { thumbnailImage: thumbNail });
    }
    return obj;
  };

  const handleUserSubmit = async () => {
    const data = await imageUpload();
    let tagArr = [];
    tags.map((data) => {
      tagArr.push(data?.id);
    });

    const obj = {
      _id: props.row?._id,
      ...userValue,
      ...data,
      features: tagArr,
    };
    
    dispatch(editLens(obj));
  };

  useEffect(() => {
    if (!_.isEmpty(lensEdited)) {
      setSuccess(true);
    }
  }, [lensEdited]);

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleSuccessClose = () => {
    props?.handleBack();
  };

  const handleAlertClose = () => {
    dispatch(editLensErrors(""));
  };

  return (
    <>
      {lensEditedError ? (
        <AlertComp
          type="error"
          message={lensEditedError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          aligntItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            aligntItems: "center",
          }}
        >
          <div onClick={handleBack}>
            <BackIcon />
          </div>
          <StyledTypography
            sx={{ padding: "0px 0px 0px 10px" }}
            color={"#03a5a5"}
            size={22}
            height={1.23}
            weight={600}
          >
            EDIT CATALOG
          </StyledTypography>
        </div>
        <div>
          <ContinueButton onClick={() => setEnableEdit(true)}>
            Edit Catalog
          </ContinueButton>
        </div>
      </div>
      <Grid container>
        <Grid container>
          <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                LENS TYPE
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={lensType}
                placeholder="Select"
                defaultValue={props.row?.lensType}
                disabled={!enableEdit}
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "lensType");
                }}
                size="medium"
                renderInput={(params) => (
                  <TextField
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                BRAND
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                defaultValue={props.row?.brand}
                disabled={!enableEdit}
                options={["REGULAR"]}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "brand")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                COATING
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                defaultValue={props.row?.lensCoating}
                disabled={!enableEdit}
                options={dropDown?.LensCoating}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "lensCoating")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          { !_.isEmpty(props.row?.lensType) && props.row?.lensType === "PROGRESSIVE" ? (
            <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  LENS CATEGORY
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="medium"
                  options={model}
                  defaultValue={props.row?.lensCategory}
                  disabled={!enableEdit}
                  onChange={(event, newValue) =>
                    handleChange(event, newValue, "lensCategory")
                  }
                  placeholder="Select"
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid container>
          <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                INDEX
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={["1.56", "1.6", "1.67", "1.74"]}
                placeholder="Select"
                defaultValue={props.row?.index}
                disabled={!enableEdit}
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "index");
                }}
                size="medium"
                renderInput={(params) => (
                  <TextField
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                COST PRICE(&#8377;)
              </StyledTypography>
              <TextField
                onChange={(event) => {
                  handleChange(event, "", "costPrice");
                }}
                fullWidth
                defaultValue={props.row?.costPrice}
                disabled={!enableEdit}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="0.00"
              />
            </div>
          </Grid>
          <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                SELL PRICE(&#8377;)
              </StyledTypography>
              <TextField
                onChange={(event) => {
                  handleChange(event, "", "sellPrice");
                }}
                fullWidth
                defaultValue={props.row?.sellPrice}
                disabled={!enableEdit}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="0.00"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} lg={5} sx={{ padding: "15px" }}>
            <StyledTypography
              sx={{ padding: "20px 10px 30px 0px" }}
              color={"#2b2b2b"}
              size={12}
              height={1.18}
              weight={600}
              maxFiles={1}
            >
              THUMBNAIL IMAGE
            </StyledTypography>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "20px 23px 57px 33px",
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleImageChange(acceptedFiles);
                }}
                name="thumbNail"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop Thumbnail image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div>
                <img
                  style={{
                    width: "170px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={thumbNail?.preview || thumbNail}
                  alt="Thumbnail"
                />
              </div>
            </div>
          </Grid>
          <Grid item md={5} lg={5} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                FEATURES
              </StyledTypography>
              <div>
                <ReactTags
                  classNames={{
                    tags: "tagsClass",
                    tagInput: "tagInputClasses",
                    tagInputField: "tagInputFieldClass",
                    selected: "selectedClass",
                    tag: "tagClass",
                    remove: "removeClass",
                    suggestions: "suggestionsClass",
                    activeSuggestion: "activeSuggestionClass",
                    editTagInput: "editTagInputClass",
                    editTagInputField: "editTagInputField",
                  }}
                  readOnly={!enableEdit}
                  tags={tags}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  inputFieldPosition="top"
                  inline={true}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        {enableEdit ? (
          <div style={{ padding: "30px 0px 0px 0px" }}>
            <ContinueShoppingButton onClick={() => setEnableEdit(false)}>
              Cancel
            </ContinueShoppingButton>
          </div>
        ) : (
          ""
        )}
        <div style={{ padding: "30px 0px 0px 0px" }}>
          <ContinueButton disabled={!enableEdit} onClick={handleUserSubmit}>
            Submit
          </ContinueButton>
        </div>
      </Grid>
      <SuccessModal
        success={success}
        successHeader={"Lens added successfully"}
        button={"Go to Lens Catalog"}
        handleSuccessClose={handleSuccessClose}
      ></SuccessModal>
    </>
  );
};

export default EditCatalog;
