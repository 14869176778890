import { Close } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import {
  ContinueButton,
  StyledTypography,
  SubLabelTypography,
} from "../../../components";
import { useStyles } from "./styles";
import Codes from "../../../assets/codes.webp";
import { useDispatch, useSelector } from "react-redux";
import { getBarcodes, getUpdateBarcodeStatus } from "../../../app/reducers/onboardingSlice";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";
import "./printStyle.css";

function BarCodeModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const barcode = useSelector((state) => state.onboardingReducer.barcodeList);
  const componentRef = useRef();

  useEffect(() => {
    dispatch(getBarcodes(props?.row?._id));
  }, [dispatch, props?.row?._id]);

 

  const handlePrintBarCode = () => {
    let tempArray = [];
    barcode?.map((data) => {
      tempArray.push(data?.barcodeId);
    });

    const obj = {
      "barcodeIds": tempArray,
      "status": "Processed"
    };
    dispatch(getUpdateBarcodeStatus(obj));
    handlePrint();
    props?.handleCloseBarCode();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div style={{ padding: "24px" }}>
      <div
        className={classes.alignItems}
        style={{ justifyContent: "space-between" }}
      >
        <StyledTypography color="#242424" size={19} weight={600} letter={"0px"} >
          Barcodes
        </StyledTypography>
        <div style={{ cursor: "pointer" }} onClick={props?.handleCloseBarCode}>
          <Close sx={{ color: "#827e7e", fontSize: "14px" }} />
        </div>
      </div>
      <div className={classes.alignItems} style={{ padding: "8px 0px" }}>
        <SubLabelTypography sx={{ marginRight: "6px" }}>
          {"PRODUCT NAME: "}
        </SubLabelTypography>
        <StyledTypography color="#242424" weight="600" letter="0px">
          {props?.row?.ProductName}
        </StyledTypography>
      </div>
      <div>
        <StyledTypography color="#242424" letter="0px">
          Select and Print Barcodes
        </StyledTypography>
      </div> 
      <Grid container sx={{ mb: "25px" }}>
        <div ref={componentRef}>
          {barcode?.map((code) => {
            return (
              <div className="printC"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Barcode
                  value={code?.barcodeId}
                  width={0.8}
                  height={18}
                  fontSize={10}
                  displayValue={true}
                  textAlign={"center"}
                />
                <div style={{ display: "inline-block" }}>
                  <div style={{ fontSize: "10px" }}> {code.Brand} </div>
                  <div style={{ fontSize: "10px" }}> {code.ModelNo} {code.ColorCode}</div>
                  <div style={{ fontSize: "10px" }}>Price (&#8377;):  {code.SellPrice}</div>
                </div>
              </div>
            );
          })}
        </div>
      </Grid>

      {/* <ContinueButton
        onClick={props?.handleCloseBarCode}
        marginLeft={"0px"}
        sx={{ margin: "10px" }}
      >
        Print Selected
      </ContinueButton> */}
      <ContinueButton onClick={handlePrintBarCode} marginLeft={"0px"}>
        Print All
      </ContinueButton>
    </div>
  );
}

export default BarCodeModal;
