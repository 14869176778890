import React, { useState } from "react";
import {
  Dialog,
  IconButton,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ContinueButton, StyledTypography } from "..";
import { useDispatch } from "react-redux";
import {
  getUpdateStatus,
  getShipmentSave,
} from "../../app/reducers/orderSlice";
import { getUpdateStatusReturns, getShipmentSaveReturns, getDelivery } from "../../app/reducers/returnsSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const StatusModal = (props) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [qcData, setQcData] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [shippingData, setShippingData] = useState({});


  const statusOptions = props.fromReturns ? ["Job Order QC Passed", "Job Order QC Failed"] : ["QC Passed", "QC Failed"];
  const handleDateChange = (newValue, name) => {
    const date = new Date(newValue).toISOString();
    setDateValue(newValue);
    setShippingData({ ...shippingData, [name]: date });
  };

  const handleShippingChange = (event, name) => {
    setShippingData({ ...shippingData, [name]: event.target.value });
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = async () => {
    const obj = {
      jobOrder_id: props?.orderDetails?.jobOrder_id,
      status: props?.isQc ? qcData : props?.statusDetails,
      comment: comment,
    };

    const shipmentObj = {
      jobOrder_id: props?.orderDetails?.jobOrder_id,
      shippingDetails: shippingData,
    };

    const returnObj = {
      returnId: props?.returnDetails?.returnId,
      product_id: props?.returnDetails?.product_id,
      status: props?.isQc ? qcData : props?.statusDetails,
      comment: comment,
    };
      
    const shipmentObjReturns = {
      returnId: props?.returnDetails?.returnId,
      product_id: props?.returnDetails?.product_id,
      status: props?.statusDetails,
      comment: comment,
      shippingDetails : shippingData
    };

    const date = new Date(Date.now());
    const returnDeliveryObj = {
      returnId: props?.returnDetails?.returnId,
      product_id: props?.returnDetails?.product_id,
      status: props?.statusDetails,
      comment: comment,
      deliveryDetails : {
        "deliveryDate": date
      }
    };
    
    if (props.fromReturns && !props.isDelivery) {
      if (props.isShipment) {
        dispatch(getShipmentSaveReturns(shipmentObjReturns));
      } else {
        dispatch(getUpdateStatusReturns(returnObj));
      }
    }
    else if(props.isDelivery){
       dispatch(getDelivery(returnDeliveryObj));
    }
     else {
      if (props.isShipment) {
        dispatch(getShipmentSave(shipmentObj));
      } else {
        dispatch(getUpdateStatus(obj));
      }
    }
    props.handleClose();
  };

  const handleChange = (newValue) => {
    setQcData(newValue);
  };
  return (
    <Dialog maxWidth={false} open={props.open} onClose={props.handleClose}>
      <div
        style={{
          width: "936px",
          height: "582px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
            padding: "0px 10px",
          }}
        >
          <StyledTypography
            sx={{ margin: "20px 60px 30px 11px" }}
            color={"#2b2b2b"}
            size={18}
            height={1.28}
            weight={700}
          >
            Update Status
          </StyledTypography>
          <div>
            <IconButton
              aria-label="close"
              onClick={props.handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#242424",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {props?.fromReturns ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "0px 20px",
                }}
              >
                <StyledTypography>RETURN ID/DATE:</StyledTypography>
                <StyledTypography color={"#2b2b2b"}>
                  {props?.returnDetails?.returnData}
                </StyledTypography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <StyledTypography>STORE ID: </StyledTypography>{" "}
                <StyledTypography color={"#2b2b2b"}>
                  {props?.returnDetails?.storeId}
                </StyledTypography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <StyledTypography>ORDER ID/DATE:</StyledTypography>{" "}
                <StyledTypography color={"#2b2b2b"}>
                  {" "}
                  {props?.returnDetails?.orderId}
                </StyledTypography>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "0px 20px",
                }}
              >
                <StyledTypography>ORDER ID/DATE:</StyledTypography>
                <StyledTypography color={"#2b2b2b"}>
                  {props?.orderDetails?.orderId}
                </StyledTypography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <StyledTypography>JOB ORDER ID: </StyledTypography>{" "}
                <StyledTypography color={"#2b2b2b"}>
                  {props?.orderDetails?.jobOrderId}
                </StyledTypography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <StyledTypography>STORE ID: </StyledTypography>{" "}
                <StyledTypography color={"#2b2b2b"}>
                  {" "}
                  {props?.orderDetails?.storeId}
                </StyledTypography>
              </div>{" "}
            </>
          )}
        </div>
        {props.isShipment ? (
          <Grid container>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  STATUS
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={statusOptions}
                  disabled={!props.isQc}
                  placeholder="Select"
                  sx={{ width: "50%" }}
                  defaultValue={props.statusDetails}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  SHIPPING DATE
                </StyledTypography>
                <DatePicker
                  selected={dateValue}
                  autoComplete="off"
                  onChange={(date) => handleDateChange(date, "shippingDate")}
                  dateFormat="dd/MM/yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="ShippingDate"
                  customInput={
                    <TextField
                      fullWidth
                      type="text"
                      placeholder="ShippingDate"
                      variant="outlined"
                    />
                  }
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  SERVICE PROVIDER
                </StyledTypography>
                <TextField
                  onChange={(event) =>
                    handleShippingChange(event, "serviceProvider")
                  }
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder="type here ..."
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  TRACKING ID
                </StyledTypography>
                <TextField
                  onChange={(event) =>
                    handleShippingChange(event, "trackingId")
                  }
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder="type here ..."
                />
              </div>
            </Grid>
            <Grid container>
              <Grid item md={6} lg={6}>
                <div style={{ margin: "20px 8px 0px 20px" }}>
                  <StyledTypography
                    sx={{ padding: "0px 0px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={17}
                    height={1.18}
                    weight={600}
                  >
                    COMMENTS
                  </StyledTypography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    onChange={(event) =>
                      handleShippingChange(event, "comments")
                    }
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "25px",
                    }}
                    placeholder="type here ..."
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column">
            <Grid container>
              <Grid item md={6} lg={6}>
                <div style={{ margin: "20px 8px 0px 20px" }}>
                  <StyledTypography
                    sx={{ padding: "0px 0px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={17}
                    height={1.18}
                    weight={600}
                  >
                    STATUS
                  </StyledTypography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={statusOptions}
                    disabled={!props.isQc}
                    placeholder="Select"
                    sx={{ width: "50%" }}
                    defaultValue={props.isQc ? "" : props.statusDetails}
                    onChange={(event, newValue) => {
                      handleChange(newValue);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                        {...params}
                        placeholder="Select"
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6} lg={6}>
                <div style={{ margin: "20px 8px 0px 20px" }}>
                  <StyledTypography
                    sx={{ padding: "0px 0px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={17}
                    height={1.18}
                    weight={600}
                  >
                    COMMENTS
                  </StyledTypography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "25px",
                    }}
                    placeholder="type here ..."
                    onChange={handleCommentChange}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
        <div style={{ padding: "20px 30px" }}>
          <ContinueButton onClick={handleSubmit}>Submit</ContinueButton>
        </div>
      </div>
    </Dialog>
  );
};

export default StatusModal;
