import React from "react";
// import { Sidebar, NavBar } from "../../components";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  content: {
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflow: "auto",
  },
})


const withNavBars = (Component) => (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div></div>
  );
};

export default withNavBars;
