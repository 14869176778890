import React, { useState, useEffect } from 'react';
import {
    StyledTypography,
    BackIcon,
    ContinueShoppingButton,
    ContinueButton
} from "../../components";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Grid,
    Autocomplete,
    TextField
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import lensPower from "../../utils/lensPower";
import { getBrandDropDowns } from "../../app/reducers/glassSlice";
import _ from "lodash";
import { WithContext as ReactTags } from "react-tag-input";
import "./ReactInputTags.css";
import { AlertComp } from "../../components/Alert";
import {
    getSaveLens,
    saveLensErrors,
} from "../../app/reducers/lensCatalogSlice";
import SuccessModal from "../../components/Modal/SuccessModal";

export default function NewRangeCatalog(props) {
    const dispatch = useDispatch();
    const rangeStruct = {
        startSph: "",
        endSph: "",
        startCyl: "",
        endCyl: "",
        starAdd: "",
        endAdd: "",
        axis: "",
        costPrice: ""
    };
    const KeyCodes = {
        comma: 188,
        enter: 13,
    };

    const [success, setSuccess] = useState(false);
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const lensCategory = ["Standard", "Advanced", "Premium"];
    const lensPowerData = lensPower() || [];
    const [tags, setTags] = useState([]);
    const [formValue, setFormValue] = useState({
        lensType: "",
        brand: "",
        lensCoating: "",
        index: "",
        sellPrice: "",
        lensCategory: "",
        ranges: [{
            startSph: "",
            endSph: "",
            startCyl: "",
            endCyl: "",
            startAdd: "",
            endAdd: "",
            axis: "",
            costPrice: ""
        }]
    });
    const [error, setError] = useState({});
    const lensAdded = useSelector((state) => state.lensCatalogReducer.lensAdded);
    const lensAddedError = useSelector(
        (state) => state.lensCatalogReducer.lensAddedError
    );

    let brandDropDown = useSelector(
        (state) => state.glassReducer.brandDropDown
    ) || [];

 
    brandDropDown = brandDropDown.filter(brand => {
        return brand.category.includes("LENS")
    }).map(brand => {
        return brand.name
    });

    useEffect(() => {
        dispatch(getBrandDropDowns());
    }, []);

    useEffect(() => {
  
    }, [formValue]);

    const dropDown = useSelector((state) => state.glassReducer.glassDropDown) || {};
    const handleChange = (name, value, index) => {
        if (index || (index === 0)) {
            let localRanges = [...formValue.ranges];
            localRanges[index][name] = value
            setFormValue({ ...formValue, ranges: localRanges });
        } else {
            setFormValue({ ...formValue, [name]: value });
        }
    };

    const addNewRange = () => {
        let localRanges = _.cloneDeep(formValue.ranges);
        localRanges.push({ ...rangeStruct })
        setFormValue({ ...formValue, ranges: localRanges });
    }

    useEffect(() => {
 
    }, [formValue]);

    const removeRange = (index) => {
        let localRanges = _.cloneDeep(formValue.ranges);
        localRanges = localRanges.filter((row, i) => i !== index)

        setFormValue({ ...formValue, ranges: [...localRanges] });
    }

    const validation = (data, index) => {
        let isValid = false;
        if (_.isEmpty(data?.lensType)) {
            isValid = false;
            setError({ lensType: "lensType is Mandatory" });
        } else if (data?.lensType === "PROGRESSIVE" && _.isEmpty(data?.lensCategory)) {
            isValid = false;
            setError({ lensType: "lensCategory is Mandatory" });
        } else if (_.isEmpty(data?.brand)) {
            isValid = false;
            setError({ brand: "brand is Mandatory" });
        } else if (_.isEmpty(data?.lensCoating)) {
            isValid = false;
            setError({ lensCoating: "lensCoating is Mandatory" });
        } else if (_.isEmpty(data?.index)) {
            isValid = false;
            setError({ index: "index is Mandatory" });
        } else if (_.isEmpty(data?.sellPrice)) {
            isValid = false;
            setError({ sellPrice: "sellPrice is Mandatory" });
        } else if (_.isEmpty(data?.costPrice)) {
            isValid = false;
            setError({
                costPrice: "costPrice is Mandatory",
                costPriceIndex: index
            });
        } else if (!new RegExp('^\\d+$').test(data?.sellPrice)) {
            isValid = false;
            setError({ sellPrice: "sellPrice must be a Number" });
        } else if (!new RegExp('^\\d+$').test(data?.costPrice)) {
            isValid = false;
            setError({
                costPrice: "costPrice must be a Number",
                costPriceIndex: index
            });
        } else {
            setError({});
            isValid = true;
        }
        return isValid;
    }

    const handleSubmit = () => {
        let rangesLocal = _.cloneDeep(formValue.ranges);
        const collabrated = rangesLocal.map(range => {
            let temp = {
                lensType: formValue.lensType,
                brand: formValue.brand,
                lensCoating: formValue.lensCoating,
                index: formValue.index,
                sellPrice: formValue.sellPrice,
                startSph: range.startSph,
                endSph: range.endSph,
                startCyl: range.startCyl,
                endCyl: range.endCyl,
                axis: range.axis,
                costPrice: range.costPrice
            };
            temp.features = tags.map((data) => {
                return data.id
            });

            if (formValue.lensType === "PROGRESSIVE") {
                temp.lensCategory = formValue.lensCategory;
                temp.startAdd = range.startAdd;
                temp.endAdd = range.endAdd;
            }
            return temp;
        })
        let isValid = true;

        for (let i = 0; i < collabrated.length; i++) {
            isValid = validation(collabrated[i], i);
            if (!isValid) {
                break;
            }
        }

        if (isValid) {
            dispatch(getSaveLens({
                lenses: collabrated
            }));
        }
    }
    const handleAlertClose = () => {
        dispatch(saveLensErrors(""));
    };
    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setTags(newTags);
    };

    useEffect(() => {
        if (!_.isEmpty(lensAdded)) {
            setSuccess(true);
        }
    }, [lensAdded]);

    const handleSuccessClose = () => {
        props?.handleBack();
    };

    return (
        <>
            {lensAddedError ? (
                <AlertComp
                    type="error"
                    message={lensAddedError}
                    handleAlertClose={handleAlertClose}
                />
            ) : (
                ""
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    aligntItems: "center",
                }}
            >
                <div onClick={props.handleBack}>
                    <BackIcon />
                </div>
                <StyledTypography
                    sx={{ padding: "0px 0px 0px 10px" }}
                    color={"#03a5a5"}
                    size={22}
                    height={1.23}
                    weight={600}
                >
                    NEW RANGE LENS CATALOG
                </StyledTypography>
            </div>
            <Grid container>
                <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                    <div>
                        <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={17}
                            height={1.18}
                            weight={600}
                        >
                            LENS TYPE *
                        </StyledTypography>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={dropDown.LensType}
                            value={formValue?.lensType}
                            placeholder="Select"
                            onChange={(event, newValue) => {
                                handleChange("lensType", newValue);
                            }}
                            size="medium"
                            renderInput={(params) => (
                                <TextField
                                    error={error.lensType}
                                    helperText={error.lensType}
                                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                    {...params}
                                    placeholder="Select"
                                />
                            )}
                        />
                    </div>
                </Grid>
                <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                    <div>
                        <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={17}
                            height={1.18}
                            weight={600}
                        >
                            BRAND *
                        </StyledTypography>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={brandDropDown}
                            value={formValue?.brand}
                            placeholder="Select"
                            onChange={(event, newValue) => {
                                handleChange("brand", newValue);
                            }}
                            size="medium"
                            renderInput={(params) => (
                                <TextField
                                    error={error.brand}
                                    helperText={error.brand}
                                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                    {...params}
                                    placeholder="Select"
                                />
                            )}
                        />
                    </div>
                </Grid>
                <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                    <div>
                        <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={17}
                            height={1.18}
                            weight={600}
                        >
                            LENS COATING *
                        </StyledTypography>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={dropDown.LensCoating}
                            placeholder="Select"
                            value={formValue?.lensCoating}
                            onChange={(event, newValue) => {
                                handleChange("lensCoating", newValue);
                            }}
                            size="medium"
                            renderInput={(params) => (
                                <TextField
                                    error={error.lensCoating}
                                    helperText={error.lensCoating}
                                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                    {...params}
                                    placeholder="Select"
                                />
                            )}
                        />
                    </div>
                </Grid>
                {
                    formValue.lensType === "PROGRESSIVE" && (
                        <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                            <div>
                                <StyledTypography
                                    sx={{ padding: "20px 10px 30px 0px" }}
                                    color={"#2b2b2b"}
                                    size={17}
                                    height={1.18}
                                    weight={600}
                                >
                                    LENS CATEGORY *
                                </StyledTypography>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={lensCategory}
                                    placeholder="Select"
                                    value={formValue?.lensCategory}
                                    onChange={(event, newValue) => {
                                        handleChange("lensCategory", newValue);
                                    }}
                                    size="medium"
                                    renderInput={(params) => (
                                        <TextField
                                            error={error.lensCategory}
                                            helperText={error.lensCategory}
                                            sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                            {...params}
                                            placeholder="Select"
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                    )
                }

            </Grid>
            <Grid container>
                <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                    <div>
                        <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={17}
                            height={1.18}
                            weight={600}
                        >
                            INDEX *
                        </StyledTypography>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={dropDown.Index}
                            placeholder="Select"
                            onChange={(event, newValue) => {
                                handleChange("index", newValue);
                            }}
                            value={formValue?.index}
                            size="medium"
                            renderInput={(params) => (
                                <TextField
                                    error={error.index}
                                    helperText={error.index}
                                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                    {...params}
                                    placeholder="Select"
                                />
                            )}
                        />
                    </div>
                </Grid>
                <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                    <div>
                        <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={17}
                            height={1.18}
                            weight={600}
                        >
                            SELL PRICE(&#8377;) *
                        </StyledTypography>
                        <TextField
                            onChange={(event) => {
                                handleChange("sellPrice", event.target.value);
                            }}
                            value={formValue?.sellPrice}
                            fullWidth
                            error={error.sellPrice}
                            helperText={error.sellPrice}
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: "25px",
                            }}
                            placeholder="0.00"
                        />
                    </div>
                </Grid>
                <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                    <div>
                        <StyledTypography
                            sx={{ padding: "20px 10px 30px 0px" }}
                            color={"#2b2b2b"}
                            size={17}
                            height={1.18}
                            weight={600}
                        >
                            FEATURES
                        </StyledTypography>
                        <div>
                            <ReactTags
                                classNames={{
                                    tags: "tagsClass",
                                    tagInput: "tagInputClasses",
                                    tagInputField: "tagInputFieldClass",
                                    selected: "selectedClass",
                                    tag: "tagClass",
                                    remove: "removeClass",
                                    suggestions: "suggestionsClass",
                                    activeSuggestion: "activeSuggestionClass",
                                    editTagInput: "editTagInputClass",
                                    editTagInputField: "editTagInputField",
                                }}
                                tags={tags}
                                delimiters={delimiters}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                handleDrag={handleDrag}
                                inputFieldPosition="top"
                                inline={true}
                            />
                            <div style={{ color: "#FF0000" }}>{error?.tags}</div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={1} lg={1} sx={{ padding: "15px" }}>
                </Grid>
                <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                    <div>
                        <StyledTypography
                            style={{ textAlign: "center" }}
                            sx={{ padding: "20px 10px 0px 0px" }}
                            color={"#2b2b2b"}
                            size={12}
                            height={1.18}
                        >
                            SPH
                        </StyledTypography>
                    </div>
                </Grid>
                <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                    <div>
                        <StyledTypography
                            style={{ textAlign: "center" }}
                            sx={{ padding: "20px 10px 0px 0px" }}
                            color={"#2b2b2b"}
                            size={12}
                            height={1.18}
                        >
                            CYL
                        </StyledTypography>
                    </div>
                </Grid>
                {
                    formValue.lensType === "PROGRESSIVE" && (
                        <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                            <div>
                                <StyledTypography
                                    style={{ textAlign: "center" }}
                                    sx={{ padding: "20px 10px 0px 0px" }}
                                    color={"#2b2b2b"}
                                    size={12}
                                    height={1.18}
                                >
                                    ADD
                                </StyledTypography>
                            </div>
                        </Grid>
                    )
                }
                <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                    <div>
                        <StyledTypography
                            style={{ textAlign: "center" }}
                            sx={{ padding: "20px 10px 0px 0px" }}
                            color={"#2b2b2b"}
                            size={12}
                            height={1.18}
                        >
                            AXIS
                        </StyledTypography>
                    </div>
                </Grid>
                <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                    <div>
                        <StyledTypography
                            style={{ textAlign: "center" }}
                            sx={{ padding: "20px 10px 0px 0px" }}
                            color={"#2b2b2b"}
                            size={12}
                            height={1.18}
                        >
                            COST PRICE(&#8377;) *
                        </StyledTypography>
                    </div>
                </Grid>
            </Grid>
            {
                formValue.ranges.map((range, index) => (
                    <>
                        <Grid container>
                            <Grid item md={1} lg={1} sx={{ padding: "15px", paddingLeft: "0px" }}>
                                <div>
                                    <StyledTypography
                                        sx={{ padding: "20px 10px 30px 0px" }}
                                        color={"#2b2b2b"}
                                        size={17}
                                        height={1.18}
                                        weight={600}
                                    >
                                        START
                                    </StyledTypography>
                                </div>
                            </Grid>
                            <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                                <div>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={lensPowerData.spherical || []}
                                        placeholder="Select"
                                        onChange={(event, newValue) => {
                                            handleChange("startSph", newValue, index);
                                        }}
                                        value={range?.startSph}
                                        size="medium"
                                        renderInput={(params) => (
                                            <TextField
                                                error={error.startSph}
                                                helperText={error.startSph}
                                                sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                                {...params}
                                                placeholder="Select"
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                                <div>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={lensPowerData.cylindrical}
                                        placeholder="Select"
                                        onChange={(event, newValue) => {
                                            handleChange("startCyl", newValue, index);
                                        }}
                                        value={range?.startCyl}
                                        size="medium"
                                        renderInput={(params) => (
                                            <TextField
                                                error={error.startCyl}
                                                helperText={error.startCyl || []}
                                                sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                                {...params}
                                                placeholder="Select"
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>

                            {
                                formValue.lensType === "PROGRESSIVE" && (
                                    <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                                        <div>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={lensPowerData.add || []}
                                                placeholder="Select"
                                                onChange={(event, newValue) => {
                                                    handleChange("startAdd", newValue, index);
                                                }}
                                                value={range?.startAdd}
                                                size="medium"
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={error.startAdd}
                                                        helperText={error.startAdd}
                                                        sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                                        {...params}
                                                        placeholder="Select"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Grid>
                                )}
                            <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                                <div>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={lensPowerData.axis || []}
                                        placeholder="Select"
                                        onChange={(event, newValue) => {
                                            handleChange("axis", newValue, index);
                                        }}
                                        value={range?.axis}
                                        size="medium"
                                        renderInput={(params) => (
                                            <TextField
                                                error={error.axis}
                                                helperText={error.axis}
                                                sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                                {...params}
                                                placeholder="Select"
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                                <div>
                                    <TextField
                                        onChange={(event) => {
                                            handleChange("costPrice", event.target.value, index);
                                        }}
                                        value={range?.costPrice}
                                        fullWidth
                                        error={(error.costPrice && (error.costPriceIndex === index))}
                                        helperText={(error.costPrice && (error.costPriceIndex === index)) && error.costPrice}
                                        sx={{
                                            backgroundColor: "#fff",
                                            borderRadius: "25px",
                                        }}
                                        placeholder="0.00"
                                    />
                                </div>
                            </Grid>
                            <Grid item md={1} lg={1} sx={{ padding: "15px", paddingLeft: "0px" }}>
                                <div>
                                    {index === (formValue.ranges.length - 1) ? (
                                        <ContinueShoppingButton
                                            onClick={addNewRange}
                                        >
                                            Add
                                        </ContinueShoppingButton>
                                    ) : (
                                        <DeleteIcon
                                            onClick={() => removeRange(index)}
                                        />

                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={1} lg={1} sx={{ padding: "15px" }}>
                                <div>
                                    <StyledTypography
                                        sx={{ padding: "20px 10px 30px 0px" }}
                                        color={"#2b2b2b"}
                                        size={17}
                                        height={1.18}
                                        weight={600}
                                    >
                                        END
                                    </StyledTypography>
                                </div>
                            </Grid>
                            <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                                <div>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={lensPowerData.spherical || []}
                                        placeholder="Select"
                                        onChange={(event, newValue) => {
                                            handleChange("endSph", newValue, index);
                                        }}
                                        value={range?.endSph}
                                        size="medium"
                                        renderInput={(params) => (
                                            <TextField
                                                error={error.endSph}
                                                helperText={error.endSph}
                                                sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                                {...params}
                                                placeholder="Select"
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                                <div>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={lensPowerData.cylindrical || []}
                                        placeholder="Select"
                                        onChange={(event, newValue) => {
                                            handleChange("endCyl", newValue, index);
                                        }}
                                        value={range?.endCyl}
                                        size="medium"
                                        renderInput={(params) => (
                                            <TextField
                                                error={error.endCyl}
                                                helperText={error.endCyl}
                                                sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                                {...params}
                                                placeholder="Select"
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>


                            {
                                formValue.lensType === "PROGRESSIVE" && (
                                    <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                                        <div>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={lensPowerData.add || []}
                                                placeholder="Select"
                                                onChange={(event, newValue) => {
                                                    handleChange("endAdd", newValue, index);
                                                }}
                                                value={range?.endAdd}
                                                size="medium"
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={error.endAdd}
                                                        helperText={error.endAdd}
                                                        sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                                                        {...params}
                                                        placeholder="Select"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Grid>
                                )}
                        </Grid>
                    </>
                ))
            }
            <div style={{ padding: "30px 0px 0px 0px" }}>
                <ContinueButton onClick={handleSubmit}>Submit</ContinueButton>
            </div>
            <SuccessModal
                success={success}
                successHeader={"Lens added successfully"}
                button={"Go to Lens Catalog"}
                handleSuccessClose={handleSuccessClose}
            ></SuccessModal>
        </>
    )
}