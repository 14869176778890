import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  setBrand,
  getBrand,
  setGlassDropDowns,
  getGlassDropDowns,
  addBrandErrors,
  editBrandErrors,
  getAddBrand,
  setAddBrand,
  setEditBrand,
  getEditBrand,
} from "../../app/reducers/brandSlice";

async function fetchBrandAPI(payload) {
  const indents = await axios
    .get('/client/brand/fetch', {
      params: {
        filter: {},
        limit: 15,
        page: payload,
        sort: { "created_at": -1 }
      }
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchBrand(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchBrandAPI, payload);
    yield put(setBrand(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchGlassesDropdownAPI(payload) {
  const headers = {
        "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
        "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token") 
    };    
  const apiURL = process.env.REACT_APP_API_URL + `/v2/inventory/common/meta/getv1`;
  const products = axios
    .get(apiURL, {
      headers: headers,
    })
    .then((response) => response.data.data);
  return products;
}

export function* fetchGlassesDropdown(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchGlassesDropdownAPI, payload);
    yield put(setGlassDropDowns(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function addBrandAPI(payload) {
    const indents = await axios
    .post('/client/brand/add', payload)
    .then((response) => response);
  return indents;
}
export function* addBrand(action) {
  try {
    const { payload } = action;
    const response = yield call(addBrandAPI, payload);
    yield put(setAddBrand(response.data.data));
  } catch (error) {
    yield put(addBrandErrors(error.response.data.message));
  }
}

async function editBrandAPI(payload) {
  const indents = await axios
    .post('/client/brand/update', payload)
    .then((response) => response.data.data);
  return indents;
}
export function* editBrand(action) {
  try {
    const { payload } = action;
    const response = yield call(editBrandAPI, payload);
    yield put(setEditBrand(response));
  } catch (error) {
    yield put(editBrandErrors(error.response.data.message));
  }
}

export function* brandSaga() {
  yield takeLatest(getBrand.type, fetchBrand);
  yield takeLatest(getGlassDropDowns.type, fetchGlassesDropdown);
  yield takeLatest(getAddBrand.type, addBrand);
  yield takeLatest(getEditBrand.type, editBrand);
}
