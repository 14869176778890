import React from "react";

export const DownloadIcon = (props) => {
    const color = props?.color ?? "#03a5a5";
    const width = props?.width ?? "24";
    const height = props?.height ?? "24";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g data-name="vuesax/outline/arrow-down">
        <path
          d="M10.75 21.5A10.75 10.75 0 1 1 21.5 10.75 10.759 10.759 0 0 1 10.75 21.5zm0-20A9.25 9.25 0 1 0 20 10.75a9.261 9.261 0 0 0-9.25-9.25z"
          transform="translate(1.25 1.25)"
        //   style="fill:#03a5a5"
        fill={color}
        />
        <path
          data-name="Vector"
          d="M.75 7.5A.755.755 0 0 1 0 6.75v-6A.755.755 0 0 1 .75 0a.755.755 0 0 1 .75.75v6a.755.755 0 0 1-.75.75z"
          transform="translate(11.25 7.75)"
        //   style="fill:#03a5a5"
        fill={color}
        />
        <path
          data-name="Vector"
          d="M3.747 4.5a.742.742 0 0 1-.53-.22l-3-3A.75.75 0 0 1 1.277.218l2.47 2.47L6.218.218a.75.75 0 1 1 1.06 1.06l-3 3a.742.742 0 0 1-.531.222z"
          transform="translate(8.253 11.753)"
        //   style="fill:#03a5a5"
        fill={color}
        />
      </g>
    </svg>
  );
};
