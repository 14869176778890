import React from "react";

export const SearchIcon = (props) => {
    const color = props?.color ?? "#827e7e";
    const width = props?.width ?? "24";
    const height = props?.height ?? "24";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        d="M10.25 20.5A10.25 10.25 0 1 1 20.5 10.25 10.264 10.264 0 0 1 10.25 20.5zm0-19A8.75 8.75 0 1 0 19 10.25a8.76 8.76 0 0 0-8.75-8.75z"
        transform="translate(1.25 1.25)"
        fill={color}
      />
      <path
        data-name="Vector"
        d="M2.748 3.5a.742.742 0 0 1-.53-.22l-2-2A.75.75 0 0 1 1.278.218l2 2a.754.754 0 0 1 0 1.06.742.742 0 0 1-.53.222z"
        transform="translate(19.253 19.253)"
        fill={color}
      />
    </svg>
  );
};
