import { List, ListItemText, ListItemButton, Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContinueButton, ListTable } from "../../components";
import NewUser from "./NewUser";
import EditUser from "./EditUser";
import {
  getUsers,
  setPageNoUser,
  clearUser,
} from "../../app/reducers/userSlice";
import _ from "lodash";

const User = (props) => {
  const [openAddUser, setOpenAddUser] = useState(false);
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userReducer.userList);
  const pageNo = useSelector((state) => state.userReducer.pageNoUser);
  const hasMore = useSelector((state) => state.userReducer.hasMoreUser);

  const userAdded = useSelector((state) => state.userReducer.userAdded);
  const userEdited = useSelector((state) => state.userReducer.userEdited);

  const [editUser, setEditUser] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [userList]);

  let tableData = [];

  const data = userList?.map((user) => {
    const userData = {
      userName: user?.name,
      userRole: user?.userType,
      phoneNumber: user?.phoneNumber,
      data: user,
    };
    tableData.push(userData);

    return tableData;
  });
 
  useEffect(() => {
    dispatch(clearUser());
    dispatch(getUsers(0));
  }, [userAdded, userEdited]);

  const addUser = () => {
    setOpenAddUser(true);
  };

  const handleBack = () => {
    setOpenAddUser(false);
    setEditUser(false);
  };

  const handleViewClick = (e, data) => {
    setRowData(data.data);
    setEditUser(true);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPageNoUser(pageNo + 1));
    dispatch(getUsers(pageNo + 1));
  };

  return (
    <>
      <div
        style={{
          padding: "90px 24px 40px 26px",
          height: "calc(100vh - 2%)",
          overflow: "hidden",
        }}
      >
        {openAddUser ? (
          <NewUser handleBack={handleBack} />
        ) : editUser ? (
          <EditUser handleBack={handleBack} row={rowData} />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                padding: "0px 0px 5px 0px",
              }}
            >
              <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
                User
              </div>
              <div style={{ padding: "0px 0px 5px 0px" }}>
                <ContinueButton onClick={addUser}> Add User</ContinueButton>
              </div>
            </div>
            <ListTable
              dataList={tableData}
              handleViewClick={handleViewClick}
              isLoading={loading}
              handleLoadmore={handleLoadmore}
              hasMore={hasMore}
              Header={["", "User Name", "User Role", "Phone Number", ""]}
              paddingLeft="100px"
              tableData={[
                { type: ["TEXT"], name: "" },
                { type: ["TEXT"], name: "userName" },
                { type: ["TEXT"], name: "userRole" },
                { type: ["TEXT"], name: "phoneNumber" },
                { type: ["VIEW"], name: "" },
              ]}
            />{" "}
          </>
        )}
      </div>
    </>
  );
};

export default User;
