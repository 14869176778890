import React, { useEffect, useState } from "react";
import { Grid, Autocomplete, TextField, InputAdornment } from "@mui/material";
import { ContinueButton, StyledTypography, BackIcon } from "../../components";
import { State, City } from "country-state-city";
import { getSaveUsers, setSaveUsersError } from "../../app/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { AlertComp } from "../../components/Alert";

const roleOptions = [
  { name: "Pick Up" },
  { name: "Fitter" },
  { name: "QC" },
  { name: "Shipping" },
  { name: "Packaging" },
];

const NewUser = (props) => {
  const dispatch = useDispatch();
  const [userValue, setUserValue] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const state = State.getStatesOfCountry("IN");
  const cities = City.getCitiesOfState("IN", selectedState);
  const [error, setError] = useState({});

  const userAdded = useSelector((state) => state.userReducer.userAdded);
  const userAddedError = useSelector(
    (state) => state.userReducer.userAddedError
  );

  useEffect(() => {
    if (!_.isEmpty(userAdded)) {
      props.handleBack();
    }
  });

  const handleBack = () => {
    props.handleBack();
  };

  const handleChange = (event, value, name) => {
    if (name === "state") {
      setSelectedState(value.isoCode);
    }
    if (value) {
      setUserValue({ ...userValue, [name]: value.name });
    } else {
      setUserValue({ ...userValue, [name]: event.target.value });
    }
  };

  const validation = async () => {
    let isValid = false;
    if (_.isEmpty(userValue?.name)) {
      isValid = false;
      setError({ name: " User Name is Mandatory" });
    } else if (_.isEmpty(userValue?.userType)) {
      isValid = false;
      setError({ userType: "userType is Mandatory" });
    } else if (_.isEmpty(userValue?.phoneNumber)) {
      isValid = false;
      setError({ phoneNumber: "phoneNumber is Mandatory" });
    } else if (_.isEmpty(userValue?.state)) {
      isValid = false;
      setError({ state: "state is Mandatory" });
    } else if (_.isEmpty(userValue?.city)) {
      isValid = false;
      setError({ city: "city is Mandatory" });
    } else if (_.isEmpty(userValue?.address)) {
      isValid = false;
      setError({ address: "address is Mandatory" });
    } else if (_.isEmpty(userValue?.email)) {
      isValid = false;
      setError({ email: "email is Mandatory" });
    } else if (_.isEmpty(userValue?.password)) {
      isValid = false;
      setError({ password: "password is Mandatory" });
    } else {
      isValid = true;
    }
    return isValid;
  };

  const handleUserSubmit = async () => {
    const valid = await validation();
    if (valid) {
      dispatch(getSaveUsers(userValue));
    }
  };

  const handleAlertClose = () => {
    dispatch(setSaveUsersError(""));
  };

  return (
    <>
      {userAddedError ? (
        <AlertComp
          type="error"
          message={userAddedError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          aligntItems: "center",
        }}
      >
        <div onClick={handleBack}>
          <BackIcon />
        </div>
        <StyledTypography
          sx={{ padding: "0px 0px 0px 10px" }}
          color={"#03a5a5"}
          size={22}
          height={1.23}
          weight={600}
        >
          NEW USER
        </StyledTypography>
      </div>

      <Grid container>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                USER NAME
              </StyledTypography>
              <TextField
                required
                fullWidth
                error={error.name}
                helperText={error.name}
                onChange={(event) => handleChange(event, "", "name")}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                USER ROLE
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={roleOptions}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "userType")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={error.userType}
                    helperText={error.userType}
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                PHONE NUMBER
              </StyledTypography>
              <TextField
                fullWidth
                error={error.phoneNumber}
                helperText={error.phoneNumber}
                onChange={(event) => {
                  handleChange(event, "", "phoneNumber");
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                STATE
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={state}
                getOptionLabel={(option) => option.name}
                placeholder="Select"
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "state");
                }}
                size="medium"
                renderInput={(params) => (
                  <TextField
                    error={error.state}
                    helperText={error.state}
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                CITY
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={cities}
                placeholder="Select"
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "city");
                }}
                size="medium"
                renderInput={(params) => (
                  <TextField
                    error={error.city}
                    helperText={error.city}
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                ADDRESS
              </StyledTypography>
              <TextField
                onChange={(event) => {
                  handleChange(event, "", "address");
                }}
                error={error.address}
                helperText={error.address}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                EMAIL ADDRESS
              </StyledTypography>
              <TextField
                fullWidth
                error={error.email}
                helperText={error.email}
                onChange={(event) => {
                  handleChange(event, "", "email");
                }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                PASSWORD
              </StyledTypography>
              <TextField
                fullWidth
                onChange={(event) => {
                  handleChange(event, "", "password");
                }}
                type="password"
                error={error.password}
                helperText={error.password}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
        </Grid>

        <div style={{ padding: "30px 0px 0px 0px" }}>
          <ContinueButton onClick={handleUserSubmit}>Submit</ContinueButton>
        </div>
      </Grid>
    </>
  );
};

export default NewUser;
