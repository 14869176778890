import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import {
  Autocomplete,
  TextField,
  Typography,
  Dialog,
  Grid,
  IconButton,
} from "@mui/material";
import StepperComp from "./StepperComp";
import {
  ContinueButton,
  ListTable,
  StyledTypography,
} from "../../../components";
import TransferProducts from "./TransferProducts";
import { useDispatch, useSelector } from "react-redux";
import {
  getStocks,
  getShipmentSaveEx,
  getUpdateStatusEx,
  setPagenoStocks,
  clearStocks
} from "../../../app/reducers/stockTransferSlice";
import moment from "moment";
import ScanAddStockTransfer from "./ScanAddStockTransfer";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StockTransfer = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const stockTransferList = useSelector(
    (state) => state.stockTransferReducer.stockTransferList
  );

  const stocksTransferred = useSelector(
    (state) => state.stockTransferReducer.savedExchanges
  );
  const shipmentUpdate = useSelector(
    (state) => state.stockTransferReducer.shipmentStatusExchange
  );
  const statusUpdate = useSelector(
    (state) => state.stockTransferReducer.statusUpdateExchange
  );

  const pageNo = useSelector((state) => state.stockTransferReducer.pageNoStocks);
  const hasMore = useSelector((state) => state.stockTransferReducer.hasMoreStocks);

  const [openTransfer, setOpenTransfer] = useState(false);
  const [transferData, setTransferData] = useState({});
  const [openAddStocks, setOpenAddStocks] = useState(false);

  const [openShipment, setOpenShipment] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [shippingData, setShippingData] = useState({});
  const [selectedTransferId, setSelectedTransferId] = useState("");

  const [openDelivery, setOpenDelivery] = useState(false);
  const [deliveryComments, setDeliveryComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [currentChart, setCurrentChart] = useState({});

  const handleDateChange = (newValue, name) => {
    const date = new Date(newValue).toISOString();
    setDateValue(newValue);
    setShippingData({ ...shippingData, [name]: date });
  };

  const handleShippingChange = (event, name) => {
    setShippingData({ ...shippingData, [name]: event.target.value });
  };

  const handleSubmit = () => {
   
    const obj = {
      shippingDetails: shippingData,
      transferId: selectedTransferId?.transferId,
    };
    dispatch(getShipmentSaveEx(obj));
    setOpenShipment(false);
  };

  const handleDelivery = () => {
    const date = new Date(Date.now()).toISOString();
    const obj = {
      deliveryDetails: {
        deliveryDate: date,
      },
      transferId: selectedTransferId?.transferId,
      comments: deliveryComments,
    };
    dispatch(getUpdateStatusEx(obj));
    setOpenDelivery(false);
  };

  const handleDeliveryChange = (event) => {
    setDeliveryComments(event.target.value);
  };

  useEffect(() => {
    dispatch(clearStocks());
    dispatch(getStocks(0));
  }, [stocksTransferred, shipmentUpdate, statusUpdate]);


  useEffect(() => {
    setLoading(false);
  }, [stockTransferList]);

  const handleViewClick = (e, data) => {

    setTransferData(data.data);
    setOpenTransfer(true);
  };
  const handleBack = () => {
    setOpenTransfer(false);
    setOpenAddStocks(false);
  };

  const handleClose = () => {
    setOpenShipment(false);
    setOpenDelivery(false);
    setOpenStatusModal(false);
  };

  const handleStoreTransferModal = () => {
    setOpenAddStocks(true);
  };
  let tableData = [];

  stockTransferList?.map((stocks) => {
    const stocksData = {
      source: stocks?.source?.type,
      destination: stocks?.destination?.type,
      storeId: stocks?.source?.storeId || stocks?.destination.storeId,
      storeName: stocks?.source?.storeName || stocks?.destination.storeName,
      reason: stocks?.reason,
      transferId: stocks?.transferId,
      transferDate: moment(stocks?._created_at).format("ll"),
      status: stocks?.status,
      refreshIcon:
        stocks?.status === "Delivered" ||
          (stocks?.status === "Shipped" && stocks?.source.type === "Warehouse") ||
          (stocks?.status === "Created" && stocks?.source.type === "Store")
          ? ""
          : "refresh",
      data: stocks,
    };
    tableData.push(stocksData);

    return tableData;
  });

  const handleStatusClick = (e, data) => {
    if (data?.status === "Shipped" && data?.source === "Warehouse") {
      setOpenShipment(true);
    } else if (data?.status === "Shipped" && data?.source === "Store") {
      setOpenDelivery(true);
    } else {
      setOpenShipment(true);
    }
    setSelectedTransferId(data.data);
  };
  const handleChartIcon = (e, data) => {
 
    setCurrentChart(data.data)
    setOpenStatusModal(true);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoStocks(pageNo + 1));
    dispatch(getStocks(pageNo + 1));
  }


  return (
    <div className={classes.root}>
      {openTransfer ? (
        <TransferProducts handleBack={handleBack} data={transferData} />
      ) : openAddStocks ? (
        <ScanAddStockTransfer
          handleBack={handleBack}
          openAddStocks={openAddStocks}
        />
      ) : (
        <div>
          <div className={classes.alignEntire} style={{ marginBottom: "15px" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
              Stock Transfer
            </Typography>
            <ContinueButton onClick={handleStoreTransferModal}>
              Stock Transfer
            </ContinueButton>
          </div>
          <ListTable
            dataList={tableData}
            handleViewClick={handleViewClick}
            handleStatusClick={handleStatusClick}
            handleChartIcon={handleChartIcon}
            isLoading={loading}
            handleLoadmore={handleLoadmore}
            hasMore={hasMore}
            Header={[
              "Source",
              "Destination",
              "Store ID",
              "Store Name",
              "Reason",
              "Transfer ID",
              "Transfer Date",
              "Status",
              "Actions",
            ]}
            paddingLeft="100px"
            tableData={[
              { type: ["TEXT"], name: "source" },
              { type: ["TEXT"], name: "destination" },
              { type: ["TEXT"], name: "storeId" },
              { type: ["TEXT"], name: "storeName" },
              { type: ["TEXT"], name: "reason" },
              { type: ["TEXT"], name: "transferId" },
              { type: ["TEXT"], name: "transferDate" },
              { type: ["ORDER_STATUS"], name: "status" },
              { type: ["VIEW"], name: "status" },
            ]}
          />
        </div>
      )}

      {openStatusModal && (
        <StepperComp
          open={openStatusModal}
          handleClose={handleClose}
          data={currentChart}
        />
      )}

      <Dialog maxWidth={false} open={openShipment} onClose={handleClose}>
        <div
          style={{
            width: "936px",
            height: "582px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              aligntItems: "center",
              padding: "0px 10px",
            }}
          >
            <StyledTypography
              sx={{ margin: "20px 60px 30px 11px" }}
              color={"#2b2b2b"}
              size={18}
              height={1.28}
              weight={700}
            >
              Update Shipment
            </StyledTypography>
            <div>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "#242424",
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "0px 20px",
              }}
            >
              <StyledTypography>TRANSFER ID/DATE:</StyledTypography>
              <StyledTypography color={"#2b2b2b"}>
                {selectedTransferId?.transferId} /{" "}
                {moment(selectedTransferId?._created_at).format("ll")}
              </StyledTypography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingRight: "30px",
              }}
            >
              <StyledTypography>STORE ID: </StyledTypography>{" "}
              <StyledTypography color={"#2b2b2b"}>
                {props?.returnDetails?.storeId}
              </StyledTypography>
            </div>
          </div>
          <Grid container>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  STATUS
                </StyledTypography>
                <TextField
                  value={"Shipped"}
                  disabled={true}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  SHIPPING DATE
                </StyledTypography>
                <DatePicker
                  selected={dateValue}
                  autoComplete="off"
                  onChange={(date) => handleDateChange(date, "shippingDate")}
                  dateFormat="dd/MM/yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="ShippingDate"
                  customInput={
                    <TextField
                      fullWidth
                      type="text"
                      placeholder="ShippingDate"
                      variant="outlined"
                    />
                  }
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  SERVICE PROVIDER
                </StyledTypography>
                <TextField
                  onChange={(event) =>
                    handleShippingChange(event, "serviceProvider")
                  }
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder="type here ..."
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  TRACKING ID
                </StyledTypography>
                <TextField
                  onChange={(event) =>
                    handleShippingChange(event, "trackingId")
                  }
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                  }}
                  placeholder="type here ..."
                />
              </div>
            </Grid>
            <Grid container>
              <Grid item md={6} lg={6}>
                <div style={{ margin: "20px 8px 0px 20px" }}>
                  <StyledTypography
                    sx={{ padding: "0px 0px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={17}
                    height={1.18}
                    weight={600}
                  >
                    COMMENTS
                  </StyledTypography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    onChange={(event) =>
                      handleShippingChange(event, "comments")
                    }
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "25px",
                    }}
                    placeholder="type here ..."
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <div style={{ padding: "20px 30px" }}>
            <ContinueButton onClick={handleSubmit}>Submit</ContinueButton>
          </div>
        </div>
      </Dialog>
      <Dialog maxWidth={false} open={openDelivery} onClose={handleClose}>
        <div
          style={{
            width: "936px",
            height: "582px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              aligntItems: "center",
              padding: "0px 10px",
            }}
          >
            <StyledTypography
              sx={{ margin: "20px 60px 30px 11px" }}
              color={"#2b2b2b"}
              size={18}
              height={1.28}
              weight={700}
            >
              Update Status
            </StyledTypography>
            <div>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "#242424",
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "0px 20px",
              }}
            >
              <StyledTypography>TRANSFER ID/DATE:</StyledTypography>
              <StyledTypography color={"#2b2b2b"}>
                {selectedTransferId?.transferId} /{" "}
                {moment(selectedTransferId?._created_at).format("ll")}
              </StyledTypography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingRight: "30px",
              }}
            >
              <StyledTypography>STORE ID: </StyledTypography>{" "}
              <StyledTypography color={"#2b2b2b"}>{ }</StyledTypography>
            </div>
          </div>
          <Grid container>
            <Grid container>
              <Grid item md={6} lg={6}>
                <div style={{ margin: "20px 8px 0px 20px" }}>
                  <StyledTypography
                    sx={{ padding: "0px 0px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={17}
                    height={1.18}
                    weight={600}
                  >
                    STATUS
                  </StyledTypography>
                  <TextField
                    value={"Delivered"}
                    disabled={true}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "25px",
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6} lg={6}>
                <div style={{ margin: "20px 8px 0px 20px" }}>
                  <StyledTypography
                    sx={{ padding: "0px 0px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={17}
                    height={1.18}
                    weight={600}
                  >
                    COMMENTS
                  </StyledTypography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    onChange={(event) =>
                      handleDeliveryChange(event, "comments")
                    }
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "25px",
                    }}
                    placeholder="type here ..."
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ padding: "20px 30px" }}>
            <ContinueButton onClick={handleDelivery}>Submit</ContinueButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default StockTransfer;
