import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  storeList: [],
  inventoryList: [],
  pageNoStores: 0,
  pageNoInventories: 0,
  hasMoreStores: true,
  hasMoreInventories: true,
  storeData: {},
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    getStores() {},
    getStoresSuccess(state, action) {
      return Object.assign({}, state, {
        storeList:
          state.pageNoStores > 0
            ? [...state.storeList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreStores: action.payload.length < 15 ? false : true,
      });
    },
    setPagenoStores(state, action) {
      return Object.assign({}, state, {
        pageNoStores: action.payload,
      });
    },
    setPagenoInventories(state, action) {
      return Object.assign({}, state, {
        pageNoInventories: action.payload,
      });
    },

    getStoreInventories() {},
    getStoreInventoriesSuccess(state, action) {
      return Object.assign({}, state, {
        inventoryList:
          state.pageNoInventories > 0
            ? [...state.inventoryList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreInventories: action.payload.length < 15 ? false : true,
      });
    },

    setStoreData(state, action) {
      return Object.assign({}, state, {
        storeData: isEmpty(action.payload) ? {} : action.payload,
      });
    },

    clearStores(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getStores,
  getStoresSuccess,
  setPagenoStores,
  getStoreInventories,
  getStoreInventoriesSuccess,
  setPagenoInventories,
  setStoreData,
  clearStores,
} = storeSlice.actions;

export default storeSlice.reducer;
