import {
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  IconButton,
  Autocomplete,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContinueButton, ListTable, StyledTypography } from "../../components";
import {
  getBrand,
  getGlassDropDowns,
  getAddBrand,
  setPagenoBrand,
  clearBrand,
  addBrandErrors,
} from "../../app/reducers/brandSlice";
import ViewBrand from "./ViewBrand";
import CloseIcon from "@mui/icons-material/Close";
import Dropzone from "react-dropzone";
import S3 from "react-aws-s3";
import _ from "lodash";
import { AlertComp } from "../../components/Alert";
window.Buffer = window.Buffer || require("buffer").Buffer;

const Brand = (props) => {
  const dispatch = useDispatch();
  const [openAddBrand, setOpenAddBrand] = useState(false);
  const [openViewBrand, setOpenViewBrand] = useState(false);
  const [userValue, setUserValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState({});

  const brandList = useSelector((state) => state.brandReducer.brandList);
  const dropDown = useSelector((state) => state.brandReducer.glassDropDown);
  const addBrandData = useSelector((state) => state.brandReducer.addBrand);
  const addBrandError = useSelector(
    (state) => state.brandReducer.addBrandError
  );

  const editBrandData = useSelector((state) => state.brandReducer.editBrand);
  const pageNo = useSelector((state) => state.brandReducer.pageNoBrand);
  const hasMore = useSelector((state) => state.brandReducer.hasMoreBrands);
  const [error, setError] = useState({});
  let tableData = [];
  const data = brandList?.map((brand) => {
    const brandData = {
      brandName: brand?.name,
      category: brand?.category.join(", "),
      logo: brand?.logo,
      data: brand,
    };
    tableData.push(brandData);
    return tableData;
  });
  useEffect(() => {
    setOpenAddBrand(false);
  }, [addBrandData]);

  useEffect(() => {
    dispatch(clearBrand());
    dispatch(getBrand(0));
    dispatch(getGlassDropDowns());
  }, [addBrandData, editBrandData]);

  useEffect(() => {
    setLoading(false);
  }, [brandList]);

  const addBrand = () => {
    setOpenAddBrand(true);
  };

  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET,
    dirName: "eyeplay",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };

  const ReactS3Client = new S3(config);
  const uploadToS3 = async () => {
    return await ReactS3Client.uploadFile(
      userValue?.logo,
      `Master/brand/${userValue?.name}${userValue?.category}`
    );
  };

  const imageUpload = async () => {
    let obj = {};
    const data = await uploadToS3();
    Object.assign(obj, { logo: data.location });
    return obj;
  };

  const validation = async () => {
    let isValid = false;
    if (_.isEmpty(userValue?.name)) {
      isValid = false;
      setError({ name: " Brand name is Mandatory" });
    } else if (_.isEmpty(userValue?.category)) {
      isValid = false;
      setError({ category: "Category is Mandatory" });
    } else if (_.isEmpty(userValue?.logo)) {
      isValid = false;
      setError({ logo: "logo is Mandatory" });
    } else {
      isValid = true;
    }
    return isValid;
  };

  const addNewBrand = async () => {
    const valid = await validation();
    if (valid) {
      const data = await imageUpload();
      const obj = {
        ...userValue,
        ...data,
      };
      dispatch(getAddBrand(obj));
    }
  };

  const handleBack = () => {
    setOpenAddBrand(false);
    setOpenViewBrand(false);
  };

  const handleViewClick = (e, data) => {
    setRow(data.data);
    setOpenViewBrand(true);
  };

  const handleChange = (event, value, name) => {
    if (value) {
      setUserValue({ ...userValue, [name]: value });
    } else {
      setUserValue({ ...userValue, [name]: event.target.value });
    }
  };

  const handleImageChange = (acceptedFiles) => {
    const fileWithPreview = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setUserValue({ ...userValue, logo: fileWithPreview[0] });
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoBrand(pageNo + 1));
    dispatch(getBrand(pageNo + 1));
  };

  const handleAlertClose = () => {
    dispatch(addBrandErrors(""));
  };


  return (
    <>
      <div
        style={{
          padding: "90px 24px 40px 26px",
          height: "calc(100vh - 2%)",
          overflow: "hidden",
        }}
      >
        {openViewBrand ? (
          <ViewBrand
            open={openViewBrand}
            handleBack={handleBack}
            row={row}
            dropDown={dropDown}
          />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                padding: "0px 0px 5px 0px",
              }}
            >
              <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
                Brand
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  aligntItems: "center",
                }}
              >
                <div style={{ padding: "0px 0px 5px 0px" }}>
                  <ContinueButton onClick={addBrand}>Add Brand</ContinueButton>
                </div>
              </div>
            </div>
            <ListTable
              dataList={tableData}
              handleViewClick={handleViewClick}
              isLoading={loading}
              handleLoadmore={handleLoadmore}
              hasMore={hasMore}
              Header={["Brand Name", "Category", "Logo", ""]}
              paddingLeft="100px"
              tableData={[
                { type: ["TEXT"], name: "brandName" },
                { type: ["TEXT"], name: "category" },
                { type: ["LOGO"], name: "logo" },
                { type: ["VIEW"], name: "" },
              ]}
            />
          </>
        )}
        <Dialog
          open={openAddBrand}
          onClose={handleBack}
          fullWidth
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ borderRadius: "8px", minWidth: "1000px" }}
        >
          {addBrandError ? (
            <AlertComp
              type="error"
              message={addBrandError}
              handleAlertClose={handleAlertClose}
            />
          ) : (
            ""
          )}
          <DialogTitle>
            <StyledTypography
              sx={{ padding: "0px" }}
              color={"#2b2b2b"}
              size={20}
              height={1.98}
              weight={800}
            >
              New Brand
            </StyledTypography>
            <IconButton
              aria-label="close"
              onClick={handleBack}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid>
              <Grid container>
                <Grid item md={6} lg={6} sx={{ padding: "15px" }}>
                  <div>
                    <StyledTypography
                      sx={{ padding: "20px 10px 30px 0px" }}
                      color={"#2b2b2b"}
                      size={17}
                      height={1.18}
                      weight={600}
                    >
                      BRAND
                    </StyledTypography>
                    <TextField
                      fullWidth
                      error={error.name}
                      helperText={error.name}
                      onChange={(event) => handleChange(event, "", "name")}
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "25px",
                      }}
                      placeholder="Type Here"
                    />
                  </div>
                </Grid>
                <Grid item md={6} lg={6} sx={{ padding: "15px" }}>
                  <div>
                    <StyledTypography
                      sx={{ padding: "20px 10px 30px 0px" }}
                      color={"#2+b2b2b"}
                      size={17}
                      height={1.18}
                      weight={600}
                    >
                      CATEGORY
                    </StyledTypography>
                    <Autocomplete
                      multiple
                      id="combo-box"
                      size="medium"
                      options={dropDown.FrameCategory || []}
                      onChange={(event, newValue) =>
                        handleChange(event, newValue, "category")
                      }
                      placeholder="Select"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                          {...params}
                          placeholder="Select"
                        />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={8} lg={8} sx={{ padding: "15px" }}>
                  <StyledTypography
                    sx={{ padding: "20px 10px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={12}
                    height={1.18}
                    weight={600}
                    maxFiles={1}
                  >
                    BRAND LOGO
                  </StyledTypography>
                  <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "20px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(acceptedFiles);
                      }}
                      name="thumbNail"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop Thumbnail image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div>
                      <img
                        style={{
                          width: "170px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={userValue?.logo?.preview}
                        alt="Thumbnail"
                      />
                    </div>
                    <div style={{ color: "#FF0000" }}>{error.logo}</div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <div>
              <ContinueButton onClick={addNewBrand}>Confirm</ContinueButton>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default Brand;
