import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  getReturns,
  setReturns,
  getUpdateStatusReturns,
  setUpdateStatusReturns,
  setShipmentSaveReturns,
  getShipmentSaveReturns,
  getDelivery,
  setDelivery
} from "../../app/reducers/returnsSlice";
import { getAssignee } from "../../app/reducers/assigneeSlice";

async function fetchReturnsAPI(payload) {
  const indents = await axios
    .get('/client/returns/returns', {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { intendId: 1 },
      }
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchReturns(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchReturnsAPI, payload);
    yield put(setReturns(response));
    yield put(getAssignee());
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function updateStatusAPI(payload) {
  const indents = await axios
    .post('/client/returns/commonstatus', payload)
    .then((response) => response.data);
  return indents;
}
export function* updateStatus(action) {
  try {
    const { payload } = action;
    const response = yield call(updateStatusAPI, payload);
    yield put(setUpdateStatusReturns(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function updateShipmentAPI(payload) {
  const indents = await axios
    .post('/client/returns/updateshippingstatus', payload)
    .then((response) => response.data);
  return indents;
}
export function* updateShipment(action) {
  try {
    const { payload } = action;
    const response = yield call(updateShipmentAPI, payload);
    const object = {
      message: response,
      data: payload,
    };
    yield put(setShipmentSaveReturns(object));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function updateDeliveryAPI(payload) {
  const indents = await axios
    .post('/client/returns/updatedeliverystatus', payload)
    .then((response) => response.data);
  return indents;
}
export function* updateDelivery(action) {
  try {
    const { payload } = action;
    const response = yield call(updateDeliveryAPI, payload);
    yield put(setDelivery(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}
export function* returnsSaga() {
  yield takeLatest(getReturns.type, fetchReturns);
  yield takeLatest(getUpdateStatusReturns.type, updateStatus);
  yield takeLatest(getShipmentSaveReturns.type, updateShipment);
  yield takeLatest(getDelivery.type, updateDelivery);
}
