import {
  Autocomplete,
  Checkbox,
  Dialog,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOnboarding, clearOnboarding, setPagenoOnboarding } from "../../../app/reducers/onboardingSlice";
import {
  ListTable,
  SearchIcon,
  StyledTypography,
  SubLabelTypography,
} from "../../../components";
import BarCodeModal from "./BarCodeModal";
import { useStyles } from "./styles";
import moment from "moment";

const options = ["All", "None"];


const CustomPaper = (props) => {
  return <Paper elevation={8} style={{ width: "120px" }} {...props} />;
};

function Onboarding(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onBoardingList = useSelector((state) => state.onboardingReducer.onBoardingList);  
  const barcodeData = useSelector((state) => state.onboardingReducer.barcodeList);
  const updatedBarcodes = useSelector((state) => state.onboardingReducer.updatedBarcodes);
  
  const pageNo = useSelector((state) => state.onboardingReducer.pageNoOnboard);
  const hasMore = useSelector((state) => state.onboardingReducer.hasMoreOnboard);

  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");
  const [row,setRow] = useState({});
  const [barCodeModal, setBarCodeModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(clearOnboarding());
    dispatch(getOnboarding(0));
  },[dispatch])

  let tableData = [];

  
  useEffect(() => {
    setLoading(false);
  }, [onBoardingList]);

  onBoardingList?.map((glass) => {
    const glassData = {
      dateTime: moment(glass?._created_at).format("LLL"),
      brand: glass?.Brand,
      model: glass?.ModelNo,
      colorCode: glass?.ColorCode,
      frameColor: glass?.FrameColor,
      sizeActual: glass?.SizeActual,
      quantity: glass?.Quantity,
      data: glass,
    };
    tableData.push(glassData);

    return tableData;
  });

  const handleBar = (e, data) => {

    setRow(data.data);
    setBarCodeModal(true);
  };

  const handleCloseBarCode =()=> {
    setBarCodeModal(false)
  }

  
  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoOnboarding(pageNo + 1));
    dispatch(getOnboarding(pageNo+1));
  }


  return (
    <div className={classes.root}>
      <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
        Onboarded Products
      </Typography>
      {/* <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox checked={check.length > 0} />
        <StyledTypography letter="0" color="#858585" weight="600">
          {check.length + " Selected"}
        </StyledTypography>
      </div> */}
      <ListTable
        //checkBox={true}
        // totalCheck={check}
        // singleCheck={singleCheck}
        // handleCheckBox={handleCheckBox}
        // handleSingleCheck={handleSingleCheck}
        isLoading={loading}
        handleLoadmore={handleLoadmore}
        hasMore={hasMore}
        dataList={tableData}
        handleBar={handleBar}
        Header={[
          "Date/Time",
          "Brand",
          "Model",
          "Frame Color",
          "Color Code",
          "Size Actual",
          "Quantity",
          "",
        ]}
        paddingLeft="100px"
        tableData={[
          // { type: ["CHECKBOX"], name: "" },
          { type: ["TEXT"], name: "dateTime" },
          { type: ["TEXT"], name: "brand" },
          { type: ["TEXT"], name: "model" },
          { type: ["TEXT"], name: "frameColor" },
          { type: ["TEXT"], name: "colorCode" },
          { type: ["TEXT"], name: "sizeActual" },
          { type: ["TEXT"], name: "quantity" },
          { type: ["BAR_CODE"], name: "" },
        ]}
      />
      <Dialog PaperProps={{ classes:{root:classes?.modelSize}}} open={barCodeModal} onClose={handleCloseBarCode}>
        <BarCodeModal  handleCloseBarCode={handleCloseBarCode} row={row} />
      </Dialog>
    </div>
  );
}

export default Onboarding;
