import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  assigneeList: [],
  assigneeSelected: {},
  assigneeSelectedReturns: {},
};

export const assigneeSlice = createSlice({
  name: "assignee",
  initialState,
  reducers: {
    getAssignee() {},
    setAssignee(state, action) {
      return Object.assign({}, state, {
        assigneeList: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getSaveAssignee() {},
    setSaveAssignee(state, action) {
      return Object.assign({}, state, {
        assigneeSelected: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getSaveAssigneeReturns() {},
    setSaveAssigneeReturns(state, action) {
      return Object.assign({}, state, {
        assigneeSelectedReturns: isEmpty(action.payload) ? {} : action.payload,
      });
    },

    clearAssignee(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  setSaveAssignee,
  getSaveAssignee,
  setAssignee,
  getAssignee,
  getSaveAssigneeReturns,
  setSaveAssigneeReturns,
  clearAssignee
} = assigneeSlice.actions;

export default assigneeSlice.reducer;
