import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import { getUpdateStatus, setUpdateStatus, getShipmentSave, setShipmentSave, getLensUpdation, setLensUpdation } from "../../app/reducers/orderSlice";

async function updateStatusAPI(payload) {
  const indents = await axios
    .post('/client/joborder/commonstatus', payload)
    .then((response) => response.data);
  return indents;
}
export function* updateStatus(action) {
  try {
    const { payload } = action;
    const response = yield call(updateStatusAPI, payload);
    yield put(setUpdateStatus(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function updateShipmentAPI(payload) {
    const indents = await axios
      .post('/client/joborder/updateshippingstatus', payload)
      .then((response) => response.data);
    return indents;
  }
  export function* updateShipment(action) {
    try {
      const { payload } = action;
      const response = yield call(updateShipmentAPI, payload);
      const object = {
          message: response,
          data: payload
      };
      yield put(setShipmentSave(object));
    } catch (error) {
      yield put({ type: "ERRORS", payload: error });
    }
  }

async function updateLensAPI(payload) {
  const indents = await axios
    .post('/client/joborder/updatecostprice', payload)
    .then((response) => response.data);
  return indents;
}
export function* updateLens(action) {
  try {
    const { payload } = action;
    const response = yield call(updateLensAPI, payload);
    yield put(setLensUpdation(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}


export function* orderSaga() {
  yield takeLatest(getUpdateStatus.type, updateStatus);
  yield takeLatest(getShipmentSave.type, updateShipment);
  yield takeLatest(getLensUpdation.type, updateLens);
}
