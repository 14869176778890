import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
  noOutline: {
    fontSize: "14px",
    padding: "0px",
    paddingRight: "0px !important",
  },
  root: {
    padding: "85px 24px 40px 26px",
    height: "calc(100vh - 12%)",
    overflow: "hidden",
  },
  alignItems: {
    display: "flex",
    alignItems: "center",
  },
  modelSize: {
    maxWidth: "750px",
  },
});
