import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  stockTransferList: [],
  storeList: [],
  inventory: [],
  savedExchanges: {},
  shipmentStatusExchange: {},
  statusUpdateExchange: {},
  pageNoStocks: 0,
  hasMoreStocks: true,
  barcodeUsb: "",
};

export const stockTransferSlice = createSlice({
  name: "stockTransfer",
  initialState,
  reducers: {
    getStocks() {},
    setStocks(state, action) {
      return Object.assign({}, state, {
        stockTransferList:
          state.pageNoStocks > 0
            ? [...state.stockTransferList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreStocks: action.payload.length < 15 ? false : true,
      });
    },
    usbScanner(state, action) {
      const regex = /\n|\r\n|\n\r|\r/gm;
      const payload = action.payload.replace(regex, "");
      return Object.assign({}, state, {
        barcodeUsb: isEmpty(payload) ? "" : payload,
      });
    },
    getStoreList() {},
    setStoreList(state, action) {
      return Object.assign({}, state, {
        storeList: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getInventory() {},
    setInventory(state, action) {
      return Object.assign({}, state, {
        inventory: isEmpty(action.payload)
          ? state.inventory
          : [...state.inventory, action.payload],
      });
    },
    deleteInventory(state, action) {
      return Object.assign({}, state, {
        inventory: state.inventory.filter((data, i) => i !== action.payload),
      });
    },
    getSaveExchange() {},
    setSaveExchange(state, action) {
      return Object.assign({}, state, {
        savedExchanges: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getShipmentSaveEx() {},
    setShipmentSaveEx(state, action) {
      return Object.assign({}, state, {
        shipmentStatusExchange: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getUpdateStatusEx() {},
    setUpdateStatusEx(state, action) {
      return Object.assign({}, state, {
        statusUpdateExchange: isEmpty(action.payload) ? {} : action.payload,
      });
    },

    setPagenoStocks(state, action) {
      return Object.assign({}, state, {
        pageNoStocks: action.payload,
      });
    },
    clearStocks(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getStocks,
  setStocks,
  getStoreList,
  setStoreList,
  setInventory,
  getInventory,
  deleteInventory,
  setSaveExchange,
  getSaveExchange,
  getShipmentSaveEx,
  setShipmentSaveEx,
  getUpdateStatusEx,
  setUpdateStatusEx,
  setPagenoStocks,
  clearStocks,
  usbScanner
} = stockTransferSlice.actions;

export default stockTransferSlice.reducer;
