import  React, { useState, useEffect } from "react";
import {
  Dialog,
  IconButton,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ContinueButton, StyledTypography } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getSaveAssignee, getSaveAssigneeReturns } from "../../app/reducers/assigneeSlice";

const AssigneeModal = (props) => {
  
  const dispatch = useDispatch();
  const [selectedRoles, setSelectedRoles] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);

  const isAssigneeSelected = useSelector((state) => state.assigneeReducer.assigneeSelected);

  const pickupOptions = (role) => {
      let arr = [];
      props?.assigneeList?.map( (assignee, index) => {
          if( assignee.userType === role){
             assignee?.users?.map( (data) => {
                 arr.push({ value: data?.name , key: data?._id});
             })
          }
      });
      return arr;
  };
  
  const handleChange = (event, newValue, roles) => {
    setSelectedRoles({...selectedRoles, [roles]: { "name" : newValue?.value, "userId": newValue?.key }});
  };
  
  useEffect(() => {
    if(props.requireFitting){
       if(Object.keys(selectedRoles).length === 5){
        setDisableSubmit(false);
       }
    } else {
      if(Object.keys(selectedRoles).length === 3){
        setDisableSubmit(false);
       }
    }
  });

  useEffect(() => {

  },[isAssigneeSelected]);

  const handleSubmit = async () => {
    const obj = {
        "jobOrder_id": props?.orderDetails?.jobOrder_id,
        "assignees": selectedRoles,
    };

    const returnObj = {
        "returnId": props?.returnDetails?.returnId,
        "product_id": props?.returnDetails?.product_id,
        "assignees": selectedRoles,
    };
    
    if(props?.fromReturns){
  
      dispatch(getSaveAssigneeReturns(returnObj));
    } else {
      dispatch(getSaveAssignee(obj));
    }
    props.handleClose();
    
  };

  
  return (
    <Dialog maxWidth={false} open={props.open} onClose={props.handleClose}>
      <div
        style={{
          width: "936px",
          height: "582px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
            padding: "0px 10px",
          }}
        >
          <StyledTypography
            sx={{ margin: "20px 60px 30px 11px" }}
            color={"#2b2b2b"}
            size={18}
            height={1.28}
            weight={700}
          >
            Assignee
          </StyledTypography>
          <div>
            <IconButton
              aria-label="close"
              onClick={props.handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#242424",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
           {props?.fromReturns ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "0px 20px",
                }}
              >
                <StyledTypography>RETURN ID/DATE:</StyledTypography>
                <StyledTypography color={"#2b2b2b"}>
                  {props?.returnDetails?.returnData}
                </StyledTypography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <StyledTypography>STORE ID: </StyledTypography>{" "}
                <StyledTypography color={"#2b2b2b"}>
                  {props?.returnDetails?.storeId}
                </StyledTypography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <StyledTypography>ORDER ID/DATE:</StyledTypography>{" "}
                <StyledTypography color={"#2b2b2b"}>
                  {" "}
                  {props?.returnDetails?.orderId}
                </StyledTypography>
              </div>
            </>
          ) : (
          <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "0px 20px",
            }}
          >
            <StyledTypography>ORDER ID/DATE:</StyledTypography>
            <StyledTypography color={"#2b2b2b"}>
              {props?.orderDetails?.orderId}
            </StyledTypography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingRight: "30px",
            }}
          >
            <StyledTypography>JOB ORDER ID: </StyledTypography>{" "}
            <StyledTypography color={"#2b2b2b"}>
              {props?.orderDetails?.jobOrderId}
            </StyledTypography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingRight: "30px",
            }}
          >
            <StyledTypography>STORE ID: </StyledTypography>{" "}
            <StyledTypography color={"#2b2b2b"}>
              {props?.orderDetails?.storeId}
            </StyledTypography>
          </div> </>) }
        </div> 
        {props?.requireFitting ? (
          <Grid container>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  PRODUCT PICKUP
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={pickupOptions("Pick Up")}
                  getOptionLabel={ (option) => option.value }
                  placeholder="Select"
                  sx={{ width: "50%" }}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "pickup");
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  FITTER
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={pickupOptions("Fitter")}
                  getOptionLabel={ (option) => option.value }
                  placeholder="Select"
                  sx={{ width: "50%" }}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "fitter");
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  QC
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={pickupOptions("QC")}
                  getOptionLabel={ (option) => option.value }
                  placeholder="Select"
                  sx={{ width: "50%" }}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "qc");
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  PACKAGING
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={pickupOptions("Packaging")}
                  getOptionLabel={ (option) => option.value }
                  placeholder="Select"
                  sx={{ width: "50%" }}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "packing");
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  SHIPPING
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={pickupOptions("Shipping")}
                  getOptionLabel={ (option) => option.value }
                  placeholder="Select"
                  sx={{ width: "50%" }}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "shipping");
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  QC
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={pickupOptions("QC")}
                  getOptionLabel={ (option) => option.value }
                  placeholder="Select"
                  sx={{ width: "50%" }}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "qc");
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  PACKAGING
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={pickupOptions("Packaging")}
                  getOptionLabel={ (option) => option.value }
                  placeholder="Select"
                  sx={{ width: "50%" }}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "packing");
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <div style={{ margin: "20px 8px 0px 20px" }}>
                <StyledTypography
                  sx={{ padding: "0px 0px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  SHIPPING
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={pickupOptions("Shipping")}
                  getOptionLabel={ (option) => option.value }
                  placeholder="Select"
                  sx={{ width: "50%" }}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "shipping");
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
        )}
        <div style={{ padding: "80px 30px" }}>
          <ContinueButton disabled={disableSubmit} onClick={handleSubmit}>Submit</ContinueButton>
        </div>
      </div>
    </Dialog>
  );
};

export default AssigneeModal;
