import React from "react";

export const ThumbnailViewIcon = (props) => {
  const width = props?.width ?? "79";
  const height = props?.height ?? "79";
  const color = props?.color ?? "#827e7e";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M7.02 10H2.98C.97 10 0 9.11 0 7.27V2.73C0 .89.98 0 2.98 0h4.04C9.03 0 10 .89 10 2.73v4.53C10 9.11 9.02 10 7.02 10zM2.98 1.5c-1.34 0-1.48.38-1.48 1.23v4.53c0 .86.14 1.23 1.48 1.23h4.04c1.34 0 1.48-.38 1.48-1.23V2.73c0-.86-.14-1.23-1.48-1.23z"
        transform="translate(12.75 1.25)"
        fill={color}
      />
      <path
        data-name="Vector"
        d="M7.02 10H2.98C.97 10 0 9.02 0 7.02V2.98C0 .97.98 0 2.98 0h4.04C9.03 0 10 .98 10 2.98v4.04c0 2-.98 2.98-2.98 2.98zM2.98 1.5c-1.18 0-1.48.3-1.48 1.48v4.04c0 1.18.3 1.48 1.48 1.48h4.04c1.18 0 1.48-.3 1.48-1.48V2.98c0-1.18-.3-1.48-1.48-1.48z"
        transform="translate(12.75 12.75)"
        fill={color}
      />
      <path
        data-name="Vector"
        d="M7.02 10H2.98C.97 10 0 9.11 0 7.27V2.73C0 .89.98 0 2.98 0h4.04C9.03 0 10 .89 10 2.73v4.53C10 9.11 9.02 10 7.02 10zM2.98 1.5c-1.34 0-1.48.38-1.48 1.23v4.53c0 .86.14 1.23 1.48 1.23h4.04c1.34 0 1.48-.38 1.48-1.23V2.73c0-.86-.14-1.23-1.48-1.23z"
        transform="translate(1.25 1.25)"
        fill={color}
      />
      <path
        data-name="Vector"
        d="M7.02 10H2.98C.97 10 0 9.02 0 7.02V2.98C0 .97.98 0 2.98 0h4.04C9.03 0 10 .98 10 2.98v4.04c0 2-.98 2.98-2.98 2.98zM2.98 1.5c-1.18 0-1.48.3-1.48 1.48v4.04c0 1.18.3 1.48 1.48 1.48h4.04c1.18 0 1.48-.3 1.48-1.48V2.98c0-1.18-.3-1.48-1.48-1.48z"
        transform="translate(1.25 12.75)"
        fill={color}
      />
    </svg>
  );
};
