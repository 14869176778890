import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  brandList: [],
  glassDropDown: {},
  addBrand: {},
  editBrand: {},
  addBrandError: "",
  editBrandError: "",
  pageNoBrand: 0,
  hasMoreBrands: true,
};

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    getBrand() {},
    setBrand(state, action) {
      return Object.assign({}, state, {
        brandList:
          state.pageNoBrand > 0
            ? [...state.brandList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreBrands: action.payload.length < 15 ? false : true,
      });
    },

    getGlassDropDowns() {},
    setGlassDropDowns(state, action) {
      return Object.assign({}, state, {
        glassDropDown: isEmpty(action.payload) ? {} : action.payload,
      });
    },

    getAddBrand() {},
    setAddBrand(state, action) {
      return Object.assign({}, state, {
        addBrand: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    addBrandErrors(state, action) {
      return Object.assign({}, state, {
        addBrandError: Array.isArray(action.payload)
          ? action.payload[0].msg
          : action.payload,
      });
    },

    getEditBrand() {},
    setEditBrand(state, action) {
      return Object.assign({}, state, {
        editBrand: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    editBrandErrors(state, action) {
      return Object.assign({}, state, {
        editBrandError: Array.isArray(action.payload)
          ? action.payload[0].msg
          : action.payload,
      });
    },

    setPagenoBrand(state, action) {
      return Object.assign({}, state, {
        pageNoBrand: action.payload,
      });
    },

    clearBrand(state, action){
      return Object.assign({}, state, initialState);
    }
  },
});

export const {
  getBrand,
  setBrand,
  getGlassDropDowns,
  setGlassDropDowns,
  getAddBrand,
  setAddBrand,
  setEditBrand,
  getEditBrand,
  addBrandErrors,
  editBrandErrors,
  setPagenoBrand,
  clearBrand
} = brandSlice.actions;

export default brandSlice.reducer;
