import React from "react";

export const BoxOutline = (props) => {
    const width = props?.width ?? "24";
    const height = props?.height ?? "24";
    const color = props?.color ?? "#827e7e";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g data-name="vuesax/outline/box">
        <path
          d="M9.581 6.613a.746.746 0 0 1-.38-.1L.371 1.4A.761.761 0 0 1 .1.373.752.752 0 0 1 1.131.1l8.45 4.89 8.4-4.86a.761.761 0 0 1 1.03.27.761.761 0 0 1-.27 1.03l-8.77 5.08a.94.94 0 0 1-.39.103z"
          transform="translate(2.419 6.687)"
          fill={color}

        />
        <path
          data-name="Vector"
          d="M.75 10.57A.755.755 0 0 1 0 9.82V.75A.755.755 0 0 1 .75 0a.755.755 0 0 1 .75.75v9.07a.755.755 0 0 1-.75.75z"
          transform="translate(11.25 11.79)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M10.36 21.5a5.137 5.137 0 0 1-2.44-.57l-5.34-2.97A5.443 5.443 0 0 1 0 13.57V7.92a5.457 5.457 0 0 1 2.58-4.39L7.92.57a5.507 5.507 0 0 1 4.88 0l5.34 2.97a5.443 5.443 0 0 1 2.58 4.39v5.65a5.457 5.457 0 0 1-2.58 4.39l-5.34 2.96a5.137 5.137 0 0 1-2.44.57zm0-20a3.686 3.686 0 0 0-1.71.38L3.31 4.85A4 4 0 0 0 1.5 7.92v5.65a4.036 4.036 0 0 0 1.81 3.08l5.34 2.97a4.014 4.014 0 0 0 3.42 0l5.34-2.97a4.02 4.02 0 0 0 1.81-3.08V7.92a4.036 4.036 0 0 0-1.81-3.08l-5.34-2.97a3.813 3.813 0 0 0-1.71-.37z"
          transform="translate(1.64 1.25)"
          fill={color}
        />
      </g>
    </svg>
  );
};
