import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGlass, getGlassDropDowns, setPageno, clearGlass, getBrandDropDowns } from "../../app/reducers/glassSlice";
import { ContinueButton, ListTable } from "../../components";
import AddGlass from "./AddGlass";
import EditGlass from "./EditGlass";

const Glasses = (props) => {
  
  const dispatch = useDispatch();

  const glassList = useSelector((state) => state.glassReducer.glassList);
  const pageNo = useSelector((state) => state.glassReducer.pageNo);
  const hasMore = useSelector((state) => state.glassReducer.hasMore);

  const glassAdd = useSelector((state) => state.glassReducer.glassAdded);
  const glassEdit = useSelector((state) => state.glassReducer.glassEdited);
  
  const [openAddGlass, setOpenAddGlass] = useState(false);
  const [openEditGlass, setOpenEditGlass] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    dispatch(clearGlass());
    dispatch(getGlass(0));
    dispatch(getGlassDropDowns());
    dispatch(getBrandDropDowns());
  }, [glassAdd, glassEdit]);

  let tableData = [];

  glassList?.map((glass) => {
    const data = {
      brand: glass?.Brand,
      model: glass?.ModelNo,
      colorCode: glass?.ColorCode,
      frameColor: glass?.FrameColor,
      sizeActual: glass?.SizeActual,
      quantity: glass?.Quantity,
      data: glass,
    };
    tableData.push(data);
    return tableData;
  });
  
  useEffect(() => {
    setLoading(false);
  }, [glassList]);

  const addGlass = () => {
    setOpenAddGlass(true);
  }

  const handleBack = () => {
    setOpenAddGlass(false);
    setOpenEditGlass(false);
  }

  const handleViewClick = (e, data) => {
    setRowData(data.data);
    setOpenEditGlass(true);
  }

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPageno(pageNo + 1));
    dispatch(getGlass(pageNo+1));
  }

  return (
    <>
      <div
        style={{
          padding: "90px 24px 40px 26px",
          overflow: "hidden",
        }}
      >
        {openAddGlass ? (<AddGlass handleBack={handleBack} /> ) :
        ( openEditGlass ? <EditGlass handleBack={handleBack} row={rowData} /> : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                padding: "0px 0px 5px 0px",
              }}
            >
              <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
                Glasses
              </div>
              <div style={{ padding: "0px 0px 5px 0px" }}>
                <ContinueButton onClick={addGlass}> Add Glass</ContinueButton>
              </div>
            </div>
            <ListTable
              dataList={tableData}
              handleViewClick={handleViewClick}
              isLoading={loading}
              handleLoadmore={handleLoadmore}
              hasMore={hasMore}
              Header={[
                "Brand",
                "Model",
                "Frame Color",
                "Color Code",
                "Size Actual",
                "Quantity",
                ""
              ]}
              tableData={[
                { type: ["TEXT"], name: "brand" },
                { type: ["TEXT"], name: "model" },
                { type: ["TEXT"], name: "frameColor" },
                { type: ["TEXT"], name: "colorCode" },
                { type: ["TEXT"], name: "sizeActual" },
                { type: ["TEXT"], name: "quantity" },
                { type: ["VIEW"], name: "" },
              ]}
            />
          </>
        ))}
      </div>
    </>
  );
};

export default Glasses;
