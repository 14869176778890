export const RoutePath = {
  indents: "/indents",
  glasses: "/glasses",
  brand: "/brand",
  onboarding: "/onboarding",
  stocks: "/stocks",
  orders: "/orders",
  err: "/404",
  inventory: "/inventory",
  returns: "/returns",
  users: "/users",
  lensCatalog: "/lens-catalog",
  // rangeLensCatalog: "/range-lens-catalog",
  stocksScanAdd: "/stocks-add",
  stores: "/stores",
  storeInventory: "/store-inventory",
  login: "/",
};
