import React from "react";

export const BarCodeIcon = (props) => {
  const width = props?.width ?? "79";
  const height = props?.height ?? "79";
  const color = props?.color ?? "#a0a0a0";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1616"
      height="241"
      viewBox="0 0 1616 241"
    >
      <g data-name="Rectangle 478" stroke="#eaeaea" fill="none">
        <path stroke="none" d="M0 0h1616v241H0z" />
        <path fill="none" d="M.5.5h1615v240H.5z" />
      </g>
    </svg>
  );
};
