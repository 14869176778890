import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  getStocks,
  setStocks,
  getStoreList,
  setStoreList,
  getInventory,
  setInventory,
  setSaveExchange,
  getSaveExchange,
  setShipmentSaveEx,
  getShipmentSaveEx,
  getUpdateStatusEx,
  setUpdateStatusEx,
} from "../../app/reducers/stockTransferSlice";

async function fetchStocksAPI(payload) {
  const indents = await axios
    .get("/client/stocktransfer/fetchstocktransfer", {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { _created_at: -1 },
      },
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchStocks(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchStocksAPI, payload);
    yield put(setStocks(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function getStoreListsAPI(payload) {
  const indents = await axios
    .get("/client/store/storelistst")
    .then((response) => response.data.data);
  return indents;
}
export function* getStoreLists(action) {
  try {
    const { payload } = action;
    const response = yield call(getStoreListsAPI, payload);
    yield put(setStoreList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchInventoryAPI(payload) {
  const indents = await axios
    .get("/client/inventory/barcodeidadmin", {
      params: {
        barcodeId: payload,
      },
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchInventory(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchInventoryAPI, payload);
    yield put(setInventory(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function saveExchangeAPI(payload) {
  const exchanges = axios
    .post("/client/stocktransfer/warehouse", payload)
    .then((response) => response.data.data);
  return exchanges;
}

export function* saveExchange(action) {
  try {
    const { payload } = action;
    const response = yield call(saveExchangeAPI, payload);
    yield put(setSaveExchange(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function updateStatusAPI(payload) {
  const indents = await axios
    .post("/client/stocktransfer/updatedeliverystatus", payload)
    .then((response) => response.data);
  return indents;
}
export function* updateStatus(action) {
  try {
    const { payload } = action;
    const response = yield call(updateStatusAPI, payload);
    yield put(setUpdateStatusEx(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function updateShipmentAPI(payload) {
  const indents = await axios
    .post("/client/stocktransfer/updateshippingstatus", payload)
    .then((response) => response.data);
  return indents;
}
export function* updateShipment(action) {
  try {
    const { payload } = action;
    const response = yield call(updateShipmentAPI, payload);
    const object = {
      message: response,
      data: payload,
    };
    yield put(setShipmentSaveEx(object));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* stockTransferSaga() {
  yield takeLatest(getStocks.type, fetchStocks);
  yield takeLatest(getStoreList.type, getStoreLists);
  yield takeLatest(getInventory.type, fetchInventory);
  yield takeLatest(getSaveExchange.type, saveExchange);
  yield takeLatest(getShipmentSaveEx.type, updateShipment);
  yield takeLatest(getUpdateStatusEx.type, updateStatus);
}
