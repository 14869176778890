import React from "react";

export const RupeeOutline = (props) => {
    const color = props?.color ?? "#827e7e";
    const width = props?.width ?? "21.5";
    const height = props?.height ?? "21.5";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21.5 21.5"
    >
      <g data-name="Group 2158">
        <path
          d="M10.75 21.5a10.75 10.75 0 0 1 0-21.5.755.755 0 0 1 .75.75.755.755 0 0 1-.75.75A9.25 9.25 0 1 0 20 10.75a.75.75 0 0 1 1.5 0A10.759 10.759 0 0 1 10.75 21.5z"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M4.75 5.5h-4A.755.755 0 0 1 0 4.75v-4A.755.755 0 0 1 .75 0a.755.755 0 0 1 .75.75V4h3.25a.75.75 0 0 1 0 1.5z"
          transform="translate(15 1)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M.747 6.5a.742.742 0 0 1-.53-.22.754.754 0 0 1 0-1.06l5-5a.75.75 0 0 1 1.06 1.06l-5 5a.742.742 0 0 1-.53.22z"
          transform="translate(15.002 .002)"
          fill={color}
        />
        <path
          data-name="Path 1"
          d="M99.974 0h-6.449v1.281H95.8a1.925 1.925 0 0 1 1.812 1.281h-4.087v1.282h4.086A1.925 1.925 0 0 1 95.8 5.125h-2.275v1.281l3.755 3.755.906-.906-2.849-2.849h.463a3.209 3.209 0 0 0 3.139-2.562h1.036V2.562h-1.037a3.19 3.19 0 0 0-.578-1.281h1.614z"
          transform="translate(-85.934 6.612)"
          fill={color}
        />
      </g>
    </svg>
  );
};
