import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { RoutePath } from "./routes";
import { NotFound } from "../features/notFound";
import {
  Brand,
  Glasses,
  Indents,
  LensCatalog,
  Onboarding,
  Orders,
  Returns,
  ScanAddStockTransfer,
  StockTransfer,
  RangeLensCatalog
} from "../features";
import User from "../features/Master/User";
import { withDrawer } from "../common/hocs";
import { StoreInventories, Stores } from "../features/Stores";
import { WarehouseLogin } from "../features/WarehouseLogin";

const RouterApp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return isLoggedIn ? (
    <Routes>
      <Route exact path={RoutePath.glasses} element={<Glasses />} />
      <Route exact path={RoutePath.brand} element={<Brand />} />
      <Route exact path={RoutePath.onboarding} element={<Onboarding />} />
      <Route exact path={RoutePath.stocks} element={<StockTransfer />} />
      <Route exact path={RoutePath.indents} element={<Indents />} />
      <Route exact path={RoutePath.orders} element={<Orders />} />
      <Route exact path={RoutePath.users} element={<User />} />
      <Route path={RoutePath.login} element={<WarehouseLogin />} />
      <Route exact path={RoutePath.rangeLensCatalog} element={<RangeLensCatalog />} />
      <Route exact path={RoutePath.lensCatalog} element={<LensCatalog />} />
      <Route exact path={RoutePath.returns} element={<Returns />} />
      <Route path={RoutePath.err} element={<NotFound />} />
      <Route path={RoutePath.inventory} element={<Onboarding />} />
      <Route
        path={RoutePath.stocksScanAdd}
        element={<ScanAddStockTransfer />}
      />
      <Route path={RoutePath.stores} element={<Stores />} />
      <Route path={RoutePath.storeInventory} element={<StoreInventories />} />
    </Routes>
  ) : (
    <Routes>
      <Route path={RoutePath.login} element={<WarehouseLogin />} />
    </Routes>
  );
};

export default withDrawer(RouterApp);
