import { useDispatch } from 'react-redux';
import {BARCODE_SCANNED, BARCODE_SCANNING} from 'react-usb-barcode-scanner';
import { usbScanner } from './reducers/stockTransferSlice';

const barcodeScannerMiddleware = (store) => (next) => (action) => {

  if(action.type === BARCODE_SCANNED){
    const barcode = action.payload.data;
    store.dispatch(usbScanner(barcode));
  }
  // if(action.type === BARCODE_SCANNING){
  //   store.dispatch(isUsbOnScan(true));
  // }
  return next(action);
};

export default barcodeScannerMiddleware;