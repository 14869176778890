import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  getSaveAssignee,
  setSaveAssignee,
  getAssignee,
  setAssignee,
  getSaveAssigneeReturns,
  setSaveAssigneeReturns,
} from "../../app/reducers/assigneeSlice";

async function saveAssigneeAPI(payload) {
  const indents = await axios
    .post("/client/intends/updateassignees", payload)
    .then((response) => response.data);
  return indents;
}
export function* saveAssignee(action) {
  try {
    const { payload } = action;

    const response = yield call(saveAssigneeAPI, payload);
    yield put(setSaveAssignee(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function saveAssigneeReturnsAPI(payload) {
  const indents = await axios
    .post("/client/returns/updateassignees", payload)
    .then((response) => response.data);
  return indents;
}
export function* saveAssigneeReturns(action) {
  try {
    const { payload } = action;

    const response = yield call(saveAssigneeReturnsAPI, payload);
    yield put(setSaveAssigneeReturns(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchAssigneeAPI(payload) {
  const indents = await axios
    .get("/client/user/fetchjoborderusers")
    .then((response) => response.data.data);
  return indents;
}
export function* fetchAssignee(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchAssigneeAPI, payload);
    yield put(setAssignee(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* assigneeSaga() {
  yield takeLatest(getSaveAssignee.type, saveAssignee);
  yield takeLatest(getAssignee.type, fetchAssignee);
  yield takeLatest(getSaveAssigneeReturns.type, saveAssigneeReturns);
}
