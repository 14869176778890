import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ListTable } from "../../components";
import {
  getIndents,
  setIndentData,
  setPagenoIndents,
  clearIndents,
} from "../../app/reducers/indentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import UploadInvoice from "../../components/Modal/UploadInvoice";
import S3 from "react-aws-s3";
import { saveInvoiceDetails } from "../../app/reducers/indentSlice";

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET, //"oicappsv3-dev",
  dirName: "eyeplay",
  region: "ap-south-1",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const ReactS3Client = new S3(config);

function Indents(props) {
  const dispatch = useDispatch();
  const history = useNavigate();

  const indentList = useSelector((state) => state.indentReducer.indentList);
  const pageNo = useSelector((state) => state.indentReducer.pageNoIndents);
  const hasMore = useSelector((state) => state.indentReducer.hasMoreIndents);
  const saveInvoiceResponse = useSelector(state => state.indentReducer.saveInvoiceResponse)

  const [uploadInvoiceModal, setUploadInvoiceModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [gridFilter, setGridFilter] = useState([]);
  const [invoiceDetailsData, setInvoiceDetailsData] = useState({});
  const [error, setError] = useState({});
  const [currentIndent, setCurrentIndent] = useState({});

  useEffect(() => {
    setLoading(false);
  }, [indentList]);

  useEffect(() => {
    dispatch(clearIndents());
    dispatch(getIndents(0));
  }, []);

  let tableData = [];

  const data = indentList?.map((indent) => {
    const indentData = {
      indentId: indent?.intendId,
      indentDate: moment(indent?.intendDate).format("ll"),
      orders: "(" + indent?.completedOrders + "/" + indent?.totalOrders + ")",
      amount: indent?.intendAmount,
      status: indent?.status,
      invoiceDetails: indent?.invoiceDetails
    };
    tableData.push(indentData);

    return tableData;
  });

  const handleViewClick = (e, data) => {
    dispatch(setIndentData(data));
    history("/orders");
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoIndents(pageNo + 1));
    dispatch(getIndents(pageNo + 1));
  };

  const handleChange = (event, value) => {
    setGridFilter(value);
  };

  const handleUploadInvoice = (e, data) => {
    setCurrentIndent(data);
    setUploadInvoiceModal(true)
  }

  const handleClose = () => {
    dispatch(clearIndents())
    setError({});
    dispatch(getIndents(0));
    setUploadInvoiceModal(false)
    setInvoiceDetailsData({});
  }

  useEffect(() => {

  }, [invoiceDetailsData]);

  const handleInputChange = (type, value) => {
    if (type === "invoiceImage") {
      const fileWithPreview = value.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setInvoiceDetailsData({ ...invoiceDetailsData, invoiceImage: fileWithPreview[0] });
      return 1;
    }

    setInvoiceDetailsData({
      ...invoiceDetailsData,
      [type]: value
    })
  }

  useEffect(() => {
    if (saveInvoiceResponse.message) {
      setInvoiceDetailsData({})
    }
  }, [saveInvoiceResponse]);

  const uploadToS3 = async () => {
    const currentTime = Math.floor(Date.now() / 1000);
    return await ReactS3Client.uploadFile(
      invoiceDetailsData?.invoiceImage,
      `warehouseinvoice/indents/${currentTime}`
    );
  };

  const validation = () => {
    let isValid = true;


    let errorData = {}
    if (_.isEmpty(invoiceDetailsData?.invoiceId)) {
      isValid = false;
      errorData = { ...errorData, invoiceId: "Invoice Id is Mandatory" };
    }

    if (_.isEmpty(invoiceDetailsData?.invoiceDate?.toString())) {
      isValid = false;
      errorData = { ...errorData, invoiceDate: "Invoice Date is Mandatory" };
    }

    if (_.isEmpty(invoiceDetailsData?.invoiceImage)) {
      isValid = false;
      errorData = { ...errorData, invoiceImage: "Invoice Image is Mandatory" };
    }

    setError(errorData)

    return isValid;
  }

  const handleAlertClose = () => { }

  const handleSubmit = async () => {
    let isValid = validation();

    if (!isValid) {
      return 1;
    }

    const imageUrl = await uploadToS3();

    if (!imageUrl) {
      return 1;
    }

    let temp = {
      indentId: currentIndent.indentId,
      invoiceImage: imageUrl.location
    }

    let invoiceDetailsDataLocal = { ...invoiceDetailsData }

    invoiceDetailsDataLocal = { ...invoiceDetailsDataLocal, ...temp };

    dispatch(saveInvoiceDetails(invoiceDetailsDataLocal))
  };

  const handleDownload = (e, data) => {

    if (data.invoiceDetails) {
      window.open(data.invoiceDetails.invoiceImage, '_blank');
    }
  }

  return (
    <div
      style={{
        padding: "90px 24px 40px 26px",
        height: "calc(100vh - 3%)",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          aligntItems: "center",
        }}
      >
        <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
          Indent
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            aligntItems: "center",
          }}
        >
          <div style={{ fontSize: 12, color: "#827e7e" }}>INDENT STATUS</div>
          <Autocomplete
            multiple
            id="indent-status"
            options={["In Progress", "Completed", "Paid"]}
            sx={{ width: "100%", paddingBottom: "20px" }}
            onChange={(event, newValue) => {
              handleChange(event, newValue);
            }}
            size="small"
            renderInput={(params) => (
              <TextField
                size="small"
                sx={{ backgroundColor: "#fff", width: "100%", padding: "5px" }}
                {...params}
                variant="standard"
              />
            )}
          />
        </div>
      </div>

      <ListTable
        dataList={tableData}
        handleViewClick={handleViewClick}
        isLoading={loading}
        handleLoadmore={handleLoadmore}
        handleUploadInvoice={handleUploadInvoice}
        hasMore={hasMore}
        filters={gridFilter}
        handleDownload={handleDownload}
        Header={[
          { name: "Indent ID" },
          { name: "Indent Date" },
          { name: "Orders" },
          { name: "Total Amount" },
          { name: "Status" },
          { name: "Actions" },
          { name: "" },
          { name: "" },
        ]}
        tableData={[
          { type: ["TEXT"], name: "indentId" },
          { type: ["TEXT"], name: "indentDate" },
          { type: ["TEXT"], name: "orders" },
          { type: ["TEXT"], name: "amount" },
          { type: ["STATUS"], name: "status" },
          { type: ["VIEW"], name: "" },
          { type: ["UPLOAD_INVOICE"], name: "invoiceDetails" },
          { type: ["DOWNLOAD"], name: "invoiceDetails" },
        ]}
      />

      {
        uploadInvoiceModal && (
          <UploadInvoice
            open={uploadInvoiceModal}
            handleClose={handleClose}
            handleInputChange={handleInputChange}
            invoiceDetails={invoiceDetailsData}
            handleSubmit={handleSubmit}
            error={error}
            currentIndent={currentIndent}
            saveInvoiceResponse={saveInvoiceResponse}
            handleAlertClose={handleAlertClose}
          />
        )
      }
    </div>
  );
}

export default Indents;
