import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  indentList: [],
  orderList: [],
  indentData: {},
  pageNoIndents: 0,
  hasMoreIndents: true,
  saveInvoiceResponse: "",
};

export const indentSlice = createSlice({
  name: "indents",
  initialState,
  reducers: {
    getIndents() {},
    setIndents(state, action) {
      return Object.assign({}, state, {
        indentList:
          state.pageNoIndents > 0
            ? [...state.indentList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreIndents: action.payload.length < 15 ? false : true,
      });
    },
    setPagenoIndents(state, action) {
      return Object.assign({}, state, {
        pageNoIndents: action.payload,
      });
    },

    getIndentOrders() {},
    setIndentOrders(state, action) {
      return Object.assign({}, state, {
        orderList: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    setIndentData(state, action) {
      return Object.assign({}, state, {
        indentData: isEmpty(action.payload) ? {} : action.payload,
      });
    },

    saveInvoiceDetails() {},
    saveInvoiceResponse(state, action) {

      return Object.assign({}, state, {
        saveInvoiceResponse: action.payload
      });
    },

    clearIndents(state, action){
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getIndents,
  setIndents,
  getIndentOrders,
  setIndentOrders,
  setIndentData,
  setPagenoIndents,
  clearIndents,
  saveInvoiceDetails,
  saveInvoiceResponse
} = indentSlice.actions;

export default indentSlice.reducer;
