import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";

const AlertComp = (props) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => handleClose(), 3000);
  })
  
  const handleClose = () => {
    setOpen(false);
    props?.handleAlertClose();
  }

  return (
    <>
      {open
        ? <Alert
              severity={props.type}
              variant="filled"
              onClose={() => handleClose()}
            >
              {props.message}
            </Alert>
        : ""}
    </>
  );
};

export default AlertComp;
