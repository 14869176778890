import React, { useEffect, useState } from "react";
import {
  ContinueButton,
  ListTable,
  ListViewIcon,
  ThumbnailViewIcon,
} from "../../components";
import {
  getGroupedLens,
  setPagenoLens,
  getLensListView,clearLens,
} from "../../app/reducers/lensCatalogSlice";
import { useDispatch, useSelector } from "react-redux";
import { getGlassDropDowns } from "../../app/reducers/glassSlice";
import NewCatalog from "./NewCatalog";
import NewRangeCatalog from "./NewRangeCatalog"
import _ from "lodash";
import EditCatalog from "./EditCatalog";

const LensCatalog = (props) => {
  const [openAddCatalog, setOpenAddCatalog] = useState(false);
  const [editCatalog, setEditCatalog] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState(false);

  const [openRangeCatalog, setOpenRangeCatalog] = useState(false);

  const dispatch = useDispatch();

  const lensAdded = useSelector((state) => state.lensCatalogReducer.lensAdded);

  const lensEdited = useSelector(
    (state) => state.lensCatalogReducer.lensEdited
  );
  const pageNo = useSelector((state) => state.lensCatalogReducer.pageNoLens);
  const hasMore = useSelector((state) => state.lensCatalogReducer.hasMoreLens);

  const groupedLenses = useSelector(
    (state) => state.lensCatalogReducer.lensGrid
  );
  const listViewDetails = useSelector(
    (state) => state.lensCatalogReducer.lensListView
  );

  useEffect(() => {
    dispatch(getGlassDropDowns());
  }, []);

  useEffect(() => {
    dispatch(getLensListView(0));
    dispatch(getGroupedLens());
    dispatch(clearLens());
  }, [lensAdded, lensEdited]);

  useEffect(() => {
    setLoading(false);
  }, [listViewDetails]);

  let listTable = [];

  listViewDetails?.map((list) => {
    const listData = {
      lensType: list?.lensType,
      brand: list?.brand,
      lensCategory: list?.lensCategory || "-",
      coatings: list?.lensCoating,
      index: list?.index,
      costPrice: list?.costPrice,
      sellPrice: list?.sellPrice,
      data: list,
    };
    listTable.push(listData);
    return listData;
  });

  let tableData = [];

  const data = groupedLenses?.map((groupedLens) => {
    const groupedLensData = {
      lensType: groupedLens?.lensType,
      lensTypeRowSpan: groupedLens?.lensCategories.length,
      model: [],
      coatings: [],
    };
    const model = groupedLens?.lensCategories.map((category) => {
      groupedLensData?.model.push(category.lensCategory);
      groupedLensData.modelRowSpan = category.lensCoatings.length;
      groupedLensData.coatings.push(
        category.lensCoatings.map((coating) => {
          return coating.lensCoating;
        })
      );
    });
    tableData.push(groupedLensData);
  });


  const addCatalog = () => {
    setOpenAddCatalog(true);
  };

  const addRangeCatalog = () => {
    setOpenRangeCatalog(true)
  }

  const handleBack = () => {
    setOpenAddCatalog(false);
    setEditCatalog(false);
    setOpenRangeCatalog(false);
  };

  const handleThumbnail = () => {
    setShowThumbnail(true);
  };
  const handleList = () => {
    setShowThumbnail(false);
  };

  const handleViewClick = (e, data) => {
    setRowData(data.data);
    setEditCatalog(true);
  };
  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoLens(pageNo + 1));
    dispatch(getLensListView(pageNo + 1));
  };
  return (
    <>
      <div
        style={{
          padding: "90px 24px 40px 26px",
          overflow: "hidden",
        }}
      >
        {openRangeCatalog ? (
              <NewRangeCatalog
                handleBack={handleBack}
              />
          ): openAddCatalog ? (
          <NewCatalog handleBack={handleBack} />
        ) : editCatalog ? (
          <EditCatalog handleBack={handleBack} row={rowData} />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                padding: "0px 10px 5px 0px",
              }}
            >
              <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
                Lens Catalog
              </div>
              <div
                style={{
                  display: "flex",
                  aligntItems: "center",
                  padding: "0px 0px 5px 0px",
                }}
              >
                <div
                  style={{ padding: "0px 0px 0px 10px" }}
                  onClick={handleList}
                >
                  <ListViewIcon color={showThumbnail ? "#827e7e" : "#03a5a5"} />
                </div>
                <div
                  style={{ padding: "0px 0px 0px 10px" }}
                  onClick={handleThumbnail}
                >
                  <ThumbnailViewIcon
                    color={showThumbnail ? "#03a5a5" : "#827e7e"}
                  />{" "}
                </div>
                <ContinueButton
                  sx={{
                    fontSize: "13px",
                    minWidth: "45px",
                    marginBottom: "5px",
                  }}
                  onClick={addCatalog}
                >
                  New Catalog
                </ContinueButton>
                <ContinueButton
                  sx={{
                    fontSize: "13px",
                    minWidth: "45px",
                    marginBottom: "5px",
                  }}
                  onClick={addRangeCatalog}
                >
                  New Range Catalog
                </ContinueButton>
              </div>
            </div>
            {showThumbnail ? (
              <ListTable
                dataList={tableData}
                Header={["Lens Type", "Model", "Coating", "Index"]}
                paddingLeft="100px"
                tableData={[
                  {
                    type: ["TEXT"],
                    name: "lensType",
                    rowSpan: "lensTypeRowSpan",
                  },
                  { type: ["ARRAY"], name: "model", rowSpan: "modelRowSpan" },
                  { type: ["ARRAY"], name: "coatings" },
                  { type: ["TEXT"], name: "index" },
                ]}
              />
            ) : (
              <ListTable
                dataList={listTable}
                isLoading={loading}
                handleLoadmore={handleLoadmore}
                handleViewClick={handleViewClick}
                hasMore={hasMore}
                Header={[
                  "Lens Type",
                  "Brand",
                  "Lens Category",
                  "Coating",
                  "Index",
                  "Cost Price",
                  "Sell Price",
                  "Actions",
                ]}
                paddingLeft="100px"
                tableData={[
                  { type: ["TEXT"], name: "lensType" },
                  { type: ["TEXT"], name: "brand" },
                  { type: ["TEXT"], name: "lensCategory" },
                  { type: ["TEXT"], name: "coatings" },
                  { type: ["TEXT"], name: "index" },
                  { type: ["TEXT"], name: "costPrice" },
                  { type: ["TEXT"], name: "sellPrice" },
                  { type: ["VIEW"], name: "" },
                ]}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default LensCatalog;
