import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  barcodeList: [],
  updatedBarcodes: [],
  onBoardingList: [],
  pageNoOnboard: 0,
  hasMoreOnboard: true,
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    getBarcodes() {},
    setBarcodes(state, action) {
      return Object.assign({}, state, {
        barcodeList: isEmpty(action.payload) ? [] : action.payload,
      });
    },

    getUpdateBarcodeStatus() {},
    setUpdateBarcodeStatus(state, action) {
      return Object.assign({}, state, {
        updatedBarcodes: isEmpty(action.payload) ? [] : action.payload,
      });
    },

    getOnboarding() {},
    setOnboarding(state, action) {
      return Object.assign({}, state, {
        onBoardingList:
          state.pageNoOnboard > 0
            ? [...state.onBoardingList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreOnboard: action.payload.length < 15 ? false : true,
      });
    },

    setPagenoOnboarding(state, action) {
      return Object.assign({}, state, {
        pageNoOnboard: action.payload,
      });
    },
    
    clearOnboarding(state, action) {
      return Object.assign({}, state, initialState);
    }
  },
});

export const {
  getBarcodes,
  setBarcodes,
  getUpdateBarcodeStatus,
  setUpdateBarcodeStatus,
  getOnboarding,
  setOnboarding,
  setPagenoOnboarding,
  clearOnboarding
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
