import React, { useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  IconButton,
  StepConnector,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CircleTick, StyledTypography } from "../../components";
import _ from "lodash";
import { useStyles } from "./styles";

const StepperComponent = (props) => {
  const classes = useStyles();

 

  let isLensIncluded =
    Object.keys(props.data.assignee).length < 5 ? false : true;



  const getActiveStepReturns = (data) => {
    let step;
    if (isLensIncluded) {
      if (data === "Created" || data === "JOB ORDER ASSIGNED") {
        step = 0;
      } else if (data === "Shipped From Store") {
        step = 1;
      } else if (data === "Delivered To Warehouse") {
        step = 2;
      } else if (data === "Pick Up") {
        step = 3;
      } else if (data === "Fitted") {
        step = 4;
      } else if (data === "Job Order QC Passed") {
        step = 5;
      } else if (data === "Job Order QC Failed") {
        step = 4;
      } else if (data === "Packed") {
        step = 6;
      } else if (data === "Shipped From Warehouse") {
        step = 7;
      } else if (data === "Delivered To Store") {
        step = 8;
      } else if (data === "QC Failed") {
        step = 8;
      } else if (data === "QC Passed") {
        step = 8;
      } 
      else {
        step = 0;
      }
    } else {
      if (data === "Created" || data === "JOB ORDER ASSIGNED") {
        step = 0;
      } else if (data === "Shipped From Store") {
        step = 1;
      } else if (data === "Delivered To Warehouse") {
        step = 2;
      } else if (data === "Job Order QC Passed") {
        step = 3;
      } else if (data === "Job Order QC Failed") {
        step = 2;
      } else if (data === "Packed") {
        step = 4;
      } else if (data === "Shipped From Warehouse") {
        step = 5;
      } else if (data === "Delivered To Store") {
        step = 6;
      } else if (data === "QC Failed") {
        step = 6;
      } else if (data === "QC Passed") {
        step = 6;
      } 
      else {
        step = 0;
      }
    }

    return step;
  };

  const getActiveStep = (data) => {
    let step;
    if (isLensIncluded) {
      if (data === "Created" || data === "JOB ORDER ASSIGNED") {
        step = 0;
      } else if (data === "Pick Up") {
        step = 1;
      } else if (data === "Fitted") {
        step = 2;
      } else if (data === "QC Passed") {
        step = 3;
      } else if (data === "QC Failed") {
        step = 2;
      } else if (data === "Packed") {
        step = 4;
      } else if (data === "Shipped") {
        step = 5;
      } else if (data === "Delivered") {
        step = 6;
      } else if (data === "Completed") {
        step = 6;
      } else if (data === "Intend Closed") {
        step = 6;
      } else {
        step = 0;
      }
    } else {
      if (data === "QC Passed") {
        step = 1;
      } else if (data === "QC Failed") {
        step = 0;
      } else if (data === "Packed") {
        step = 2;
      } else if (data === "Shipped") {
        step = 3;
      } else if (data === "Delivered") {
        step = 4;
      } else if (data === "Completed") {
        step = 4;
      } else if (data === "Intend Closed") {
        step = 4;
      } else {
        step = 0;
      }
    }

    return step;
  };

  const activeStep = props.fromReturns
    ? getActiveStepReturns(props.data.status)
    : getActiveStep(props.data.status);

  const stepsWithLens = [
    {
      name: "PRODUCT PICKUP",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : isLensIncluded
        ? props.data.assignee.pickup.name
        : "",
    },
    {
      name: "FITTED",
      assignee:
        _.isEmpty(props.data.assignee) && isLensIncluded
          ? "yet to be assigned"
          : isLensIncluded
          ? props.data.assignee.fitter.name
          : "",
    },
    {
      name: "QC",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.qc.name,
    },
    {
      name: "PACKED",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.packing.name,
    },
    {
      name: "SHIPPED",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.shipping.name,
    },
    { name: "DELVIERED", assignee: "-" },
  ];

  const stepsWithoutLens = [
    {
      name: "QC",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.qc.name,
    },
    {
      name: "PACKED",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.packing.name,
    },
    {
      name: "SHIPPED",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.shipping.name,
    },
    { name: "DELVIERED", assignee: "-" },
  ];

  const stepsWithLensReturns = [
    {
      name: "SHIPPED FROM STORE",
      assignee: "-",
    },
    {
      name: "DELIVERED TO WAREHOUSE",
      assignee: "-",
    },
    {
      name: "PRODUCT PICKUP",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : isLensIncluded
        ? props.data.assignee.pickup.name
        : "",
    },
    {
      name: "FITTED",
      assignee:
        _.isEmpty(props.data.assignee) && isLensIncluded
          ? "yet to be assigned"
          : isLensIncluded
          ? props.data.assignee.fitter.name
          : "",
    },
    {
      name: "QC",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.qc.name,
    },
    {
      name: "PACKED",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.packing.name,
    },
    {
      name: "SHIPPED FROM WAREHOUSE",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.shipping.name,
    },
    { name: "DELVIERED TO STORE", assignee: "-" },
  ];

  const stepsWithoutLensReturns = [
    {
      name: "SHIPPED FROM STORE",
      assignee: "-",
    },
    {
      name: "DELIVERED TO WAREHOUSE",
      assignee: "-",
    },
    {
      name: "QC",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.qc.name,
    },
    {
      name: "PACKED",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.packing.name,
    },
    {
      name: "SHIPPED FROM WAREHOUSE",
      assignee: _.isEmpty(props.data.assignee)
        ? "yet to be assigned"
        : props.data.assignee.shipping.name,
    },
    { name: "DELVIERED TO STORE", assignee: "-" },
  ];

  let steps = [];

  if (props.fromReturns) {
    steps = isLensIncluded ? stepsWithLensReturns : stepsWithoutLensReturns;
  } else {
    steps = isLensIncluded ? stepsWithLens : stepsWithoutLens;
  }


  return (
    <Box sx={{ width: "100%" }}>
      <Dialog maxWidth={false} open={props.open} onClose={props.handleClose}>
        <div style={{ width: "1187px", height: "440px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              aligntItems: "center",
              padding: "0px 10px",
            }}
          >
            <StyledTypography
              sx={{ margin: "20px 60px 30px 11px" }}
              color={"#2b2b2b"}
              size={18}
              height={1.28}
              weight={700}
            >
              {props?.fromReturns ? "Returns Status" : "Order Status"}
            </StyledTypography>
            <div>
              <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "#242424",
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "30px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "0px 20px",
              }}
            >
              {props?.fromReturns ? (
                <>
                  <StyledTypography>RETURN ID/DATE:</StyledTypography>
                  <StyledTypography color={"#2b2b2b"}>
                    {props?.returnDetails?.returnData}
                  </StyledTypography>
                </>
              ) : (
                <>
                  <StyledTypography>ORDER ID/DATE:</StyledTypography>
                  <StyledTypography color={"#2b2b2b"}>
                    {props?.orderDetails?.orderId}
                  </StyledTypography>
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingRight: "30px",
              }}
            >
              {props?.fromReturns ? (
                <>
                  <StyledTypography>STORE ID</StyledTypography>
                  <StyledTypography color={"#2b2b2b"}>
                    {props?.returnDetails?.storeId}
                  </StyledTypography>
                </>
              ) : (
                <>
                  <StyledTypography>JOB ORDER ID: </StyledTypography>{" "}
                  <StyledTypography color={"#2b2b2b"}>
                    {props?.orderDetails?.jobOrderId}
                  </StyledTypography>
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingRight: "30px",
              }}
            >
              {props?.fromReturns ? (
                <>
                  <StyledTypography>ORDER ID/DATE</StyledTypography>
                  <StyledTypography color={"#2b2b2b"}>
                    {props?.returnDetails?.orderId}
                  </StyledTypography>
                </>
              ) : (
                <>
                  <StyledTypography>STORE ID: </StyledTypography>

                  <StyledTypography color={"#2b2b2b"}>
                    {props?.orderDetails?.storeId}
                  </StyledTypography>
                </>
              )}
            </div>
          </div>

          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label.name}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      active: classes.icon,
                      completed: classes.icon,
                    },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        color: "#03a5a5",
                        fontSize: "13px",
                        lineHeight: "1.67",
                        fontWeight: 700,
                      }}
                    >
                      {label.name}
                    </div>
                    <div
                      style={{
                        color: "#000",
                        fontSize: "10px",
                        lineHeight: "1.67",
                        fontWeight: 600,
                      }}
                    >
                      {label.assignee}
                    </div>
                  </div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </Dialog>
    </Box>
  );
};

export default StepperComponent;
