import React, { useEffect, useState } from "react";
import RouterApp from "./router";
import { BrowserRouter as Router} from "react-router-dom";
import { WarehouseLogin } from "./features/WarehouseLogin";


function App() {
  return (
    <Router>
      <RouterApp />
    </Router>
  );
}

export default App;
