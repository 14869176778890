import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import { getIndents, setIndents, getIndentOrders, setIndentOrders, saveInvoiceDetails, saveInvoiceResponse } from "../../app/reducers/indentSlice";
import { getAssignee } from "../../app/reducers/assigneeSlice";

async function fetchIndentsAPI(payload) {
  const indents = await axios
    .get('/client/intends/fetchintends', {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { "intendDate": -1 },
        search: "",
      },
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchIndents(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchIndentsAPI, payload);
    yield put(setIndents(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchIndentOrderAPI(payload) {
  const indents = await axios
    .get('/client/intends/fetchintenddetails', {
      params: {
        intendId: payload,
      },
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchIndentOrder(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchIndentOrderAPI, payload);
    yield put(setIndentOrders(response));
    yield put(getAssignee());
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function saveInvoiceAPI(payload) {
  const data = axios
    .post('/client/intends/updateinvoice', payload)
    .then((response) => response);

  return data;
}

export function* gSaveInvoiceDetails(action) {
  try {
    const { payload } = action;
    const response = yield call(saveInvoiceAPI, payload);
    yield put(saveInvoiceResponse({
      message: response.data.message
    }));
  } catch (error) {
    yield put(saveInvoiceResponse({
      error: error.message
    }));
  }
}

export function* indentSaga() {
  yield takeLatest(getIndents.type, fetchIndents);
  yield takeLatest(getIndentOrders.type, fetchIndentOrder);
  yield takeLatest(saveInvoiceDetails.type, gSaveInvoiceDetails)
}
