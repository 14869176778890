import React, { useRef } from "react";
import {
  Dialog,
  IconButton,
  Grid,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ContinueButton, StyledTypography } from "../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewDetailTable from "./ViewDetailTable";
import { useReactToPrint } from "react-to-print";
import _ from "lodash";
import "./printAll.css";

const JobCard = (props) => {
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });


  return (
    <Dialog fullWidth open={props.open} onClose={props.handleClose} >
      <div ref={printRef}>
        {props.orderDetails.map((order) => {
          return (
            <div style={{ minHeight: "793px"}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%", padding: "10px" }}>
                  <StyledTypography
                    color={"#2b2b2b"}
                    weight={600}
                    sx={{
                      borderBottom: "1px solid #000",
                      borderTop: "1px solid #000",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    EYEPLAY RETAIL LLP
                  </StyledTypography>
                </div>
                <div style={{ width: "100%", padding: "10px" }}>
                  <StyledTypography
                    color={"#2b2b2b"}
                    weight={600}
                    sx={{ width: "100%", textAlign: "center" }}
                  >
                    JOB CARD
                  </StyledTypography>
                </div>

                <div style={{ width: "100%", padding: "0px 20px 0px 20px" }}>
                  <StyledTypography color={"#2b2b2b"} weight={600}>
                    LOFTY OPTICAL INDUSTRIES
                  </StyledTypography>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 20px 0px 20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <StyledTypography>Store:</StyledTypography>

                    <StyledTypography color={"#2b2b2b"} weight={700}>
                      {order.storeId}
                    </StyledTypography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <StyledTypography>Indent:</StyledTypography>

                    <StyledTypography color={"#2b2b2b"} weight={700}>
                      {order.intendId}
                    </StyledTypography>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 20px 0px 20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <StyledTypography>Order:</StyledTypography>
                    <StyledTypography color={"#2b2b2b"} weight={700}>
                      {order.orderId}
                    </StyledTypography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <StyledTypography>Job Id:</StyledTypography>
                    <StyledTypography color={"#2b2b2b"} weight={700}>
                      {order.jobOrderId}
                    </StyledTypography>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "10px 20px",
                }}
              >
                <StyledTypography sx={{ paddingRight: "2px" }}>
                  Frame Info:
                </StyledTypography>
                <StyledTypography color={"#2b2b2b"} weight={600}>
                  {order?.frame}
                </StyledTypography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "0px 20px",
                }}
              >
                <StyledTypography sx={{ paddingRight: "2px" }}>
                  Lens Info:
                </StyledTypography>
                <StyledTypography color={"#2b2b2b"} weight={600}>
                  {order?.lens}
                </StyledTypography>
              </div>
              {_.isEmpty(order.prescription) ? (
                <div></div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "0px 20px",
                  }}
                >
                  <ViewDetailTable
                    heading={"Prescription"}
                    prescriptionData={order.prescription}
                  />
                </div>
              )}
              {_.isEmpty(order.frameSize) ? (
                <div></div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "0px 20px",
                  }}
                >
                  <ViewDetailTable
                    heading={"Frame"}
                    frameData={order.frameSize}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 20px 10px 20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StyledTypography color={"#2b2b2b"} weight={700}>
                    Fitting:
                  </StyledTypography>
                  <StyledTypography
                    color={"#2b2b2b"}
                    weight={700}
                    sx={{ marginLeft: "10px" }}
                  >
                    __________________________
                  </StyledTypography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StyledTypography color={"#2b2b2b"} weight={700}>
                    Time:
                  </StyledTypography>
                  <StyledTypography
                    color={"#2b2b2b"}
                    weight={700}
                    sx={{ marginLeft: "10px" }}
                  >
                    _________________
                  </StyledTypography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 20px 10px 20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StyledTypography color={"#2b2b2b"} weight={700}>
                    QC:
                  </StyledTypography>
                  <StyledTypography
                    color={"#2b2b2b"}
                    weight={700}
                    sx={{ marginLeft: "10px" }}
                  >
                    _____________________________
                  </StyledTypography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StyledTypography color={"#2b2b2b"} weight={700}>
                    Time:
                  </StyledTypography>
                  <StyledTypography
                    color={"#2b2b2b"}
                    weight={700}
                    sx={{ marginLeft: "10px" }}
                  >
                    _________________
                  </StyledTypography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "10px 20px 10px 20px",
                }}
              >
                <StyledTypography color={"#2b2b2b"} weight={700}>
                  Remarks:
                </StyledTypography>
                <StyledTypography
                  color={"#2b2b2b"}
                  weight={700}
                  sx={{ marginLeft: "10px" }}
                >
                  ________________________
                </StyledTypography>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "15px 20px 20px 0px",
        }}
      >
        <ContinueButton onClick={handlePrint}>Print All Job Card</ContinueButton>
      </div>
    </Dialog>
  );
};

export default JobCard;
