import React, { useEffect } from "react";
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Dialog,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledTypography } from "../../../components";
import _ from "lodash";
import { useStyles } from "./styles";
import moment from "moment";

const StepperComponent = (props) => {
    const classes = useStyles();

    const getActiveStep = (data) => {
        let step;
        if (data === "Created") {
            step = 1;
        }
        if (data === "Shipped") {
            step = 2;
        }

        if (data === "Delivered") {
            step = 3;
        }

        return step;
    };

    const activeStep = getActiveStep(props.data.status);

    const steps = [
        {
            name: "Created",
            date: moment(props.data?._created_at).format("ll")
        },
        {
            name: "Shipped",
            date: props.data?.shippingDetails && moment(props.data?.shippingDetails?.shippingDate).format("ll"),
            shippingInfo: props.data?.shippingDetails ? props.data?.shippingDetails : false
        },
        {
            name: "Delivered",
            date: props.data?.deliveryDetails && moment(props.data?.deliveryDetails?.deliveryDate).format("ll"),
            deliveryInfo: props.data?.deliveryDetails ? props.data?.deliveryDetails : {}
        }
    ];

    return (
        <Box sx={{ width: "100%" }}>
            <Dialog maxWidth={false} open={props.open} onClose={props.handleClose}>
                <div style={{ width: "1187px", height: "440px" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            aligntItems: "center",
                            padding: "0px 10px",
                        }}
                    >
                        <StyledTypography
                            sx={{ margin: "20px 60px 30px 11px" }}
                            color={"#2b2b2b"}
                            size={18}
                            height={1.28}
                            weight={700}
                        >
                            Stock Transfer Status
                        </StyledTypography>
                        <div>
                            <IconButton
                                aria-label="close"
                                onClick={props.handleClose}
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: "#242424",
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: "30px 10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                padding: "0px 20px",
                            }}
                        >
                            <>
                                <StyledTypography>TRANSFER ID/DATE:</StyledTypography>
                                <StyledTypography color={"#2b2b2b"}>
                                    {props.data?.transferId} / {moment(props.data?._created_at).format("ll")}
                                </StyledTypography>
                            </>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                paddingRight: "30px",
                            }}
                        >
                            <>
                                <StyledTypography>STORE ID: </StyledTypography>

                                <StyledTypography color={"#2b2b2b"}>
                                    {props.data?.destination?.storeId}
                                </StyledTypography>
                            </>
                        </div>
                    </div>

                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label.name}>
                                <StepLabel
                                    StepIconProps={{
                                        classes: {
                                            active: classes.icon,
                                            completed: classes.icon,
                                        },
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: "#03a5a5",
                                                fontSize: "13px",
                                                lineHeight: "1.67",
                                                fontWeight: 700,
                                            }}
                                        >
                                            {label.name}
                                        </div>
                                        <div
                                            style={{
                                                color: "#000",
                                                fontSize: "10px",
                                                lineHeight: "1.67",
                                                fontWeight: 600,
                                            }}
                                        >
                                            {label.date}
                                        </div>
                                        {label.shippingInfo && (
                                            <div
                                                style={{
                                                    color: "#000",
                                                    fontSize: "10px",
                                                    lineHeight: "1.67",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {`Service Provider: ${label.shippingInfo.serviceProvider}`}
                                                <br />
                                                {`Tracking Id: ${label.shippingInfo.trackingId}`}
                                            </div>
                                        )}
                                    </div>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            </Dialog>
        </Box>
    );
};

export default StepperComponent;
