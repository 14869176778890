import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  getBarcodes,
  setBarcodes,
  getUpdateBarcodeStatus,
  setUpdateBarcodeStatus,
  getOnboarding,
  setOnboarding
} from "../../app/reducers/onboardingSlice";

async function fetchBarcodesAPI(payload) {
  const indents = await axios
    .get('/client/inventory/inventorybarcodeid', {
      params: {
        product_id: payload,
      }
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchBarcodes(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchBarcodesAPI, payload);
    yield put(setBarcodes(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function updateBarcodeStatusAPI(payload) {
  const indents = await axios
    .post('/client/inventory/barcodeupdate', payload)
    .then((response) => response.data.data);
  return indents;
}
export function* updateBarcodeStatus(action) {
  try {
    const { payload } = action;
    const response = yield call(updateBarcodeStatusAPI, payload);
    yield put(setUpdateBarcodeStatus(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchOnboardingAPI(payload) {
  const indents = await axios
    .get('/client/inventory/onboardingproducts', {
      params: {
        limit: 15,
        page: payload,
        storeId: "All",
        search: "",
        filter: {},
        sort: { _created_at: -1 },
      }
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchOnboarding(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchOnboardingAPI, payload);
    yield put(setOnboarding(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* onboardingSaga() {
  yield takeLatest(getBarcodes.type, fetchBarcodes);
  yield takeLatest(getUpdateBarcodeStatus.type, updateBarcodeStatus);
  yield takeLatest(getOnboarding.type, fetchOnboarding);
}
