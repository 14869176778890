import React, { useState } from "react";
import {
    ContinueButton,
  } from "../../components";
import _ from "lodash";
import NewRangeCatalog from "./NewRangeCatalog";

const RangeLensCatalog = (props) => {
    const [currentPage, setCurrentPage] = useState("ListLensCatalog");

    const newCatalog = () => {
        setCurrentPage("NewRangeCatalog");        
    }

    const handleBack = () => {
        setCurrentPage("ListLensCatalog")
    }

    return (
        <>
            <div
                style={{
                    padding: "90px 24px 40px 26px",
                    overflow: "hidden",
                }}
            >
                {currentPage === "NewRangeCatalog" && (
                    <NewRangeCatalog handleBack={handleBack} />
                )}

                {currentPage === "ListLensCatalog" && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            aligntItems: "center",
                            padding: "0px 10px 5px 0px",
                        }}
                    >
                        <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
                            Range Lens Catalog
                        </div>
                        <ContinueButton
                            sx={{
                                fontSize: "13px",
                                minWidth: "45px",
                                marginBottom: "5px",
                            }}
                            onClick={newCatalog}
                        >
                            New Range Catalog
                        </ContinueButton>
                    </div>
                )}
            </div>
        </>
    );
};

export default RangeLensCatalog;
