import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  getStores,
  getStoresSuccess,
  getStoreInventoriesSuccess,
  getStoreInventories,
} from "../../app/reducers/storeSlice";

async function fetchStoresAPI(payload) {
  const indents = await axios
    .get('/client/inventory/storeswithinventory', {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { _created_at: -1 },
        search: "",
      }
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchStores(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchStoresAPI, payload);
    yield put(getStoresSuccess(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchStoreInventoryAPI(payload) {
  const indents = await axios
    .get('/client/inventory/storewiseinventory', {
      params: {
        limit: 15,
        page: payload.page,
        filter: {
          storeId: payload.storeId,
        },
        sort: { _created_at: -1 },
        search: "",
      }
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchStoreInventory(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchStoreInventoryAPI, payload);
    yield put(getStoreInventoriesSuccess(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* storeSaga() {
  yield takeLatest(getStores.type, fetchStores);
  yield takeLatest(getStoreInventories.type, fetchStoreInventory);
}
