import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  returnsList: [],
  updatedStatus: {},
  deliveryStatus: {},
  shipmentStatus: {},
  pageNoReturns: 0,
  hasMoreReturns: true,
};

export const returnsSlice = createSlice({
  name: "returns",
  initialState,
  reducers: {
    getReturns() {},
    setReturns(state, action) {
      return Object.assign({}, state, {
        returnsList:  state.pageNoReturns > 0
        ? [...state.returnsList, ...action.payload]
        : isEmpty(action.payload)
        ? []
        : action.payload,
        hasMoreReturns: action.payload.length < 15 ? false : true,
      });
    },
    getUpdateStatusReturns() {},
    setUpdateStatusReturns(state, action) {
      return Object.assign({}, state, {
        updatedStatus: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getShipmentSaveReturns() {},
    setShipmentSaveReturns(state, action) {
      return Object.assign({}, state, {
        shipmentStatus: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getDelivery() {},
    setDelivery(state, action) {
      return Object.assign({}, state, {
        deliveryStatus: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    
    setPagenoReturns(state, action) {
      return Object.assign({}, state, {
        pageNoReturns: action.payload,
      });
    },
    clearReturns(state, action) {
      return Object.assign({}, state, initialState);
    }

  },
});

export const {
  getReturns,
  setReturns,
  setUpdateStatusReturns,
  getUpdateStatusReturns,
  getShipmentSaveReturns,
  setShipmentSaveReturns,
  getDelivery,
  setDelivery,
  setPagenoReturns,
  clearReturns
} = returnsSlice.actions;

export default returnsSlice.reducer;
