import React, { useState, useEffect } from "react";
import { Grid, Autocomplete, TextField, InputAdornment } from "@mui/material";
import {
  ContinueButton,
  StyledTypography,
  BackIcon,
  ContinueShoppingButton,
} from "../../components";
import { State, City } from "country-state-city";
import { updateUsers, updateUsersError } from "../../app/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { AlertComp } from "../../components/Alert";

const roleOptions = [
  { name: "Pick Up" },
  { name: "Fitter" },
  { name: "QC" },
  { name: "Shipping" },
  { name: "Packaging" },
];

const EditUser = (props) => {
  const dispatch = useDispatch();

  const userEdited = useSelector((state) => state.userReducer.userEdited);
  const userEditedError = useSelector(
    (state) => state.userReducer.userEditedError
  );

  const [userValue, setUserValue] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const state = State.getStatesOfCountry("IN");
  const cities = City.getCitiesOfState("IN", selectedState);

  const [enableEdit, setEnableEdit] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(userEdited)) {
      props.handleBack();
    }
  });

  const handleBack = () => {
    props.handleBack();
  };

  const handleCancel = () => {
    setEnableEdit(false);
  };

  const handleChange = (event, value, name) => {
    if (name === "state") {
      setSelectedState(value.isoCode);
    }
    if (value) {
      setUserValue({ ...userValue, [name]: value.name });
    } else {
      setUserValue({ ...userValue, [name]: event.target.value });
    }
  };

  const handleUserSubmit = () => {
    const obj = {
      _id: props?.row?._id,
      ...userValue,
    };
    dispatch(updateUsers(obj));
  };

  const handleAlertClose = () => {
    dispatch(updateUsersError(""));
  };

  const roleValue = {
    name: props.row?.userType,
  };

  const stateValue = {
    name: props.row?.state,
  };
  const cityValue = {
    name: props.row?.city,
  };

  return (
    <>
      {userEditedError ? (
        <AlertComp
          type="error"
          message={userEditedError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          aligntItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            aligntItems: "center",
          }}
        >
          <div onClick={handleBack}>
            <BackIcon />
          </div>
          <StyledTypography
            sx={{ padding: "0px 0px 0px 10px" }}
            color={"#03a5a5"}
            size={22}
            height={1.23}
            weight={600}
          >
            VIEW USER
          </StyledTypography>
        </div>
        <div>
          <ContinueButton onClick={() => setEnableEdit(true)}>
            Edit User
          </ContinueButton>
        </div>
      </div>

      <Grid container>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                USER NAME
              </StyledTypography>
              <TextField
                required
                fullWidth
                defaultValue={props.row?.name}
                disabled={!enableEdit}
                onChange={(event) => handleChange(event, "", "name")}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                USER ROLE
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={roleOptions}
                defaultValue={roleValue}
                disabled={!enableEdit}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "userType")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                PHONE NUMBER
              </StyledTypography>
              <TextField
                fullWidth
                defaultValue={props.row?.phoneNumber}
                disabled={!enableEdit}
                onChange={(event) => {
                  handleChange(event, "", "phoneNumber");
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                STATE
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={state}
                defaultValue={stateValue}
                disabled={!enableEdit}
                getOptionLabel={(option) => option.name}
                placeholder="Select"
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "state");
                }}
                size="medium"
                renderInput={(params) => (
                  <TextField
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                CITY
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={cities}
                defaultValue={cityValue}
                disabled={!enableEdit}
                placeholder="Select"
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "city");
                }}
                size="medium"
                renderInput={(params) => (
                  <TextField
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                ADDRESS
              </StyledTypography>
              <TextField
                onChange={(event) => {
                  handleChange(event, "", "address");
                }}
                defaultValue={props.row?.address}
                disabled={!enableEdit}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={4} lg={4} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                EMAIL ADDRESS
              </StyledTypography>
              <TextField
                fullWidth
                defaultValue={props.row?.email}
                disabled={!enableEdit}
                onChange={(event) => {
                  handleChange(event, "", "email");
                }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                placeholder="type here ..."
              />
            </div>
          </Grid>
        </Grid>
        {enableEdit ? (
          <div style={{ padding: "30px 0px 0px 0px" }}>
            <ContinueShoppingButton onClick={handleCancel}>
              Cancel
            </ContinueShoppingButton>
          </div>
        ) : (
          ""
        )}
        <div style={{ padding: "30px 0px 0px 0px" }}>
          <ContinueButton disabled={!enableEdit} onClick={handleUserSubmit}>
            Submit
          </ContinueButton>
        </div>
      </Grid>
    </>
  );
};

export default EditUser;
