import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  getGroupedLens,
  setGroupedLens,
  getLensListView,
  setLensListView,
  getSaveLens,
  setSaveLens,
  editLensSuccess,
  editLensErrors,
  editLens,
  saveLensErrors
} from "../reducers/lensCatalogSlice";

async function fetchGroupedLensAPI(payload) {
  const groupedLens = await axios
    .get('/client/lenscatalog/groupedlens', {
      params: {
        filter: {},
        //sort: {"intendId" : 1},
        //search: "",
      }
    })
    .then((response) => response.data.data);
  return groupedLens;
}
export function* fetchGroupedLens(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchGroupedLensAPI, payload);
    yield put(setGroupedLens(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchLensListViewAPI(payload) {
  const groupedLens = await axios
    .get('/client/lenscatalog/list', {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { _created_at: -1 },
        search: "",
      }
    })
    .then((response) => response.data.data);
  return groupedLens;
}
export function* fetchLensListView(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchLensListViewAPI, payload);
    yield put(setLensListView(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}


async function saveLensAPI(payload) {
  const groupedLens = await axios
    .post('/client/lenscatalog/add', payload)
    .then((response) => response.data.data);
  return groupedLens;
}
export function* saveLens(action) {
  try {
    const { payload } = action;
    const response = yield call(saveLensAPI, payload);
    yield put(setSaveLens(response));
  } catch (error) {
    yield put(saveLensErrors(error.response.data.message));
  }
}

async function updateLensAPI(payload) {
  const groupedLens = await axios
    .post('/client/lenscatalog/update', payload )
    .then((response) => response.data.data);
  return groupedLens;
}
export function* updateLens(action) {
  try {
    const { payload } = action;
    const response = yield call(updateLensAPI, payload);
    yield put(editLensSuccess(response));
  } catch (error) {
    yield put(editLensErrors(error.response.data.message));
  }
}

export function* lensCatalogSaga() {
  yield takeLatest(getGroupedLens.type, fetchGroupedLens);
  yield takeLatest(getLensListView.type, fetchLensListView);
  yield takeLatest(getSaveLens.type, saveLens);
  yield takeLatest(editLens.type, updateLens);
}
