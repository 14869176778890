import { List, ListItemText, ListItemButton, Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ListTable } from "../../components";
import { makeStyles } from "@mui/styles";
import { getStores, clearStores, setStoreData, setPagenoStores } from "../../app/reducers/storeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

function Stores(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const storeList = useSelector((state) => state.storeReducer.storeList);
  const pageNo = useSelector((state) => state.storeReducer.pageNoStores);
  const hasMore = useSelector((state) => state.storeReducer.hasMoreStores);
  
  
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    setLoading(false);
  }, [storeList]);

  useEffect(() => {
    dispatch(clearStores());
    dispatch(getStores(0));
  }, []);

  let tableData = [];

  const data = storeList?.map((store) => {
    const storeData = {
      storeId: store?.storeId,
      storeType: store?.storeType,
      name: store?.name,
      city: store?.city,
      pincode: store?.pincode
    };
    tableData.push(storeData);

    return tableData;
  });

  const handleViewClick = (e, data) => {
    dispatch(setStoreData(data));
    history("/store-inventory");
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoStores(pageNo + 1));
    dispatch(getStores(pageNo+1));
  }

  return (
    <div
      style={{
        padding: "90px 24px 40px 26px",
        height: "calc(100vh - 2%)",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          aligntItems: "center",
        }}
      >
        <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
          Stores
        </div>
      </div>
      <ListTable
        dataList={tableData}
        handleViewClick={handleViewClick}
        isLoading={loading}
        handleLoadmore={handleLoadmore}
        hasMore={hasMore}
        Header={[
          { name :"STORE ID" },
          { name : "Store Type" },
          { name : "Store Name" },
          { name : "City" },
          { name : "Pincode" },
          { name :"" },
        ]}
        tableData={[
          { type: ["TEXT"], name: "storeId" },
          { type: ["TEXT"], name: "storeType" },
          { type: ["TEXT"], name: "name" },
          { type: ["TEXT"], name: "city" },
          { type: ["TEXT"], name: "pincode" },
          { type: ["VIEW"], name: "" },
        ]}
      />
    </div>
  );
}

export default Stores;
