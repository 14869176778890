import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  setUsers,
  getUsers,
  getSaveUsers,
  setSaveUsers,
  setSaveUsersError,
  updateUsers,
  updateUsersSuccess,
  updateUsersError,
} from "../../app/reducers/userSlice";

async function fetchUsersAPI(payload) {
  const indents = await axios
    .get('/client/user/warehouseusers', {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { "created_at": -1 }
      },
    })
    .then((response) => response.data.data);
  return indents;
}
export function* fetchUsers(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchUsersAPI, payload);
    yield put(setUsers(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function saveUsersAPI(payload) {
  const indents = await axios
    .post('/client/user/createuser', payload)
    .then((response) => response);
  return indents;
}
export function* saveUsers(action) {
  try {
    const { payload } = action;
    const response = yield call(saveUsersAPI, payload);
    yield put(setSaveUsers(response.data.data));
  } catch (error) {
    yield put(setSaveUsersError(error.response.data.message));
  }
}

async function editUsersAPI(payload) {
  const indents = await axios
    .post('/client/user/updateemployee', payload)
    .then((response) => response);
  return indents;
}
export function* editUsers(action) {
  try {
    const { payload } = action;
    const response = yield call(editUsersAPI, payload);
    yield put(updateUsersSuccess(response.data.data));
  } catch (error) {
    yield put(updateUsersError(error.response.data.message));
  }
}

export function* userSaga() {
  yield takeLatest(getUsers.type, fetchUsers);
  yield takeLatest(getSaveUsers.type, saveUsers);
  yield takeLatest(updateUsers.type, editUsers);
}
