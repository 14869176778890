import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/v1/eyeplay'
});

instance.defaults.headers.common['oic-authorization'] = localStorage.getItem("eyePlay-auth-token");
instance.defaults.headers.common['oic-refresh-token'] = localStorage.getItem("eyePlay-refresh-token");

instance.interceptors.request.use(function (config) {
    let token = localStorage.getItem("eyePlay-auth-token");
    let refreshToken = localStorage.getItem("eyePlay-refresh-token");

    config.headers["oic-authorization"] = token;
    config.headers["oic-refresh-token"] = refreshToken;

    return config;
  });

export default instance;