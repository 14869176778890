import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  updatedStatus: {},
  shipmentStatus: {},
  lensUpdate: {},
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    getUpdateStatus() {},
    setUpdateStatus(state, action) {
      return Object.assign({}, state, {
        updatedStatus: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getShipmentSave() {},
    setShipmentSave(state, action) {
      return Object.assign({}, state, {
        shipmentStatus: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getLensUpdation() {},
    setLensUpdation(state, action) {
      return Object.assign({}, state, {
        lensUpdate: isEmpty(action.payload) ? {} : action.payload,
      });
    },

    clearOrder(state, action) {
      return Object.assign({}, state, initialState);
    },

  },
});

export const {
  setUpdateStatus,
  getUpdateStatus,
  setShipmentSave,
  getShipmentSave,
  setLensUpdation,
  getLensUpdation,
  clearOrder
} = orderSlice.actions;

export default orderSlice.reducer;
