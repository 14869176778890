import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { BackButton, ContinueButton, ContinueShoppingButton } from "../wrapperComponents";
import { useStyles } from "../styles";



const Footer = (props) => {
  const classes = useStyles();
  const {
    back,
    onContinue,
    handleBackButton = () => null,
    handleContinueButton = () => {},
    continueShopping,
    handleContinueShopping = () => {},
    disabled = false,
  } = props;

  const handleBack = () =>{
      handleBackButton()
  }

  const handleContinue = (event) => {
     handleContinueButton(event);
  }

  return (
    // <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" className={classes.appBarFooter} sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          {back && <BackButton onClick={handleBack}>{back}</BackButton>}
          {continueShopping && <ContinueShoppingButton onClick={handleContinueShopping}>{continueShopping}</ContinueShoppingButton>}
          {onContinue && <ContinueButton disabled={disabled} onClick={handleContinue}>{onContinue}</ContinueButton>}
        </Toolbar>
      </AppBar>
    // </Box>
  );
};

export default Footer;
