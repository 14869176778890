import React from 'react';
import {Typography} from "@mui/material"

function NotFound(props) {
    return (
        <div>
            <Typography>404</Typography>
        </div>
    );
}

export default NotFound;